// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './VacancyUsers.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// OTHER COMPONENTS
import {
  Button,
  CollapsibleNext, HotChipsPeopleWithSearch,
  ImgCircle, Modal,
  OptionsMenu,
  SkeletonFlexible,
} from 'ui/basic';

// UTILS
import { apiNext } from 'apiNext';
import * as api from 'api';
import { useBreakpoint } from 'utils/hooks';
import { useTranslate } from 'utils/translator';
import { getFullName } from 'utils/users';
import { VACANCY_ROLES } from 'utils/configuration/const/roles';

// STORE
import { selectCapabilitiesNext } from 'store/selectors/configuration';
import { getCurrentUser } from 'store/actions';


const OPTION_TYPES = {
  SHOW_PROFILE: 'showProfile',
  REMOVE_FROM_VACANCY: 'removeFromVacancy',
  DEMOTE_TO_COLLABORATOR: 'demoteToCollaborator',
  DEMOTE_TO_SPECTATOR: 'demoteToSpectator',
  PROMOTE_TO_COLLABORATOR: 'promoteToCollaborator',
  PROMOTE_TO_MANAGER: 'promoteToManager',
};

// CONFIG & DATA
const Config = {
  roles: [
    { value: VACANCY_ROLES.VACANCY_MANAGER, label: 'vacancy_manager' },
    { value: VACANCY_ROLES.VACANCY_COLLABORATOR, label: 'vacancy_collaborator' },
    { value: VACANCY_ROLES.VACANCY_SPECTATOR, label: 'vacancy_spectator' },
  ],
};

// COMPONENT: VacancyUsers
const VacancyUsers = () => {
  // SPECIAL HOOKS
  const bp = useBreakpoint();
  const params = useParams();
  const translate = useTranslate();
  const dispatch = useDispatch();

  const me = useSelector(({ currentUser }) => currentUser);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const isVacancyManagerOnly = me.roleNext === 'employee' && me.subRoleNext.includes('vacancyManager');
  const noEditing = !capabilitiesNext.vacanciesEdit;

  const [ users, setUsers ] = useState();
  const [ usersProcessing, setUsersProcessing ] = useState(false);
  const [ usersErrorMessage, setUsersErrorMessage ] = useState('');

  const [ managers, setManagers ] = useState([]);
  const [ collaborators, setCollaborators ] = useState([]);
  const [ spectators, setSpectators ] = useState([]);

  const fetchUsers = useCallback(() => {
    apiNext.get(`/recruiting/jobs/${params.vacancyId}/users`, { expand: 'user' })
    .then(({ users: usersInternal }) => {
      setUsers(usersInternal.map(({ role, user }) => ({ ...user, role })));

      const mapUser = ({ user }) => ({
        id: user.id,
        value: user.mail,
        label: getFullName(user),
        img: api.getUserImageUrl(user.id),
        subLabel: user.companyData?.profession,
      });

      setManagers(usersInternal.filter(({ role }) => role === VACANCY_ROLES.VACANCY_MANAGER).map(mapUser));
      setCollaborators(usersInternal.filter(({ role }) => role === VACANCY_ROLES.VACANCY_COLLABORATOR).map(mapUser));
      setSpectators(usersInternal.filter(({ role }) => role === VACANCY_ROLES.VACANCY_SPECTATOR).map(mapUser));
    })
    .catch((error) => {
      console.error(error.message);
      setUsersErrorMessage(error.message);
    })
    .finally(() => setUsersProcessing(false));
  }, [ params.vacancyId ]);

  useEffect(() => {
    if (users) {
      return;
    }

    fetchUsers();
  }, [ users, fetchUsers ]);

  const [ modalVisible, setModalVisible ] = useState(false);
  const getHotChipsValues = (role) => {
    switch (role) {
      case VACANCY_ROLES.VACANCY_MANAGER:
        return managers;
      case VACANCY_ROLES.VACANCY_COLLABORATOR:
        return collaborators;
      case VACANCY_ROLES.VACANCY_SPECTATOR:
        return spectators;
      default:
    }

    return [];
  };

  const handleHotChipsUpdate = (role, values, newValue) => {
    const clean = (vacancyUsers) => {
      const index = vacancyUsers.findIndex((vu) => vu.id === newValue?.id);
      if (index !== -1) {
        const vacancyUsersInternal = JSON.parse(JSON.stringify(vacancyUsers));
        vacancyUsersInternal.splice(index, 1);
        return vacancyUsersInternal;
      }

      return vacancyUsers;
    };

    switch (role) {
      case VACANCY_ROLES.VACANCY_MANAGER:
        setCollaborators(clean(collaborators));
        setSpectators(clean(spectators));
        setManagers(values);
        break;
      case VACANCY_ROLES.VACANCY_COLLABORATOR:
        setManagers(clean(managers));
        setSpectators(clean(spectators));
        setCollaborators(values);
        break;
      case VACANCY_ROLES.VACANCY_SPECTATOR:
        setManagers(clean(managers));
        setCollaborators(clean(collaborators));
        setSpectators(values);
        break;
      default:
    }
  };

  const getOptions = (role) => {
    const options = [];
    if (capabilitiesNext.employeesView) {
      options.push({ value: OPTION_TYPES.SHOW_PROFILE, label: translate('team_users_option1') });
    }

    if (role === VACANCY_ROLES.VACANCY_MANAGER) {
      options.push({ value: OPTION_TYPES.DEMOTE_TO_COLLABORATOR, label: translate('demote_to_collaborator') });
      options.push({ value: OPTION_TYPES.DEMOTE_TO_SPECTATOR, label: translate('demote_to_spectator') });
    }

    if (role === VACANCY_ROLES.VACANCY_COLLABORATOR) {
      options.push({ value: OPTION_TYPES.PROMOTE_TO_MANAGER, label: translate('promote_to_manager') });
      options.push({ value: OPTION_TYPES.DEMOTE_TO_SPECTATOR, label: translate('demote_to_spectator') });
    }

    if (role === VACANCY_ROLES.VACANCY_SPECTATOR) {
      options.push({ value: OPTION_TYPES.PROMOTE_TO_MANAGER, label: translate('promote_to_manager') });
      options.push({ value: OPTION_TYPES.PROMOTE_TO_COLLABORATOR, label: translate('promote_to_collaborator') });
    }

    options.push({ value: OPTION_TYPES.REMOVE_FROM_VACANCY, label: translate('remove_from_vacancy') });
    return options;
  };

  const handleUserRemove = (userId) => {
    apiNext
    .delete(`/recruiting/jobs/${params.vacancyId}/users/${userId}`)
    .then(() => {
      // if user deleted itself
      if (isVacancyManagerOnly && userId === me.id) {
        dispatch(getCurrentUser());
        return;
      }

      fetchUsers();
    })
    .catch(({ message }) => console.error(message));
  };

  const handleRoleChange = async (userId, role) => {
    try {
      await apiNext.delete(`/recruiting/jobs/${params.vacancyId}/users/${userId}`);
      await apiNext.post(`/recruiting/jobs/${params.vacancyId}/users`, { add: [ { id: userId, role } ] });

      fetchUsers();
    } catch ({ message }) {
      console.error(message);
    }
  };

  const handleOptionClick = (action, userId) => {
    switch (action) {
      case OPTION_TYPES.SHOW_PROFILE: {
        const path = me.id === userId
          ? '/my-profile'
          : `/employees/${userId}/profile`;
        window.open(path, '_blank');
        break;
      }
      case OPTION_TYPES.REMOVE_FROM_VACANCY: {
        handleUserRemove(userId);
        break;
      }
      case OPTION_TYPES.DEMOTE_TO_COLLABORATOR: {
        handleRoleChange(userId, VACANCY_ROLES.VACANCY_COLLABORATOR);
        break;
      }
      case OPTION_TYPES.DEMOTE_TO_SPECTATOR: {
        handleRoleChange(userId, VACANCY_ROLES.VACANCY_SPECTATOR);
        break;
      }
      case OPTION_TYPES.PROMOTE_TO_MANAGER: {
        handleRoleChange(userId, VACANCY_ROLES.VACANCY_MANAGER);
        break;
      }
      case OPTION_TYPES.PROMOTE_TO_COLLABORATOR: {
        handleRoleChange(userId, VACANCY_ROLES.VACANCY_COLLABORATOR);
        break;
      }
      default:
    }
  };

  const mapSearchResults = useCallback(({ users: usersInternal }) => usersInternal
  .map((user) => ({
    id: user.id,
    value: user.mail,
    label: getFullName(user),
    img: api.getUserImageUrl(user.id),
    subLabel: user.companyData?.profession,
  })), []);

  const [ updateProcessing, setUpdateProcessing ] = useState(false);
  const [ updateErrorMessage, setUpdateErrorMessage ] = useState('');
  const handleVacancyUsersUpdate = () => {
    setUpdateProcessing(true);
    const usersForUpdate = [
      ...managers.map((m) => ({ id: m.id, role: VACANCY_ROLES.VACANCY_MANAGER })),
      ...collaborators.map((c) => ({ id: c.id, role: VACANCY_ROLES.VACANCY_COLLABORATOR })),
      ...spectators.map((s) => ({ id: s.id, role: VACANCY_ROLES.VACANCY_SPECTATOR })),
    ];

    const usersIdsForUpdate = usersForUpdate.map((au) => au.id);
    const toRemove = users.filter((vu) => !usersIdsForUpdate.includes(vu.id)).map((vu) => ({ id: vu.id }));

    apiNext.post(`/recruiting/jobs/${params.vacancyId}/users`, {
      add: usersForUpdate,
      remove: toRemove,
    })
    .then(() => {
      fetchUsers();
      setModalVisible(false);
    })
    .catch(({ message }) => {
      console.error(message);
      setUpdateErrorMessage(message);
    })
    .finally(() => setUpdateProcessing(false));
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setUpdateErrorMessage('');

    const mapUser = (user) => ({
      id: user.id,
      value: user.mail,
      label: getFullName(user),
      img: api.getUserImageUrl(user.id),
      subLabel: user.companyData?.profession,
    });

    setManagers(users.filter(({ role }) => role === VACANCY_ROLES.VACANCY_MANAGER).map(mapUser));
    setCollaborators(users.filter(({ role }) => role === VACANCY_ROLES.VACANCY_COLLABORATOR).map(mapUser));
    setSpectators(users.filter(({ role }) => role === VACANCY_ROLES.VACANCY_SPECTATOR).map(mapUser));
  };

  // RENDER: VacancyUsers
  return (
    <div className={styles.vacancyUsers}>
      <CollapsibleNext
        header={<span>{ translate('vacancy_contributors') }</span>}
        headerClassName={styles.vuHeader}
        withBorders
        noPaddings
        isOpenOverride={!bp.isXs}
      >
        <div className={styles.vuContent}>
          { usersErrorMessage && (
            <div className={styles.generalError}>{ usersErrorMessage }</div>
          ) }

          { usersProcessing && (
            <SkeletonFlexible />
          ) }

          { !usersProcessing && users && (
            <>
              { Config.roles.map(({ value, label }) => {
                const usersByRole = users.filter(({ role }) => role === value);

                return (
                  <div key={value?.toLowerCase()} className={styles.userGroup}>
                    <div>{ translate(label) }</div>

                    { usersByRole.length === 0 && (
                      <div className={styles.noUsers}>
                        { translate(`no_users_for_${label}`) }
                      </div>
                    ) }

                    { usersByRole.length > 0 && usersByRole.map((user) => (
                      <div
                        key={user.id}
                        className={styles.vUser}
                      >
                        <div className={styles.imgCircle}>
                          <ImgCircle
                            size='S'
                            src={api.getUserImageUrl(user.id)}
                            fullLabel={getFullName(user)}
                          />
                        </div>

                        <div className={styles.namePosition}>
                          <div className={styles.name}>{ getFullName(user) }</div>
                          <div className={styles.position}>
                            { user.organizationalRoles || user.position || '-' }
                          </div>
                        </div>

                        { !noEditing && (
                          <div className={styles.optionsMenu}>
                            <OptionsMenu
                              withBackgroundDark
                              options={getOptions(value)}
                              onClick={(action) => handleOptionClick(action, user.id)}
                            />
                          </div>
                        ) }
                      </div>
                    )) }
                  </div>
                );
              }) }

              <Button
                size='S'
                looks='secondary'
                disabled={noEditing}
                onClick={() => setModalVisible(true)}
              >
                { translate('vacancy_contributors_update_btn') }
              </Button>
            </>
          ) }
        </div>
      </CollapsibleNext>

      { modalVisible && (
        <Modal
          header={translate('vacancy_contributors_update')}
          primaryButtonTitle={translate('save_lbl')}
          primaryButtonDisabled={updateProcessing}
          secondaryButtonTitle={translate('cancel_lbl')}
          closeOnConfirm={false}
          onClose={handleModalClose}
          onConfirm={handleVacancyUsersUpdate}
        >
          <>
            { updateErrorMessage && <div className={styles.modalError}>{ updateErrorMessage }</div> }

            { translate('vacancy_contributors_update_copy') }

            { Config.roles.map(({ value, label }, index) => (
              <div key={value.toLowerCase()} className={styles.modalRole}>
                <span className='bluTypeLabelL'>{ translate(label) }</span>
                <div>
                  { translate(`${label}_description`) }
                </div>

                <HotChipsPeopleWithSearch
                  placeholder={translate('vacancy_contributors_add_placeholder')}
                  hint={translate('vacancy_contributors_add_hint')}
                  values={getHotChipsValues(value)}
                  noResultsHint={translate('vacancy_contributors_add_no_results')}
                  onUpdate={(values, newValue) => handleHotChipsUpdate(value, values, newValue)}
                  canMail={false}
                  topFlyout={index + 1 === Config.roles.length}
                  searchConfig={{
                    entrypoint: '/core/company/users/search',
                    mapResults: mapSearchResults,
                    extraParams: { expand: 'user' },
                  }}
                />

              </div>
            )) }
          </>
        </Modal>
      ) }
    </div>
  );
};

export default VacancyUsers;
