export const LIST_FLOWS = 'LIST_FLOWS';
export const listFlows = () => ({
  type: LIST_FLOWS,
});

export const LIST_FLOWS_FULFILLED = 'LIST_FLOWS_FULFILLED';
export const listFlowsFulfilled = (payload) => ({
  type: LIST_FLOWS_FULFILLED,
  payload,
});

export const LIST_FLOWS_REJECTED = 'LIST_FLOWS_REJECTED';
export const listFlowsRejected = (payload) => ({
  type: LIST_FLOWS_REJECTED,
  payload,
});

export const LIST_USER_FLOWS = 'LIST_USER_FLOWS';
export const listUserFlows = (payload) => ({
  type: LIST_USER_FLOWS,
  payload,
});

export const LIST_USER_FLOWS_FULFILLED = 'LIST_USER_FLOWS_FULFILLED';
export const listUserFlowsFulfilled = (payload) => ({
  type: LIST_USER_FLOWS_FULFILLED,
  payload,
});

export const LIST_USER_FLOWS_REJECTED = 'LIST_USER_FLOWS_REJECTED';
export const listUserFlowsRejected = (payload) => ({
  type: LIST_USER_FLOWS_REJECTED,
  payload,
});

export const GET_USER_FLOW_HISTORY = 'GET_USER_FLOW_HISTORY';
export const getUserFlowHistory = (payload) => ({
  type: GET_USER_FLOW_HISTORY,
  payload,
});

export const GET_USER_FLOW_HISTORY_FULFILLED = 'GET_USER_FLOW_HISTORY_FULFILLED';
export const getUserFlowHistoryFulfilled = (payload) => ({
  type: GET_USER_FLOW_HISTORY_FULFILLED,
  payload,
});

export const GET_USER_FLOW_HISTORY_REJECTED = 'GET_USER_FLOW_HISTORY_REJECTED';
export const getUserFlowHistoryRejected = (payload) => ({
  type: GET_USER_FLOW_HISTORY_REJECTED,
  payload,
});
