import * as fromActions from './vacancy.actions';

const initialState = {
  baseInfo: {
    loading: false,
    candidatesLoading: false,
  },
  matching: {
    loading: false,
  },
  profile: {
    loading: false,
  },
};


export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case fromActions.RESET: {
      return {
        ...state,
        baseInfo: { loading: false },
        matching: { loading: false },
      };
    }
    case fromActions.GET_VACANCY: {
      const { loading } = action.payload;

      return {
        ...state,
        baseInfo: { ...state.baseInfo, loading },
      };
    }
    case fromActions.GET_VACANCY_SUCCESS: {
      return {
        ...state,
        baseInfo: { loading: false, ...action.payload.data },
      };
    }
    case fromActions.GET_VACANCY_FAIL: {
      const errorCode = action.payload.error && action.payload.error.errorCode;
      let notFound;
      // 1001 - not found error
      if (errorCode === 1001) {
        notFound = true;
      }

      return {
        ...state,
        baseInfo: { loading: false, error: action.payload.error, notFound },
      };
    }
    case fromActions.GET_VACANCY_DESCRIPTION_SUCCESS: {
      return {
        ...state,
        baseInfo: { ...state.baseInfo, description: action.payload.data },
      };
    }
    case fromActions.GET_VACANCY_CANDIDATES: {
      return {
        ...state,
        baseInfo: {
          ...state.baseInfo,
          candidatesLoading: true,
        },
      };
    }
    case fromActions.GET_VACANCY_CANDIDATES_SUCCESS: {
      return {
        ...state,
        baseInfo: {
          ...state.baseInfo,
          candidates: action.payload.data,
          candidatesLoading: false,
        },
      };
    }
    case fromActions.GET_VACANCY_CANDIDATES_FAIL: {
      return {
        ...state,
        baseInfo: {
          error: action.payload.error,
          candidatesLoading: false,
        },
      };
    }
    case fromActions.GET_VACANCY_MATCHES: {
      const { loading } = action.payload;

      return {
        ...state,
        matching: { ...state.matching, loading },
      };
    }
    case fromActions.GET_VACANCY_MATCHES_SUCCESS: {
      return {
        ...state,
        matching: { ...state.matching, matches: action.payload.data, loading: false },
      };
    }
    case fromActions.GET_VACANCY_MATCHES_FAIL: {
      return {
        ...state,
        matching: { ...state.matching, loading: false },
      };
    }
    case fromActions.GET_VACANCY_PROFILE: {
      return {
        ...state,
        profile: { ...state.profile, loading: true },
      };
    }
    case fromActions.GET_VACANCY_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: { ...state.profile, ...action.payload.data, loading: false },
      };
    }
    case fromActions.GET_VACANCY_PROFILE_FAIL: {
      return {
        ...state,
        profile: { ...state.profile, loading: false },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
