// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './Peer360ResultItem.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { CollapsibleNext, LineDiagram } from 'ui/basic';
import { Peer360ItemInfoModal } from '../Peer360ItemInfoModal';

// UTILS
import { setDecimalSeparator } from 'utils/userpreference';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: Peer360ResultItem
const Peer360ResultItem = (props) => {
  // PROPS
  const {
    data = {},
    segmentsCount,
    renderMin,
    exportView = false,
    showInfo = true,
  } = props;

  // SPECIAL HOOKS

  const Info = IconsSvg.InfoBlue;

  const [ subDimension, setSubDimension ] = useState();
  const [ modalVisible, setModalVisible ] = useState(false);

  const headerComponent = (
    <div className={classNames(styles.header)}>
      <div className={styles.diagram}>
        <LineDiagram
          score={data.result}
          range={[ data.minResult, data.maxResult ]}
          renderMin={renderMin}
          renderMax={segmentsCount}
        />
      </div>

      <div className={styles.textContent}>
        <span>{ data.name || data.id }</span>
        <span>{ setDecimalSeparator(data.result) }</span>
      </div>
    </div>
  );

  const isExpandable = data.subDimensions?.length > 0 && !exportView;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Peer360ResultItem
  return (
    <div className={styles.peer360ResultItem}>
      <CollapsibleNext
        header={headerComponent}
        headerClassName={classNames(
          styles.peer360ResultItemCollapsibleHeader,
          { [styles.isExpandable]: isExpandable },
        )}
        highlightOnOpen
        noBorders
        isExpandable={isExpandable}
      >
        <div className={styles.subDimensionsContainer}>
          { data.subDimensions.map((subDimensionParam) => (
            <div className={styles.subDimension} key={subDimensionParam.id}>
              <div className={styles.diagram}>
                <LineDiagram
                  score={subDimensionParam.result}
                  range={(subDimensionParam.minResult && subDimensionParam.maxResult)
                    ? [ subDimensionParam.minResult, subDimensionParam.maxResult ]
                    : undefined}
                  renderMin={renderMin}
                  renderMax={segmentsCount}
                />
              </div>

              <div className={styles.subDimensionTextContent}>
                <span>{ subDimensionParam.name || subDimensionParam.id }</span>
                { showInfo && (
                  <Info
                    onClick={() => {
                      setSubDimension(subDimensionParam);
                      setModalVisible(true);
                    }}
                  />
                ) }
              </div>
            </div>
          )) }

        </div>
      </CollapsibleNext>

      { modalVisible && (
        <Peer360ItemInfoModal
          data={subDimension}
          segmentsCount={segmentsCount}
          renderMin={renderMin}
          onClose={() => setModalVisible(false)}
        />
      ) }
    </div>
  );
};

export default Peer360ResultItem;
