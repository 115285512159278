// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './CandidateExternalSummary.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  TableCard,
  DetailsPageSkeleton,
  Link, PillGroup, RoleMatchSummary,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { getRoleMatchSummaryLinks } from 'utils/roles';
import { getMappedVacancyData } from 'features/+candidates/store/vacancy/vacancy.utils';

// STORE
import * as api from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { listAssessments, listUserFlows } from 'store/actions';
import { selectUserFlows } from 'store/selectors/flow';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import { convertCandidateStartDateToLocale } from 'utils/dateTools';
import { FlowCard } from 'features/+candidates/pages/CandidateSummary/FlowCard';
import { CandidateProfile } from 'ui/basic/molecules/CandidateProfile';

// CONFIG & DATA
// const Config = {};


// COMPONENT: CandidateExternalSummary
const CandidateExternalSummary = (props) => {
  // PROPS
  const { candidate, setCurrentPage } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  // const [documents, setDocuments] = useState([]);
  // const [links, setLinks] = useState([]);

  // VACANCY: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ vacancies, setVacancies ] = useState([]);

  const [ vacancy, setVacancy ] = useState();

  const [ tableCardColumn1Items, setTableCardColumn1Items ] = useState([]);
  const [ tableCardColumn2Data, setTableCardColumn2Data ] = useState();

  const setVacancyData = useCallback((thisVacancy) => {
    const mappedVacancyData = getMappedVacancyData(thisVacancy);
    setVacancy(mappedVacancyData);

    setTableCardColumn1Items([
      {
        value: mappedVacancyData.metaData && mappedVacancyData.metaData.roleCareerLevel,
        label: translate('role_careerlevel'),
      },
      {
        value: mappedVacancyData.name,
        label: translate('role_description'),
      },
      {
        value: mappedVacancyData.metaData.salaryRange,
        label: translate('salary_range'),
      },
      {
        value: mappedVacancyData.metaData.utilizationString,
        label: translate('workload'),
      },
      {
        value: mappedVacancyData.metaData.startDateString,
        label: translate('start_termin'),
      },
      {
        value: mappedVacancyData.metaData.location,
        label: translate('location'),
      },
    ]);

    if (candidate.profileCreated) {
      setTableCardColumn2Data({
        title: translate('candidate_information_lbl'),
        items: [
          {
            value: candidate.profileData.careerLevel,
            label: translate('current_careerlevel_long'),
          },
          {
            value: candidate.profileData.currentPosition,
            label: translate('current_position'),
          },
          {
            value: candidate.profileData.desiredSalaryString,
            label: translate('desired_salary'),
          },
          {
            value: candidate.profileData.desiredWeeklyHours,
            label: translate('desired_workload'),
          },
          {
            value: convertCandidateStartDateToLocale(candidate.profileData.earliestStart),
            label: translate('start_termin'),
          },
          {
            value: candidate.profileData.residence,
            label: translate('residence'),
          },
        ],
      });
    }
  }, [ candidate, translate ]);

  // ASSESSMENTS
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);
  const userFlows = useSelector((state) => selectUserFlows(state, candidate.id));

  useEffect(() => {
    dispatch(listAssessments({ fetchExtra: { surveys: false, recommendations: false } }));
  }, [ dispatch ]);

  useEffect(() => {
    if (candidate.id) {
      dispatch(listUserFlows({ id: candidate.id }));
    }
  }, [ dispatch, candidate.id ]);

  useEffect(() => {
    if (!candidate.id) {
      return;
    }

    setVacancies(candidate.jobs || []);

    if (candidate.jobs && candidate.jobs.length > 0) {
      setVacancyData(candidate.jobs[0]);
    } else {
      setVacancy(null);
    }
  }, [ candidate, setVacancyData ]);

  // ROLE MATCHING: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ userMatchingDetails, setUserMatchingDetails ] = useState();

  const getMatchTitle = useCallback((score) => {
    if (score >= 90) {
      return translate(
        'employee_role_very_high_matching',
        [ '{{username}}', candidate.name, '{{role}}', '' ],
      );
    }
    if (score < 90 && score >= 80) {
      return translate(
        'employee_role_high_matching',
        [ '{{username}}', candidate.name, '{{role}}', '' ],
      );
    }
    if (score < 80 && score >= 40) {
      return translate(
        'employee_role_medium_matching',
        [ '{{username}}', candidate.name, '{{role}}', '' ],
      );
    }
    return translate(
      'employee_matching_low',
      [ '{{username}}', candidate.name, '{{role}}', '' ],
    );
  }, [ candidate, translate ]);

  useEffect(() => {
    if (!vacancy || !vacancy.roleId) {
      return;
    }

    api.get(`/recruiting/jobs/${vacancy.id}/matches`, { expand: 'score' })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        const candidateMatchDetails = data.matches.find((m) => m.user === candidate.id);
        if (candidateMatchDetails) {
          setUserMatchingDetails({
            ...candidateMatchDetails,
            matchingText: getMatchTitle(candidateMatchDetails.score),
          });
        }
      }
    })
    .catch((error) => console.error(error.message));
  }, [ candidate, vacancy, getMatchTitle ]);

  // RENDER: DetailsPageSkeleton
  if (!candidate) {
    return (
      <div className={styles.candidateExternalSummary}>
        <div className={styles.gridContainer}>
          <DetailsPageSkeleton />
        </div>
      </div>
    );
  }

  // RENDER: CandidateExternalSummary
  return (
    <div className={classNames(styles.candidateExternalSummary)}>
      <div className={styles.gridContainer}>
        { /* LEFT CONTENT */ }
        <div className={styles.leftContent}>
          <CandidateProfile candidate={candidate} />
        </div>

        { /* RIGHT CONTENT */ }
        <div className={styles.rightContent}>
          { candidate.deactivated && (
            <div className={styles.deactivated}>
              <div className='bluTypeXxs'>
                { translate('user_settings_deactivated_user_long') }
              </div>
              <div className={classNames('bluTypeCopyStrong', 'marginTopXs')}>
                { translate('user_settings_deactivated_user_description') }
              </div>
            </div>
          ) }

          { !candidate.deactivated && (
            <>
              { /* IF PROFILE IS NOT YET CREATED */ }
              { (!candidate.profileCreated) && (
                <div className={styles.profileCompletionPending}>
                  <div className='bluTypeXxs'>
                    { translate('no_user_profile_header') }
                  </div>
                  <div className={classNames('bluTypeCopyStrong', 'marginTopXs')}>
                    { translate('no_user_profile_copy') }
                  </div>
                </div>
              ) }

              { /* IF NO VACANCY ASSIGNED */ }
              { candidate.profileCreated && vacancies.length === 0 && (
                <div className={styles.profileCompletionPending}>
                  <div className='bluTypeXxs'>
                    { translate('no_user_vacancy_header', [
                      '{{username}}', candidate.firstName || candidate.name,
                    ]) }
                  </div>
                  <div className={classNames('bluTypeCopyStrong', 'marginTopXs')}>
                    { translate('no_user_vacancy_copy') }
                  </div>
                </div>
              ) }

              { vacancies.length > 0 && (
                <>
                  <div className='bluTypeXxs'>
                    { translate('candidate_summary') }
                  </div>

                  { vacancies.length > 1 && (
                    <PillGroup
                      pillItems={vacancies.map(({ id, name }) => {
                        let label = name;
                        if (label.length > 30) {
                          label = `${label.slice(0, 26)} ...`;
                        }

                        return { id, label };
                      })}
                      activeIndex={vacancy
                        ? vacancies.map((v) => v.id).findIndex((v) => v === vacancy.id)
                        : 0}
                      looks='primary'
                      onPillClick={(pillItem) => {
                        const thisVacancy = candidate.jobs.find((job) => job.id === pillItem.id);
                        if (thisVacancy) {
                          setVacancyData(thisVacancy);
                        }
                      }}
                    />
                  ) }

                  { vacancy && (
                    <>
                      { vacancy.flow && (
                        <FlowCard
                          user={candidate}
                          userFlow={userFlows?.find((el) => (el.flowId === vacancy.flow && el.jobId === vacancy.id))}
                        />
                      ) }
                      <TableCard
                        title={translate('candidate_for_lbl')}
                        subHeaderChildren={(
                          <div className={styles.vacancyTableSubHeader}>
                            { /* LABELS */ }
                            <div className={styles.labels}>
                              <div className={styles.label}>{ vacancy.name }</div>

                              { (vacancy.metaData.employmentType || vacancy.metaData.team) && (
                                <div className={styles.subLabels}>
                                  { vacancy.metaData.employmentType && (
                                    <span>
                                      { `${vacancy.metaData.employmentType}${vacancy.team ? ', ' : ''}` }
                                    </span>
                                  ) }
                                  { vacancy.team && (
                                    <Link to={`/teams/${vacancy.team.id}/profile`} openInNewTab>
                                      { vacancy.team.name }
                                    </Link>
                                  ) }
                                </div>
                              ) }

                            </div>
                          </div>
                        )}
                        column1Data={{
                          title: translate('vacancy_data_lbl'),
                          items: tableCardColumn1Items,
                        }}
                        column2Data={tableCardColumn2Data}
                      />
                    </>
                  ) }

                  { /* ROLE MATCHING SUMMARY */ }
                  { (vacancy && vacancy.roleId && candidate.profileCreated) && (
                    <RoleMatchSummary
                      matchingText={userMatchingDetails && userMatchingDetails.matchingText}
                      matchingPercentage={userMatchingDetails && userMatchingDetails.score}
                      links={getRoleMatchSummaryLinks(translate, assessmentsFromStore, userMatchingDetails)}
                      onLinkClick={() => setCurrentPage('rolefit')}
                    />
                  ) }

                </>
              ) }
            </>
          ) }

        </div>
      </div>

    </div>
  );
};

export default CandidateExternalSummary;
