import React from 'react';
import styles from './Profile.module.scss';

// 3RD PARTY
import classNames from 'classnames';

import { useHistory } from 'react-router';

// OTHER COMPONENTS
import RolesPopover from './RolesPopover';
import { CollapsibleNext, ImgCircle } from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { getMonths } from 'utils/months';
import { useBreakpoint } from 'utils/hooks';
import { getFullName } from 'utils/users';


const Profile = (props) => {
  const {
    user = {},
    roleClickable = true,
    showCompanyData = true,
    showManagers = false,
    managers = [],
    controls,
  } = props;

  const translate = useTranslate();
  const history = useHistory();

  const { isXs } = useBreakpoint();

  const isActive = user.status === undefined || (user.status && user.status.percentage !== -1);

  return (
    <div className={styles.profile}>
      <div className={styles.imageBlock}>
        { !user.isAnonymous && (
          <div className={classNames(styles.left, { [styles.halfVisible]: !isActive })}>
            <ImgCircle
              className='darker'
              src={user.userPicture ?? api.getUserImageUrl(user.id)}
              label1={user.name ? user.name.split(' ')[0] : ''}
              label2={user.name ? user.name.split(' ')[1] : ''}
              size='M'
            />
          </div>
        ) }

        <div className={styles.right}>
          <div className={styles.name}>
            { user.isAnonymous
              ? translate('anonymous_profile_name', [ '{{candidate_name}}', user.name ])
              : user.name }
          </div>
          { (user.orgRoles && user.orgRoles.length > 0)
            ? (
              <div className={styles.roles}>
                { user.orgRoles.length > 1
                  ? (
                    <RolesPopover
                      roles={user.orgRoles}
                      roleClickable={roleClickable}
                      onClick={(roleId) => {
                        if (roleClickable) {
                          history.push(`/roles/${roleId}/reference-profile`);
                        }
                      }}
                    />
                  ) : (
                    <span
                      role='presentation'
                      onClick={() => {
                        if (roleClickable) {
                          history.push(`/roles/${user.orgRoles[0].id}/reference-profile`);
                        }
                      }}
                    >
                      { user.orgRoles[0].name }
                    </span>
                  ) }
              </div>
            ) : (
              <div className={styles.position}>
                { user.position || '-' }
              </div>
            ) }
          <div className={styles.deactivated}>
            { isActive
              ? user.userGroup
              : translate('user_settings_deactivated_user_short') }
          </div>
        </div>
      </div>

      { controls }

      { showCompanyData && (
        <div className='marginTopS'>
          <CollapsibleNext
            header={translate('ps_label_expandable')}
            headerClassName={styles.header}
            withBorders
            isOpenOverride={!isXs}
          >
            <>
              <div className={styles.listItem}>
                <div className={styles.label}>
                  { translate('cp_subheadline_timeincomp') }
                </div>
                <div className={styles.value}>
                  { user.since
                    ? `${getMonths()[new Date(user.since).getMonth()]} ${new Date(user.since).getFullYear()}`
                    : '-' }
                </div>
              </div>

              <div className={styles.listItem}>
                <div className={styles.label}>
                  { translate('cp_label_dropdown_careerlevel') }
                </div>
                <div className={styles.value}>
                  { user.careerLevel || '-' }
                </div>
              </div>

              <div className={styles.listItem}>
                <div className={styles.label}>
                  { translate('login_form_email_label') }
                </div>
                <div
                  className={styles.value}
                  data-test='ProfileValueMail'
                >
                  { user.email }
                </div>
              </div>

              { /* MANAGERS */ }
              { showManagers && (
                <div className={styles.listItem}>
                  <div className={styles.label}>
                    { translate('manager') }
                  </div>

                  { managers.length === 0 && (
                    <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                      { translate('user_no_manager', [
                        '{{username}}', user.firstName || user.email,
                      ]) }
                    </div>
                  ) }

                  { managers.map((manager) => (
                    <div
                      key={manager.id}
                      className={styles.manager}
                    >
                      <ImgCircle
                        size='S'
                        fullLabel={getFullName(manager)}
                        src={api.getUserImageUrl(manager.id)}
                      />
                      <span>{ getFullName(manager) }</span>
                    </div>
                  )) }
                </div>
              ) }
            </>
          </CollapsibleNext>
        </div>
      ) }
    </div>
  );
};

export default Profile;
