import { get } from 'api';
import * as action from 'store/actions';
import {
  call,
  put,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';


export function* listFlows() {
  try {
    const { status, ok, data } = yield call(get, '/core/flows');

    if (ok && status === 200) {
      const { flows } = data;
      yield put(action.listFlowsFulfilled({ flows }));
    } else {
      yield put(action.listFlowsRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(action.listFlowsRejected({
      error: {
        errorMessage: error?.message,
      },
    }));
  }
}

export function* listUserFlows({ payload }) {
  const { id } = payload;
  try {
    const { status, ok, data } = yield call(get, `/core/flows/user/${id}`);

    if (ok && status === 200) {
      const { flows } = data;
      yield put(action.listUserFlowsFulfilled({ id, flows }));
    } else {
      yield put(action.listUserFlowsRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(action.listUserFlowsRejected({
      error: {
        errorMessage: error?.message,
      },
    }));
  }
}

export function* getUserFlowHistory({ payload }) {
  const { userId, userFlowId } = payload;
  try {
    const { status, ok, data } = yield call(get, `/core/flows/user/${userId}/flow/${userFlowId}/history`);

    if (ok && status === 200) {
      const { history } = data;
      yield put(action.getUserFlowHistoryFulfilled({ userId, userFlowId, history }));
    } else {
      yield put(action.getUserFlowHistoryRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(action.getUserFlowHistoryRejected({
      error: {
        errorMessage: error?.message,
      },
    }));
  }
}

export function* watchListFlowsRequest() {
  yield takeLeading(action.LIST_FLOWS, listFlows);
}

export function* watchListUserFlowsRequest() {
  yield takeLeading(action.LIST_USER_FLOWS, listUserFlows);
}

export function* watchGetUserFlowHistoryRequest() {
  yield takeEvery(action.GET_USER_FLOW_HISTORY, getUserFlowHistory);
}
