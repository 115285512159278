import React, {
  useEffect, useRef, useState,
} from 'react';
import styles from './UserAssessmentsResults.module.scss';

import classNames from 'classnames';

import * as api from 'api';

import { useDispatch, useSelector } from 'react-redux';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import { getAssessment, getAssessmentHistory, listAssessments } from 'store/actions';
import { selectCurrentLanguage } from 'store/selectors/localisation';

import { useTranslate } from 'utils/translator';
import { getMappedNineLevelsResults, getTopParentAssessmentId } from 'utils/assessment';
import { useBreakpoint } from 'utils/hooks';
import {
  ASSESSMENT_RECURRENCE_TYPES,
  ASSESSMENT_TITLES,
  ASSESSMENT_TYPES,
} from 'utils/configuration/const/assessment-types';

import { ReactComponent as HumanSitting } from 'assets/keyvisuals/sitting-human.svg';

import { PillGroup, Profile, SkeletonFlexible } from 'ui/basic';
import {
  Big5Result,
  CompetenciesResult,
  NineLevelsResult,
  PotentialResult,
  RmpResult,
  WorkPreferencesResult,
  SkillsResult,
  CliftonResult,
  Peer360Result,
  AssessmentHistory, IstResult,
} from 'ui/molecules';
import AttachmentsCard from 'features/+candidates/pages/CandidateSummary/AttachmentsCard';
import {
  RecurringAssessmentControl,
} from 'features/framework/pages/MyProfileWellBeing/BalancedYou/components/RecurringAssessmentControl';


const UserAssessmentsResults = (props) => {
  const {
    user, isCandidate, results,
  } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { isXs } = useBreakpoint();

  // ASSESSMENTS
  const currentLanguage = useSelector(selectCurrentLanguage);
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);
  const assessmentsLoading = useSelector(fromAssessmentsSelectors.selectAssessmentsLoading);

  useEffect(() => {
    dispatch(listAssessments({ fetchExtra: { surveys: false, recommendations: false } }));
  }, [ dispatch, currentLanguage ]);

  const completedAssessments = results
  ?.filter(({ assessment: assessmentId }) => assessmentsFromStore.find((a) => a.id === assessmentId))
  .map((resultItem) => {
    const assessment = assessmentsFromStore.find((a) => a.id === resultItem.assessment) || {};
    const name = translate(ASSESSMENT_TITLES[resultItem.assessment]) || assessment.title;
    return {
      ...assessment,
      name,
      results: resultItem.result?.results,
      history: resultItem.history,
    };
  });

  const completedAssessmentsForPillGroup = completedAssessments
  ?.filter(({ id }) => getTopParentAssessmentId(id, assessmentsFromStore) === id);

  // ATTACHMENTS
  const [ attachments, setAttachments ] = useState([]);
  useEffect(() => {
    api.get('/core/user/attachments')
    .then(({ ok, data = {} }) => {
      if (ok) {
        setAttachments(data.attachments);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ user ]);

  const fullWidth = user?.isAnonymous && attachments.length === 0;

  const dispatchedAssessments = useRef(new Set());
  useEffect(() => {
    if (!results) {
      return;
    }

    results.forEach((resultItem) => {
      const { assessment: assessmentId } = resultItem;

      if (!dispatchedAssessments.current.has(assessmentId)) {
        dispatchedAssessments.current.add(assessmentId);
        dispatch(getAssessment(assessmentId));
      }
    });
  }, [ dispatch, dispatchedAssessments, results ]);

  const [ historyIndex, setHistoryIndex ] = useState(0);
  const [ selectedItemId, setSelectedItemId ] = useState();
  useEffect(() => {
    if (!completedAssessments || selectedItemId) {
      return;
    }

    const assessmentId = completedAssessments?.[0]?.id;
    setSelectedItemId(assessmentId);
  }, [ dispatch, selectedItemId, completedAssessments ]);

  const recAss = assessmentsFromStore.find((a) => a.id === selectedItemId);
  const recurring = [
    ASSESSMENT_RECURRENCE_TYPES.RUN,
    ASSESSMENT_RECURRENCE_TYPES.FREQUENCY,
    ASSESSMENT_RECURRENCE_TYPES.MANUAL,
  ].includes(recAss?.recurrenceType);

  useEffect(() => {
    if (recurring) {
      dispatch(getAssessmentHistory(selectedItemId, {
        userId: user?.id,
      }));
    }
  }, [
    dispatch,
    selectedItemId,
    recurring,
    user?.id,
  ]);

  const renderResultComponent = (assessmentId) => {
    const thisAssessment = completedAssessments.find((ca) => ca.id === assessmentId);
    const assessmentResults = thisAssessment?.history?.results?.[historyIndex]?.results ?? thisAssessment?.results;

    switch (assessmentId) {
      case ASSESSMENT_TYPES.BIG5:
        return <Big5Result results={assessmentResults} requestFeedback={false} />;
      case ASSESSMENT_TYPES.POTENTIAL:
        return <PotentialResult showCallout results={assessmentResults} requestFeedback={false} />;
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return <WorkPreferencesResult results={assessmentResults} />;
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return (
          <CompetenciesResult
            assessmentType={assessmentId}
            results={assessmentResults}
          />
        );
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return (
          <CompetenciesResult
            assessmentType={assessmentId}
            results={assessmentResults}
          />
        );
      case ASSESSMENT_TYPES.RMP:
        return (
          <RmpResult
            results={assessmentResults}
            requestFeedback={false}
          />
        );
      case ASSESSMENT_TYPES.NINE_LEVELS: {
        const [ mappedResults ] = getMappedNineLevelsResults(assessmentResults);
        return (
          <NineLevelsResult
            results={mappedResults}
            signatureValues={assessmentResults?.filter((rItem) => rItem.id.includes('highvalues'))}
            requestFeedback={false}
          />
        );
      }
      case ASSESSMENT_TYPES.IST:
        return (
          <IstResult
            ist={{
              ...thisAssessment,
              date: thisAssessment?.history?.results?.[historyIndex]?.date ?? thisAssessment?.date,
              registrationData: thisAssessment?.history?.results?.[historyIndex]?.registrationData
                ?? thisAssessment?.registrationData,
              results: assessmentResults,
            }}
            user={user}
            coachingView
          />
        );
      default:
        if (thisAssessment?.render_results === 'clifton') {
          return <CliftonResult strengths={assessmentResults} />;
        }
        if (thisAssessment?.peerAssessment) {
          return (
            <Peer360Result
              userId={user?.id}
              assessmentData={assessmentsFromStore.find((a) => a.id === assessmentId)}
              assessmentResults={assessmentResults}
            />
          );
        }

        return (
          <SkillsResult
            userId={user?.id}
            assessmentType={assessmentId}
            results={assessmentResults}
            reportContent={assessmentResults}
          />
        );
    }
  };

  const renderResultComponentWrapper = (assessmentId) => (
    <React.Fragment key={assessmentId}>
      { renderResultComponent(assessmentId) }
      { assessmentsFromStore
      .filter((a) => a.parentId === assessmentId)
      .map((a) => renderResultComponentWrapper(a.id)) }
    </React.Fragment>
  );

  // RENDER: Result
  const renderResult = () => {
    if (!selectedItemId || !completedAssessments) {
      return null;
    }

    const res = [];
    const assessment = completedAssessments.find((ca) => ca.id === selectedItemId);

    if (!assessment) {
      return null;
    }

    if (recurring && assessment.history?.results?.length) {
      res.push(
        <div key={`control-${selectedItemId}`}>
          <RecurringAssessmentControl
            assessment={assessment}
            historyIndex={historyIndex}
            user={user}
            showStartNewIteration={false}
            onHistoryChange={setHistoryIndex}
          />
        </div>,
      );
    }

    res.push(
      <div key={`result-${selectedItemId}`} className={styles.assessmentsResultsContainer}>
        { renderResultComponentWrapper(selectedItemId) }
      </div>,
    );

    if (recurring && assessment.history?.results?.length) {
      res.push(
        <div key={`history-${selectedItemId}`}>
          <AssessmentHistory
            historyIndex={historyIndex}
            results={[ ...assessment.history.results ].reverse()}
            range={[
              assessment.renderReferenceMin,
              assessment.renderReferenceMax,
            ]}
          />
        </div>,
      );
    }

    return res;
  };


  // RENDER: UserAssessmentResults
  return (
    <div className={styles.userAssessmentsResults}>
      <div className={styles.gridContainer}>
        { !fullWidth && (
          <div className={styles.personalInfo}>
            <div className={styles.subBlock}>
              { !user.isAnonymous && (
                <div className={styles.profile}>
                  <Profile
                    user={user}
                    showCompanyData={!isCandidate}
                  />
                </div>
              ) }

              { /* ATTACHMENTS */ }
              { attachments.length > 0 && (
                <div className={styles.attachments}>
                  <AttachmentsCard
                    attachments={attachments}
                    getDownloadParams={(file) => ([ `/core/user/attachments/${file.id}/file` ])}
                    readOnly
                  />
                </div>
              ) }
            </div>
          </div>
        ) }

        { /* ASSESSMENTS INFO */ }
        <div className={classNames(styles.assessmentsInfo, {
          [styles.fullWidth]: fullWidth,
        })}
        >
          { (!completedAssessments || assessmentsLoading) && (
            <SkeletonFlexible />
          ) }

          { (!assessmentsLoading && completedAssessments?.length > 0) && (
            <>
              <div className={styles.title}>{ translate('employee_strengths') }</div>

              { completedAssessmentsForPillGroup.length > 1 && (
                <PillGroup
                  looks='primary'
                  activeIndex={0}
                  wrap={!isXs}
                  pillItems={completedAssessmentsForPillGroup.map((a) => ({ id: a.id, label: a.name }))}
                  onPillClick={({ id }) => {
                    setHistoryIndex(0);
                    setSelectedItemId(id);
                  }}
                />
              ) }

              <div className={styles.assessmentsResult}>
                { renderResult() }
              </div>
            </>
          ) }

          { (!assessmentsLoading && completedAssessments?.length === 0) && (
            <>
              <div className='bluTypeXs'>
                { translate('no_data') }
              </div>
              <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                { translate('employee_no_data_descr', [ '{{username}}', user.name ]) }
              </div>
              <HumanSitting />
            </>
          ) }
        </div>

      </div>

    </div>
  );
};

export default UserAssessmentsResults;
