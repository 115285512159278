// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
import styles from './StartAssessments.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { HELP_LINK } from 'utils/configuration';

// STORE NEXT
import { useDispatch } from 'react-redux';
import { setOnboardingCompletedApi } from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};

// COMPONENT: StartAssessments
const StartAssessments = (props) => {
  // PROPS
  const {
    state = { inject: { teasers: [], noTeasers: false } },
    controls = () => {},
    handleFinish,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { teasers, noTeasers } = state.inject;

  // MARK ONBOARDING COMPLETED
  useEffect(() => {
    dispatch(setOnboardingCompletedApi());
  }, [ dispatch ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // HANDLES
  const handleHelpClick = () => {
    window.open(HELP_LINK, '_blank');
  };

  if (noTeasers) {
    handleFinish?.();
    return undefined;
  }

  // RENDER: StartAssessments
  return (
    <div className={classNames(styles.startAssessments)}>
      <div className={styles.content}>
        <div className={styles.title}>
          { translate('onboarding_candidate_start_assessment_title') }
        </div>
      </div>
      <div className={classNames(styles.content, styles.contentBreakout)}>
        { teasers }
      </div>
      <div className={classNames(styles.content, styles.continue)}>
        { controls && (
          <div className={styles.controls}>
            { controls }
          </div>
        ) }

        <div className={styles.helpText}>
          { translate('onboarding_candidate_start_assessment_text') }
        </div>

        <div className={styles.helpButton}>
          <Button
            looks='secondary'
            leadingIcon={IconsSvg.BqContact}
            onClick={handleHelpClick}
          >
            { translate('onboarding_candidate_start_assessment_help_center') }
          </Button>

        </div>

      </div>
    </div>
  );
};

export default StartAssessments;
