import React, { useEffect, useState } from 'react';
import styles from './SegmentedChooser.module.scss';
import classNames from 'classnames';


const SegmentedChooser = (props) => {
  const {
    size = 'L',
    value,
    segmentsCount = 5,
    onChange,
    onHover,
  } = props;

  let valueInternal;
  if (Number.isInteger(value)) {
    valueInternal = value;
  } else {
    valueInternal = value < 1 ? 1 : Math.floor(value);
  }

  const [ clickedCellNumber, setClickedCellNumber ] = useState();
  useEffect(() => {
    setClickedCellNumber(valueInternal);
  }, [ valueInternal ]);

  const [ hoveredCellNumber, setHoveredCellNumber ] = useState();

  const getCells = () => {
    const cells = [];
    for (let i = 1; i <= segmentsCount; i += 1) {
      const cell = (
        <div
          key={`competencies-cell-${i}`}
          role='presentation'
          className={classNames(
            styles.cell,
            { [styles.blue]: clickedCellNumber && clickedCellNumber >= i },
            {
              [styles.hellBlue]: (!clickedCellNumber || i > clickedCellNumber)
              && (hoveredCellNumber && hoveredCellNumber >= i),
            },
            { [styles.grey]: !clickedCellNumber || !hoveredCellNumber },
          )}
          onClick={() => {
            setClickedCellNumber(i);
            onChange?.(i);
          }}
          onMouseOver={() => {
            setHoveredCellNumber(i);
            onHover?.(i);
          }}
          onMouseOut={() => {
            setHoveredCellNumber(null);
            onHover?.();
          }}
        />
      );
      cells.push(cell);
    }
    return cells;
  };

  return (
    <div className={classNames(styles.segmentedChooser, styles[`size${size}`])}>
      { getCells() }
    </div>
  );
};

export default SegmentedChooser;
