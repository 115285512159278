// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './ResetAssessmentHandle.module.scss';

// 3RD PARTY
import classNames from 'classnames';
import { IconsSvg } from 'assets/icons';

// OTHER COMPONENTS
import { Modal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { apiNext } from 'apiNext';
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions';


// COMPONENT: ResetAssessmentHandle
const ResetAssessmentHandle = (props) => {
  // PROPS
  const {
    userId,
    assessmentId,
    assessmentProgress,
    assessmentProtected = false,
    onConfirm,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ confirmDeletion, setConfirmDeletion ] = useState(false);
  const [ hasProgress, setHasProgress ] = useState(Boolean(assessmentProgress));
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: ResetAssessmentHandle
  return (
    <div className={styles.resetAssessmentHandle}>
      <div
        className={classNames(styles.content, {
          // eslint-disable-next-line max-len
          // it should always be active for self_only(protected) assessments (https://blueexcellence.atlassian.net/browse/BQA-193)
          [styles.disabled]: !hasProgress && !assessmentProtected,
        })}
        role='button'
        tabIndex={0}
        onClick={() => setConfirmDeletion(true)}
        onKeyDown={() => setConfirmDeletion(true)}
      >
        <IconsSvg.Reset />
        <span>
          { translate('reset_assessment_handle') }
        </span>
      </div>

      { confirmDeletion && (
        <Modal
          secondaryButtonTitle={translate('cancel_lbl')}
          redButtonTitle={translate('delete_lbl')}
          onClose={() => setConfirmDeletion(false)}
          onConfirm={async () => {
            setConfirmDeletion(false);
            setHasProgress(false);
            try {
              await apiNext.post(
                `/core/assessments/${assessmentId}/reset`,
                undefined,
                { user: userId },
              );
              dispatch(showToast(
                translate('fill_position__toast_title'),
                translate('reset_assessment_handle_success'),
              ));
              onConfirm?.();
            } catch {
              setHasProgress(true);
              dispatch(showToast(
                '',
                translate('api_error_generic'),
              ));
            }
          }}
        >
          { translate('reset_assessment_handle_confirm') }
        </Modal>
      ) }
    </div>
  );
};

export default ResetAssessmentHandle;
