// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import { Checkbox, InputNext } from 'ui/basic';
import REGEXES from 'utils/configuration/const/regexes';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: FormLink
const FormLink = (props) => {
  // PROPS
  const {
    children,
    vacancyMode = false,
    link = {},
    isLoading,
    clearInputs = false,
    onInputFocus,
    onValid,
    onInvalid,
    onChange,
    onSubmit,
  } = props;

  const [ linkUrl, setLinkUrl ] = useState('');
  useEffect(() => {
    setLinkUrl(link?.url || '');
  }, [ link?.url ]);

  const [ linkName, setLinkName ] = useState('');
  useEffect(() => {
    setLinkName(link?.name || '');
  }, [ link?.name ]);

  const [ shareWithCandidates, setShareWithCandidates ] = useState(link.shareWithCandidates || false);
  useEffect(() => {
    if (clearInputs) {
      setLinkUrl('');
      setLinkName('');
      setShareWithCandidates(false);
    }
  }, [ clearInputs ]);

  const [ isLinkValid, setIsLinkValid ] = useState(false);
  const [ isLinkUrlValid, setIsLinkUrlValid ] = useState(true);

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  useEffect(() => {
    let isValid = true;

    if (linkUrl.length === 0) {
      isValid = false;

      setIsLinkUrlValid(true); // no error state if no value
    } else if (!REGEXES.DOMAIN_FULL.test(linkUrl)) {
      isValid = false;

      setIsLinkUrlValid(false);
    } else {
      setIsLinkUrlValid(true);
    }

    setIsLinkValid(isValid);
  }, [ linkUrl, linkName ]);

  useEffect(() => {
    if (isLinkValid) {
      onValid?.();
    } else {
      onInvalid?.();
    }
  });

  const onLinkSubmit = (e) => {
    if (isLinkValid) {
      if (link) {
        // edit existing link
        onSubmit({
          ...link,
          url: linkUrl,
          name: linkName,
          shareWithCandidates: vacancyMode ? shareWithCandidates : undefined,
        });
      } else {
        // create a new link
        onSubmit({
          url: linkUrl,
          name: linkName,
          shareWithCandidates: vacancyMode ? shareWithCandidates : undefined,
        });
      }
    }

    e.preventDefault();
  };

  // RENDER: FormLink
  return (
    <form onSubmit={onLinkSubmit}>
      <div>
        <InputNext
          label={translate('candidate_attachments_url')}
          value={linkUrl}
          disabled={isLoading}
          className={isLinkUrlValid === false ? 'error' : undefined}
          onChange={(value) => {
            setLinkUrl(value);
            onChange?.(value, 'url');
          }}
          onFocus={onInputFocus}
        />

        { isLinkUrlValid === false && (
          <small>
            { translate('candidate_attachments_invalid_url') }
          </small>
        ) }
      </div>

      <div>
        <InputNext
          label={translate('candidate_attachments_title')}
          value={linkName}
          disabled={isLoading}
          onChange={(value) => {
            setLinkName(value);
            onChange?.(value, 'name');
          }}
          onFocus={onInputFocus}
        />
      </div>

      { vacancyMode && (
        <div className='marginTopS'>
          <Checkbox
            checked={shareWithCandidates}
            name={translate('vacancy_attachments_share_with_candidates')}
            onChange={(value) => {
              setShareWithCandidates(value);
              onInputFocus?.();
              onChange?.(value, 'shareWithCandidates');
            }}
          />
        </div>
      ) }

      <div>{ children }</div>
    </form>
  );
};

export default FormLink;
