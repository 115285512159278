// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './CandidateProfile.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { CollapsibleNext, ImgCircle } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { convertCandidateStartDateToLocale } from 'utils/dateTools';

// STORE
import * as api from 'api';

// CONFIG & DATA
// const Config = {};
const CANDIDATE_INFO = [
  {
    value: 'mail',
    labelFallback: 'E-Mail',
  },
  {
    value: 'yearOfBirth',
    labelKey: 'year_of_birth',
    labelFallback: 'Alter',
    lookInProfileData: true,
  },
  {
    value: 'gender',
    labelKey: 'rmp_ass_form_gender_label',
    labelFallback: 'Geschlecht',
    lookInProfileData: true,
  },
  {
    value: 'residenceWithNationality',
    labelKey: 'residence_and_nationality',
    labelFallback: 'Wohnhaft in / Nationalität',
    lookInProfileData: true,
  },
  {
    value: 'education',
    labelKey: 'highest_school_degree',
    labelFallback: 'Höchster Schulabschluss',
    lookInProfileData: true,
  },
  {
    value: 'careerLevel',
    labelKey: 'current_career_level',
    labelFallback: 'Akt. Karrierestufe',
    lookInProfileData: true,
  },
  {
    value: 'positionAndJobFamily',
    labelKey: 'position_and_jobFamily',
    labelFallback: 'Aktuelle Position / Job-Familie',
    lookInProfileData: true,
  },
  {
    value: 'industryExperienceMapped',
    labelKey: 'industry_experience',
    labelFallback: 'Branchenerfahrung',
    lookInProfileData: true,
  },
  {
    value: 'motivation',
    labelKey: 'motivation',
    labelFallback: 'Motivation',
    lookInProfileData: true,
  },
  {
    value: 'jobRequirements',
    labelKey: 'job_requirements',
    labelFallback: 'Job-Anforderungen',
    lookInProfileData: true,
  },
  {
    value: 'earliestStart',
    labelKey: 'start_termin',
    labelFallback: 'Start-Termin',
    lookInProfileData: true,
  },
];

// COMPONENT: CandidateProfile
const CandidateProfile = (props) => {
  // PROPS
  const { candidate = {} } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // RENDER: CandidateProfile
  return (
    <div className={styles.candidateProfile}>
      <div className={styles.imageBlock}>
        { !candidate.isAnonymous && (
          <div className={classNames(styles.left, { [styles.halfVisible]: candidate.disabled })}>
            <ImgCircle
              className='darker'
              src={api.getUserImageUrl(candidate.id)}
              label1={candidate.name ? candidate.name.split(' ')[0] : ''}
              label2={candidate.name ? candidate.name.split(' ')[1] : ''}
              size='M'
            />
          </div>
        ) }

        <div className={styles.right}>
          <div className={styles.name}>
            {
              candidate.isAnonymous
                ? translate('anonymous_profile_name', [ '{{candidate_name}}', candidate.name ])
                : candidate.name
            }
          </div>
          <div className={styles.position}>
            { candidate.profileData?.currentPosition || '' }
          </div>
        </div>
      </div>

      <div className='marginTopS'>
        <CollapsibleNext
          header={<span className='bluTypeLabel'>Details</span>}
          headerClassName={styles.header}
          withBorders
        >
          <>
            { CANDIDATE_INFO.map((candidateInfo) => {
              let value = candidateInfo.lookInProfileData
                ? candidate.profileData[candidateInfo.value]
                : candidate[candidateInfo.value];
              value = convertCandidateStartDateToLocale(value);
              if (Array.isArray(value)) {
                value = value.filter(Boolean);
                value = value.length
                  ? <ul>{ value.map((v) => <li key={v}>{ v }</li>) }</ul>
                  : null;
              }
              value = value || '-';

              return (
                <div className={styles.listItem} key={candidateInfo.value}>
                  <div className={styles.label}>
                    { candidateInfo.labelKey
                      ? (translate(candidateInfo.labelKey) || candidateInfo.labelFallback)
                      : candidateInfo.labelFallback }
                  </div>
                  <div className={styles.value}>
                    { value }
                  </div>
                </div>
              );
            }) }
          </>
        </CollapsibleNext>
      </div>
    </div>
  );
};

export default CandidateProfile;
