import { combineReducers } from 'redux';

// UTILS
import { IS_ADMIN_FRONTEND } from 'utils/configuration';

// ACTIONS
import bqActions from './bqActions';

// ASSESSMENTS
import assessments from './assessments';

// ASSESSMENTS
import surveys from './surveys';

// ADMIN
import admin from './admin';

// CONFIGURATION
import configuration from './configuration';

// CURRENT USER
import currentUser from './currentUser';

// DEBUG
import debug from './debug';

// FILTER LIST ENITIES
import filterListEntities from './filterListEntities';

// LOADING
import loading from './loading';

// LOCALISATION
import localisation from './localisation';

// LOGIN & USER HANDLING
import login from './login';
import resetPassword from './resetPassword';
import resetPasswordRequest from './resetPasswordRequest';
import signUp from './signUp';

// ORGANIZATION
import organization from './organization';

// PAGES
import employeeOverview from './employeeOverview';
import settings from './settings';

// PROFILE
import profile from './profile';

// ROLES
import roles from './roles';

// ROUTING
import routing from './routing';

// STAFFING
import staffing from './staffing';

// STATIC
import staticValues from './staticValues';

// SURVEY
import survey from './survey';

// TEAM OVERVIEW
import teamOverview from './teamOverview';

// CANDIDATE
import candidate from 'features/+candidates/store/candidate/candidate.reducer';

// VACANCY
import vacancy from 'features/+candidates/store/vacancy/vacancy.reducer';

// WELL-BEING
import wellBeing from './wellBeing';

// ??
import setMenuItem from './setMenuItem';

// FLOW
import flow from './flow';

// TOAST
import toast from './toast';

// ADMIN REDUCERS
import planOverview from 'features/+admin/store/reducers/planOverview';
import instanceOverview from 'features/+admin/store/reducers/instanceOverview';

// GARMIN REDUCER
import garmin from 'features/+garmin/store/garmin.reducer';


const reducersToCombine = {
  // ACTIONS
  bqActions,

  // ASSESSMENTS
  assessments,

  // ASSESSMENTS
  surveys,

  // ADMIN
  admin,

  // CONFIGURATION
  configuration,

  // CURRENT USER
  currentUser,

  // DEBUG
  debug,

  // GARMIN
  garmin,

  // LOADING
  loading,

  // LOCALISATION
  localisation,

  // LOGIN & USER HANDLING
  login,
  resetPassword,
  resetPasswordRequest,
  signUp,

  // ORGANIZATION
  organization,

  // FILTER LIST ENTITIES
  filterListEntities,

  // EMPLOYEES
  employeeOverview,

  // PROFILE
  profile,

  // ROLES
  roles,

  // ROUTING
  routing,

  // STAFFING
  staffing,

  // STATIC
  staticValues,

  // SURVEY
  survey,

  // TEAM OVERVIEW
  teamOverview,

  // CANDIDATE
  candidate,

  // VACANCY
  vacancy,

  // WELL-BEING
  wellBeing,

  // ??
  setMenuItem,
  settings,

  // TOAST
  toast,

  // FLOW
  flow,
};

// ADD ADMIN REDUCERS if necessary
if (IS_ADMIN_FRONTEND) {
  Object.assign(reducersToCombine, {
    planOverview,
    instanceOverview,
  });
}

const reducers = combineReducers(reducersToCombine);

export default reducers;
