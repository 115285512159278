export const LIST_ASSESSMENTS = 'LIST_ASSESSMENTS';
export const LIST_ASSESSMENTS_FULFILLED = 'LIST_ASSESSMENTS_FULFILLED';
export const LIST_ASSESSMENTS_REJECTED = 'LIST_ASSESSMENTS_REJECTED';

export const listAssessments = ({ fetchExtra } = {}) => ({
  type: LIST_ASSESSMENTS,
  payload: { fetchExtra },
});

export const listAssessmentsFulfilled = (assessments) => ({
  type: LIST_ASSESSMENTS_FULFILLED,
  payload: assessments,
});

export const listAssessmentsRejected = ({ error }) => ({
  type: LIST_ASSESSMENTS_REJECTED,
  payload: { error },
});

