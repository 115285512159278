/* eslint-disable camelcase */
import { create, NETWORK_ERROR, SERVER_ERROR } from './apisauce';

// CONFIG
import {
  REACT_APP_API_BASE_URL,
  COMPANY_ID,
} from 'utils/configuration';
import { LANGUAGES } from 'utils/configuration/const/languages';
import { returnToUrl } from 'features/+coachHub/utils/returnUrl';
import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';

// UTILS
import * as localStorage from 'utils/localStorage';

// CONSTANTS
export const MIN_SEARCH_LENGTH = 2; // characters
export const ITEMS_PER_PAGE = 10;
export const DEBOUNCE_TIME = 200; // ms
// tbd: SESSION_DURATION actually 30 mins in the backend.
export const SESSION_DURATION = 15 * 60 * 1000; // 15 minutes
export const EXTENDED_SESSION_DURATION = 7 * 24 * 60 * 60 * 1000; // 1 week

const AUTH_ERROR_CODES = [
  1102, // Your session has expired
  1103, // The session token provided is invalid
  1104, // The request was sent from a new IP address, please login again
  1108, // The auth token provided is invalid (MFA)
  1109, // Your auth token has expired (MFA)
];

/**
 * HEADERS Section
 */

// BASE HEADERS
const AUTHORIZATION_HEADER = 'Bearer';
const DEFAULT_HEADERS = {
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json',
};

// CUSTOM HEADERS
let CUSTOM_HEADERS = {};
export const addCustomHeaders = (headers) => {
  CUSTOM_HEADERS = {
    ...CUSTOM_HEADERS,
    ...headers,
  };
};

// Get access token name. Depending on the current page, a special token with a different name may be required.
export const getTokenNameForCurrentPage = () => {
  // Case: shared external profiles, peer assessments
  const [ , shareToken ] = window.location.pathname.split('/share/');
  if (shareToken) {
    return `shareToken${shareToken}`;
  }

  // Case: surveys (external participants and anonymous participation link)
  const [ , invitationId ] = window.location.pathname.split(/\/surveys\/ext\/|\/external\//);
  if (invitationId) {
    return `surveyToken${invitationId}`;
  }

  // Default login
  return 'token';
};

// Log user out on auth error. Redirect for regular pages, reload on the spot for shared pages.
export const handleErrorLogout = (errorCode) => {
  if (AUTH_ERROR_CODES.includes(errorCode)) {
    localStorage.logout();
    if (getTokenNameForCurrentPage() === 'token') {
      window.location.href = '/logged-out';
    } else {
      window.location.reload();
    }
  }
};

// Create default headers for calls
const createHeaders = (headerItems = {}) => ({
  Authorization: `${AUTHORIZATION_HEADER} ${localStorage.getItem(getTokenNameForCurrentPage())}`,
  'Accept-Language': `${localStorage.getItem('selectedLanguage') || LANGUAGES.EN}, *`,
  ...headerItems,
  ...CUSTOM_HEADERS,
});


/**
 * APISAUCE Setup
 */

const api = create({
  // base URL is read from the 'constructor'
  baseURL: REACT_APP_API_BASE_URL,
  headers: DEFAULT_HEADERS,
  // default: 15 second timeout...
  // timeout: 20000,
});

const apiLocal = create({
  baseURL: '',
  headers: DEFAULT_HEADERS,
  // 15 second timeout...
  // timeout: 20000,
});


/**
 * Global error handling for all http verbs below.
 *
 * @param {Object} response The apisauce response object
 * @returns {undefined}
 */
const handleErrors = (response = {}) => {
  // No error: skip checks
  if (response?.ok) {
    return;
  }

  /* Coachhub instance: redirect */
  if (getIsExternalSystemIntegrationSupported()) {
    // 1102 - Session expired error
    if (response?.data?.error?.errorCode === 1102) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'error',
          error_type: 'session_expired',
        },
      });
      return;
    }

    // 1103 - Invalid token error
    if (response?.data?.error?.errorCode === 1103) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'error',
          error_type: 'access_denied',
        },
      });
      return;
    }

    // 1105 - Not allowed for this user
    if (response.data?.error?.errorCode === 1105) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'error',
          error_type: 'action_error',
          error_message: response.data.error.errorMessage,
          error_code: response.data.error.errorCode,
        },
      });
      return;
    }

    // Generic Api/Network errors
    if ([ SERVER_ERROR, NETWORK_ERROR ].includes(response?.problem)) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'error',
          error_type: 'action_error',
          error_message: response.originalError?.message ?? response.problem,
          error_code: response.status,
        },
      });
    }
  }

  /* Regular bq instances */
  handleErrorLogout(response?.data?.error?.errorCode);
};


// BASE URL
export const getBaseUrl = () => {
  let baseUrl = REACT_APP_API_BASE_URL;
  if (baseUrl[baseUrl.length - 1] === '/') {
    baseUrl = baseUrl.slice(0, -1);
  }
  return baseUrl;
};

// IMAGE URL
export const getCustomLogoUrl = (companyId = COMPANY_ID) => `${getBaseUrl()}/core/company/${companyId}/logo`;
export const getUserImageUrl = (userId) => `${getBaseUrl()}/core/static/image/user/${userId}/thumbnail.jpg`;


/**
 * REQUESTS Section
 */

// GET LOCAL REQUEST
export const getLocal = async (url, params = {}) => {
  const headers = createHeaders();
  const response = await apiLocal.get(url, params, { headers });

  handleErrors(response);

  return response;
};

// GET REQUEST
export const get = async (url, params = {}, headerItems = {}, axiosConfig = {}) => {
  const headers = createHeaders(headerItems);
  const response = await api.get(url, params, { headers, ...axiosConfig });

  // Special case: wb
  if (!response.ok && response.data?.error?.errorCode === 1102 && window.location.pathname.includes('wb-measurement')) {
    localStorage.logout();
    return response;
  }

  handleErrors(response);

  return response;
};

// POST REQUEST
export const post = async (url, data = {}, headerItems = {}, config = {}) => {
  const headers = createHeaders(headerItems);
  const response = await api.post(url, data, { headers, ...config });

  handleErrors(response);

  return response;
};

// PUT REQUEST
export const put = async (url, data = {}, headerItems = {}) => {
  const headers = createHeaders(headerItems);
  const response = await api.put(url, data, { headers });

  handleErrors(response);

  return response;
};

// PATCH REQUEST
export const patch = async (url, data = {}, headerItems = {}) => {
  const headers = createHeaders(headerItems);
  const response = await api.patch(url, data, { headers });

  handleErrors(response);

  return response;
};

// POST BINARY REQUEST
export const post_binary = async (url, data = {}) => {
  const headers = createHeaders({ 'Content-Type': 'multipart/form-data' });
  const response = await api.post(url, data, { headers });

  handleErrors(response);

  return response;
};

// PUT BINARY REQUEST
export const put_binary = async (url, data = {}) => {
  const headers = createHeaders({ 'Content-Type': 'multipart/form-data' });
  const response = await api.put(url, data, { headers });

  handleErrors(response);

  return response;
};

// GET BASE64 REQUEST
// @deprecated in favour of getUserImageUrl?
export const get_base64 = async (url, params = {}, headerItems = {}) => {
  const headers = createHeaders({ Accept: 'application/base64', ...headerItems });
  const response = await api.get(url, params, { headers });

  handleErrors(response);

  return response;
};

// DELETE REQUEST
export const _delete = async (url, params = {}, headerItems = {}) => {
  const headers = createHeaders(headerItems);
  const response = await api.delete(url, params, { headers });

  handleErrors(response);

  return response;
};
