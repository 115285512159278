// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
import styles from './QuestionName.module.scss';

// 3RD PARTY
import { useSelector } from 'react-redux';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import { selectUserFirstName, selectUserLastName } from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
const Config = {
  minLength: 2,
};


// COMPONENT: QuestionName
const QuestionName = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer,
  } = props;

  const translate = useTranslate();

  const firstNameStore = useSelector(selectUserFirstName);
  const lastNameStore = useSelector(selectUserLastName);

  const [ nameSetFromStore, setNameSetFromStore ] = useState();
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');

  // HELPERS
  const checkAnswers = useCallback((first, last) => {
    const isValid = first?.length >= Config.minLength && last?.length >= Config.minLength;
    onAnswer?.({
      firstName: first,
      lastName: last,
      isValid,
    });
  }, [ onAnswer ]);

  // COMPONENT/UI STATE and REFS
  useEffect(() => {
    if (nameSetFromStore) {
      return;
    }

    const firstNameInternal = answer.firstName || firstNameStore;
    const lastNameInternal = answer.lastName || lastNameStore;
    setFirstName(firstNameInternal);
    setLastName(lastNameInternal);
    setNameSetFromStore(true);

    if (!nameSetFromStore && (firstNameInternal || lastNameInternal)) {
      checkAnswers(firstNameInternal, lastNameInternal);
    }
  }, [ answer, firstNameStore, lastNameStore, nameSetFromStore, checkAnswers ]);

  // EVENT HANDLES
  const handleFirstName = (value) => {
    setFirstName(value);
    checkAnswers(value, lastName);
  };

  const handleLastName = (value) => {
    setLastName(value);
    checkAnswers(firstName, value);
  };


  // RENDER: QuestionName
  return (
    <div className={styles.questionName}>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={firstName}
          label={translate('cp_firstname_placeholder')}
          onChange={handleFirstName}
        />
      </div>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={lastName}
          label={translate('cp_lastname_placeholder')}
          onChange={handleLastName}
        />
      </div>
    </div>
  );
};

export default QuestionName;
