// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './QuestionLocation.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { InputNext, DropDownSearchable } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import { useSelector } from 'react-redux';
import { selectCountries } from 'features/framework/storeNext/staticValuesSlice';

// CONFIG & DATA
const Config = {
  preSelectedValue: 'de',
};


// COMPONENT: QuestionLocation
const QuestionLocation = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer,
    onAnswer,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // COMPONENT/UI STATE and REFS
  const countries = useSelector(selectCountries);

  const [ country, setCountry ] = useState(answer?.country ?? Config.preSelectedValue);
  const [ city, setCity ] = useState(answer?.city);

  useEffect(() => {
    onAnswer?.({ country, city, isValid: Boolean(country) });
  }, [ onAnswer, country, city ]);


  // RENDER: QuestionLocation
  return (
    <div className={classNames(styles.questionLocation)}>
      <div className={styles.formRow}>
        <DropDownSearchable
          size={size}
          placeholder={translate('please_select_lbl')}
          filterPlaceholder={translate('search_lbl')}
          activeValue={country}
          options={countries}
          onChange={(option) => setCountry(option?.value ?? '')}
        />
      </div>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={city}
          label={translate('cp_city_placeholder')}
          onChange={(option) => setCity(option ?? '')}
        />
      </div>
    </div>
  );
};

export default QuestionLocation;
