import React from 'react';
import styles from './WorkPreferencesAssessmentEnd.module.scss';

// 3RD PARTY
import { useHistory } from 'react-router';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentResult } from 'store/actions';
import { selectAssessments } from 'store/selectors/assessment';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { getTopParentAssessmentId } from 'utils/assessment';

// CONFIG & DATA
import { getTranslationIds } from 'ui/molecules/AssessmentNext/AssessmentNext.translations';
import { ASSESSMENT_NEXT_TYPES } from 'ui/molecules/AssessmentNext/AssessmentNext.config';


const WorkPreferencesAssessmentEnd = (props) => {
  const {
    finishable,
    handleFinish,
    errorEnd,
  } = props;

  const dispatch = useDispatch();
  const translate = useTranslate();
  const history = useHistory();

  const assessmentsFromStore = useSelector(selectAssessments);

  const assessmentType = ASSESSMENT_TYPES.WORK_PREFERENCES;
  const translationIds = getTranslationIds(ASSESSMENT_NEXT_TYPES[assessmentType]);
  const topParent = getTopParentAssessmentId(assessmentType, assessmentsFromStore);

  localStorage.removeItem(`chat_${localStorage.getItem('token')}`);

  const handleViewResults = () => {
    dispatch(getAssessmentResult(assessmentType));
    handleFinish?.(() => history.push(`/my-profile/strength-profile?route=${topParent}`));
  };


  return (
    <div
      className={styles.assessmentEnd}
      data-test='AssessmentEnd'
    >
      { errorEnd && (errorEnd.message || translate('assessment_save_answers_error')) }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role='img' aria-label='applause'>
            &#128588;
          </span>

          <div className={styles.sTitle}>
            { translate(translationIds.successTitle) }
          </div>

          <div className={styles.buttons}>
            <Button
              size='M'
              looks='secondary'
              disabled={!finishable}
              onClick={handleViewResults}
            >
              { translate('assessment_view_results') }
            </Button>
          </div>
        </>
      ) }

    </div>
  );
};

export default WorkPreferencesAssessmentEnd;
