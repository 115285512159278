// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './VacancyExternalMatching.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { ListNext } from 'ui/basic/molecules/ListNext';
import { ProfileSummary } from 'features/+candidates/pages/VacancySummary/ProfileSummary';
import { mapStaffingData } from 'ui/basic/molecules/ListNext/ListNext.data';
import {
  TeamBig5Result, TeamCompetenciesResult,
  TeamNineLevelsResult,
  TeamPotentialResult,
  TeamRmpResult, TeamSkillsResult,
  TeamWorkPrefResult,
} from 'ui/molecules';

// UTILS
import { useTranslate } from 'utils/translator';
import { getFullName, getMappedCandidate } from 'utils/users';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// STORE
import * as api from 'api';
import { CandidateQuickView } from 'features/+candidates/pages/VacancySummary/CandidateQuickView';
import { convertCandidateStartDateToLocale } from 'utils/dateTools';

// CONFIG & DATA
// const Config = {};

const CANDIDATE_INFO = [
  {
    value: 'careerLevel',
    labelKey: 'current_career_level',
    labelFallback: 'Akt. Karrierestufe',
  },
  {
    value: 'desiredSalaryString',
    labelKey: 'desired_salary',
    labelFallback: 'Gehaltswunsch p.a.',
  },
  {
    value: 'desiredWeeklyHours',
    labelKey: 'desired_workload',
    labelFallback: 'Auslastungswunsch',
  },
  {
    value: 'earliestStart',
    labelKey: 'start_termin',
    labelFallback: 'Start-Termin',
  },
  {
    value: 'residence',
    labelKey: 'residence',
    labelFallback: 'Wohnort / Remote-Anteil',
  },
  {
    value: 'industryExperienceMapped',
    labelKey: 'industry_experience',
    labelFallback: 'Branchenerfahrung',
  },
  {
    value: 'motivation',
    labelKey: 'motivation',
    labelFallback: 'Motivation',
  },
];

// COMPONENT: VacancyExternalSummary
const VacancyExternalMatching = (props) => {
  // PROPS
  const { vacancy, token } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // VACANCY CANDIDATES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ candidates, setCandidates ] = useState([]);
  useEffect(() => {
    api.get(`/recruiting/jobs/${vacancy.id}/candidates?expand=user&expand=flow`)
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setCandidates(data.users);
      }
    });
  }, [ token, vacancy ]);

  // VACANCY MATCHES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ vacancyMatches, setVacancyMatches ] = useState();
  const [ vacancyMatchesLoading, setVacancyMatchesLoading ] = useState();
  useEffect(() => {
    if (vacancy.role && !vacancyMatches) {
      setVacancyMatchesLoading(true);

      api.get(
        `recruiting/jobs/${vacancy.id}/matches`,
        { expand: 'user' },
      ).then(({ ok, status, data }) => {
        setVacancyMatchesLoading(false);
        if (ok && status === 200) {
          setVacancyMatches(data.matches);
        }
      });
    }
  }, [ token, vacancy, vacancyMatches ]);

  // VACANCY PROFILE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ vacancyProfile, setVacancyProfile ] = useState();
  useEffect(() => {
    if (vacancy.role && !vacancyProfile) {
      api.get(
        `recruiting/jobs/${vacancy.id}/profile`,
        { expand: 'user' },
      ).then(({ ok, status, data }) => {
        const vacancyProfileInternal = data;

        if (ok && status === 200) {
          // get vacancy candidates to fetch users data
          api.get(
            `recruiting/jobs/${vacancy.id}/candidates`,
            { expand: 'user' },
          ).then(({ data: dataInner }) => {
            const vacancyCandidates = dataInner.users;

            vacancyProfileInternal.candidates.forEach((profile) => {
              profile.results.forEach((r) => {
                r.values.forEach((value) => {
                  const thisCandidate = vacancyCandidates
                  .find((vCandidate) => vCandidate.user.id === value.user);

                  if (thisCandidate) {
                    // eslint-disable-next-line no-param-reassign
                    value.user = {
                      id: thisCandidate.user.id,
                      name: getFullName(thisCandidate.user),
                      isAnonymous: thisCandidate.user.isAnonymous,
                    };
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    value.user = {
                      id: value.user,
                      name: value.user,
                    };
                  }
                });
              });
            });

            setVacancyProfile(vacancyProfileInternal);
          });
        }
      });
    }
  }, [ token, vacancy, vacancyProfile ]);

  // CANDIDATE QUICK VIEW: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ candidateForQuickView, setCandidateForQuickView ] = useState();
  const [ candidateQuickViewPanelVisible, setCandidateQuickViewPanelVisible ] = useState();

  // MORE CONTENT FOR CANDIDATE MATCHING: STATE, EFFECTS, STORE, METHODS,
  // EVENT HANDLES, HELPERS, RENDERS
  const getCandidateMoreContent = (candidateId) => {
    let profileData;

    if (candidates && candidates.find((c) => c.user.id === candidateId)) {
      const thisCandidate = candidates.find((c) => c.user.id === candidateId);
      if (thisCandidate && thisCandidate.user) {
        profileData = getMappedCandidate(thisCandidate.user).profileData;
      }
    }

    const renderRowItem = (candidateInfo) => {
      if (!candidateInfo) {
        return null;
      }

      let value = profileData?.[candidateInfo.value];
      value = convertCandidateStartDateToLocale(value);
      if (Array.isArray(value)) {
        value = value.filter(Boolean);
        value = value.length
          ? <ul>{ value.map((v) => <li key={v}>{ v }</li>) }</ul>
          : null;
      }
      value = value || '-';

      return (
        <div className={styles.rowItem}>
          <div className={styles.label}>
            { translate(candidateInfo.labelKey) || candidateInfo.labelFallback }
          </div>
          <div className={styles.value}>
            { value }
          </div>
        </div>
      );
    };

    const rows = [];
    for (let i = 0; i < 8; i += 2) {
      rows.push(
        <div className={styles.row} key={i}>
          { renderRowItem(CANDIDATE_INFO[i]) }
          { renderRowItem(CANDIDATE_INFO[i + 1]) }
        </div>,
      );
    }

    return (
      <div className={styles.candidateDetailedContent}>
        { rows }
      </div>
    );
  };

  // RENDER VACANCY PROFILE
  const renderVacancyProfiles = () => {
    const assessments = [];

    if (!vacancyProfile || !vacancyProfile.candidates) {
      return [];
    }

    vacancyProfile.candidates.forEach((vProfile) => {
      let referenceProfile;
      if (vacancyProfile.reference && vacancyProfile.reference.profile) {
        const thisRefProfile = vacancyProfile.reference.profile
        .find((p) => p.assessmentId === vProfile.assessment);
        if (thisRefProfile) {
          referenceProfile = thisRefProfile;
        }
      }

      switch (vProfile.assessment) {
        case ASSESSMENT_TYPES.BIG5:
          assessments[0] = (
            <TeamBig5Result
              key={vProfile.assessment}
              profile={vProfile}
              referenceProfile={referenceProfile}
              showAverage={false}
            />
          );
          break;
        case ASSESSMENT_TYPES.RMP:
          assessments[1] = (
            <TeamRmpResult
              key={vProfile.assessment}
              profile={vProfile}
              referenceProfile={referenceProfile}
            />
          );
          break;
        case ASSESSMENT_TYPES.NINE_LEVELS:
          assessments[2] = (
            <TeamNineLevelsResult
              key={vProfile.assessment}
              profile={vProfile}
              referenceProfile={referenceProfile}
              showAverage={false}
            />
          );
          break;
        case ASSESSMENT_TYPES.WORK_PREFERENCES:
          assessments[3] = (
            <TeamWorkPrefResult
              key={vProfile.assessment}
              profile={vProfile}
              referenceProfile={referenceProfile}
            />
          );
          break;
        case ASSESSMENT_TYPES.POTENTIAL:
          assessments[4] = (
            <TeamPotentialResult
              key={vProfile.assessment}
              profile={vProfile}
              referenceProfile={referenceProfile}
              showAverage={false}
            />
          );
          break;
        case ASSESSMENT_TYPES.KEY_COMPETENCIES:
          assessments[5] = (
            <TeamCompetenciesResult
              key={vProfile.assessment}
              assessmentType={ASSESSMENT_TYPES.KEY_COMPETENCIES}
              profile={vProfile}
              referenceProfile={referenceProfile}
              showAverage={false}
            />
          );
          break;
        case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
          assessments[6] = (
            <TeamCompetenciesResult
              key={vProfile.assessment}
              assessmentType={ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES}
              profile={vProfile}
              referenceProfile={referenceProfile}
              showAverage={false}
            />
          );
          break;
        default:
          assessments.push(
            <TeamSkillsResult
              key={vProfile.assessment}
              assessmentType={vProfile.assessment}
              profile={vProfile}
              referenceProfile={referenceProfile}
              showAverage={false}
            />,
          );
      }
    });

    return assessments.filter(((assessment) => assessment));
  };

  // RENDER ROLE LOADING SKELETON
  const renderVacancyMatchesLoadingSkeleton = () => {
    const rows = [];

    for (let i = 0; i < 11; i += 1) {
      rows.push(
        <div key={i} className={styles.contentBlock}>
          <div className={styles.row} />
          <div className={styles.row} />
        </div>,
      );
    }

    return (
      <div className={styles.vacancyMatchesLoadingSkeleton}>
        <div className={styles.top} />
        { rows }
      </div>
    );
  };

  // RENDER: VacancyExternalSummary
  return (
    <div className={classNames(styles.vacancyExternalMatching)}>
      <div className={styles.gridContainer}>
        { /* LEFT CONTENT */ }
        <div className={styles.leftContent}>
          <ProfileSummary vacancyBaseInfo={vacancy} showControls={false} teamClickable={false} />
        </div>

        { /* RIGHT CONTENT */ }
        <div className={styles.rightContent}>
          <div className='bluTypeXxs'>{ translate('vacancy_subnav_matching') }</div>

          { !vacancy.role && (
            <div className={styles.noRole}>
              { translate('vacancy_no_role_copy') }
            </div>
          ) }

          { vacancyMatchesLoading && renderVacancyMatchesLoadingSkeleton() }

          { (candidates?.length > 0 && vacancyMatches) && (
            <div className={styles.candidatesMatching}>
              <div className={styles.header}>
                <div className='bluTypeLabel'>{ translate('role_fit_summary') }</div>
              </div>
              <ListNext
                listItems={mapStaffingData(vacancyMatches)}
                dataType='staffing'
                hasTooltips
                cellLastXsBehavior='addCellLastXs'
                layout='layout5Cells'
                layout5CellsCompactMode
                showArrow={false}
                showMore
                disableItem={() => true}
                listItemsMoreContent={vacancyMatches.map((match) => ({
                  id: match.user.id,
                  content: getCandidateMoreContent(match.user.id),
                }))}
                options={[ { value: 'quickViewProfile', label: translate('candidate_profile_quick_view') } ]}
                onOptionClick={(optionValue, itemId) => {
                  switch (optionValue) {
                    case 'quickViewProfile':
                      if (candidates) {
                        const thisCandidate = candidates
                        .find((candidate) => candidate.user.id === itemId);
                        setCandidateForQuickView(getMappedCandidate(thisCandidate.user));
                      }

                      setCandidateQuickViewPanelVisible(true);
                      break;
                    default:
                  }
                }}
              />
            </div>
          ) }

          { /* VACANCY PROFILE */ }
          { vacancyProfile?.candidates?.length > 0 && (
            <div className={styles.vacancyProfiles}>
              { renderVacancyProfiles() }
            </div>
          ) }
        </div>
      </div>

      { /* CANDIDATE QUICK VIEW PANEL VISIBLE */ }
      { candidateQuickViewPanelVisible && createPortal(
        <CandidateQuickView
          token={token}
          candidate={candidateForQuickView}
          vacancyId={vacancy.id}
          role={vacancy.role}
          showToggle={vacancy.role && candidateForQuickView.profileCreated}
          onClose={() => setCandidateQuickViewPanelVisible(false)}
        />,
        document.body,
      ) }
    </div>
  );
};

export default VacancyExternalMatching;
