import * as action from 'store/actions';
import * as fromStaticValueActions from 'store/actions/staticValues';
import * as localStorage from 'utils/localStorage';
import { put, takeEvery } from 'redux-saga/effects';


function* changeLanguage({ payload }) {
  try {
    const { language, refetchStaticValues = false } = payload;

    localStorage.setItem('selectedLanguage', language);
    yield put(action.getTranslations(language));
    yield put(action.changeLanguageFulfilled(language));

    if (refetchStaticValues) {
      yield put(fromStaticValueActions.getCountries());
      yield put(fromStaticValueActions.getEducation());
      yield put(fromStaticValueActions.getIndustries());
      yield put(fromStaticValueActions.getJobFamilies());
      yield put(fromStaticValueActions.getCareerLevels());

      // Force refetch of wellbeing quote when user navigates to a page where it's used
      yield put(action.getWellBeingIndexFulfilled({}));
    }
  } catch (error) {
    yield put(action.changeLanguageRejected(error));
  }
}

export default function* watchChangeLanguageRequest() {
  yield takeEvery(action.CHANGE_LANGUAGE, changeLanguage);
}
