// CONFIG & DATA
import { REACT_APP_API_BASE_URL } from 'utils/configuration';
import { LANGUAGES } from 'utils/configuration/const/languages';
import { returnToUrl } from 'features/+coachHub/utils/returnUrl';
import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';
import { getTokenNameForCurrentPage, handleErrorLogout } from 'api';

const Config = {
  errorQueue: {
    throwErrorAfter: 150, // ms
  },
};


export const apiNext = {
  symRes: Symbol('response'),
  getUrlBase() {
    let baseUrl = REACT_APP_API_BASE_URL;
    if (baseUrl[baseUrl.length - 1] === '/') {
      // remove any trailing slash
      baseUrl = baseUrl.slice(0, -1);
    }
    return baseUrl;
  },
  getUrlFull(url = '', getParams = {}) {
    // get full url
    const fullUrl = `${this.getUrlBase()}/${url.replace(/^\//, '')}`;

    // merge params from url and getParams object
    const fullUrlWithParams = this.mergeGetParams(fullUrl, getParams);

    return fullUrlWithParams;
  },
  mergeGetParams(url, getParams = {}) {
    // merge params from url and getParams object
    const urlObj = new URL(url);
    const paramsString = new URLSearchParams([
      ...Array.from(urlObj.searchParams.entries()),
      ...Object.entries(getParams).filter(([ , v ]) => v !== undefined),
    ]).toString();
    urlObj.search = '';

    const urlWithParams = paramsString
      ? `${urlObj.toString()}?${paramsString}`
      : url;

    return urlWithParams;
  },
  getHeaderDefaults() {
    return {
      'Content-Type': 'application/json',
      'Accept-Language': `${localStorage.getItem('selectedLanguage') || LANGUAGES.EN}, *`,
      Authorization: `Bearer ${localStorage.getItem(getTokenNameForCurrentPage())}`,
    };
  },
  getFetchParams({
    method = 'GET',
    url = '',
    body, // usually an object, but allow undefined for GET
    getParams = {},
    extraHeaders = {},
    extraOptions = {},
  }) {
    // full url with get params
    const urlFull = this.getUrlFull(url, getParams);

    // headers & default headers
    const headers = {
      ...this.getHeaderDefaults(),
      ...extraHeaders,
    };

    // fetchOptions
    const fetchOptions = {
      method,
      headers,
      body: JSON.stringify(body),
      ...extraOptions,
    };

    // const requestId
    const now = Date.now();
    const requestId = `${urlFull}||${now}`;

    return { urlFull, fetchOptions, requestId };
  },
  call({
    method = 'GET',
    url = '',
    body, // usually an object, but allow undefined for GET
    getParams = {},
    extraHeaders = {},
    extraOptions = {},
  }) {
    const { urlFull, fetchOptions, requestId } = this.getFetchParams({
      method,
      url,
      body,
      getParams,
      extraHeaders,
      extraOptions,
    });

    return fetch(urlFull, fetchOptions)
    .then(async (response) => {
      if (response.ok) {
        let res;
        if (response.headers.get('Content-Type').includes('application/json')) {
          res = response.json();
        } else {
          res = response.blob();
        }

        return res.then((data) => {
          if (data) {
            // add the response to the processed object as a hidden property
            Object.defineProperty(
              data,
              this.symRes,
              { value: response, enumerable: false },
            );
          }
          return data;
        });
      }

      const error = new Error(`${urlFull} HTTP status ${response.status}`);
      // add some context
      error.response = response;
      error.url = urlFull;
      error.requestId = requestId;
      try {
        // get error from response
        error.responseContent = await response.clone().json();
      } catch (e) {
        console.error(e);
      }
      throw error;
    })
    .catch((error) => { // throw for all non-HTTP errors
      if (!error.requestId) {
        // add some context
        error.url = urlFull;
        error.requestId = requestId;
      }

      // add to queue (which adds timer and resolve())
      error = errorQueue.add(error);

      // throw error
      throw error;
    });
    // TBD: do some globalised error handling here.
  },
  get(url = '', getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      url,
      getParams,
      extraHeaders,
      extraOptions,
    });
  },
  post(url = '', body = {}, getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      method: 'POST',
      url,
      body,
      getParams,
      extraHeaders,
      extraOptions,
    });
  },
  put(url = '', body = {}, getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      method: 'PUT',
      url,
      body,
      getParams,
      extraHeaders,
      extraOptions,
    });
  },
  patch(url = '', body = {}, getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      method: 'PATCH',
      url,
      body,
      getParams,
      extraHeaders,
      extraOptions,
    });
  },
  delete(url = '', getParams = {}, extraHeaders = {}, extraOptions = {}) {
    return this.call({
      method: 'DELETE',
      url,
      getParams,
      extraHeaders,
      extraOptions,
    });
  },

};

export default apiNext;

export const errorQueue = {
  queue: { },
  add(error) {
    if (!error.requestId) {
      console.warn(JSON.stringify(error, null, 2));
      throw new Error(`apiNext.errorQueue.add can't add error without requestId: ${error}`);
    }
    // TODO: theoretically two requests with the same url could happen at the exact same time (Date.now())
    // then their requestId would be the same.

    // create timer for handleUnhandledError
    const timer = setTimeout(() => {
      this.handleUnhandledError(error);
    }, Config.errorQueue.throwErrorAfter);

    // add timer
    error.timer = timer;

    // add resolve
    error.resolve = () => {
      errorQueue.resolve(error);
    };

    // add error to queue
    this.queue[error.requestId] = error;

    // return updated error
    return error;
  },
  resolve(error) {
    if (!error.requestId) {
      console.warn(JSON.stringify(error, null, 2));
      throw new Error(`apiNext.errorQueue.resolve can't resolve error without requestId: ${error}`);
    }

    // clear timeout
    if (error.timer) clearTimeout(error.timer);

    // remove from queue
    delete this.queue[error.requestId];
  },
  handleUnhandledError(error) {
    // TODO: global error handling with Modal, Toast Message, or smth. similar
    // allowing user to report the error, discuss with UX/BE.
    console.error('unhandled error');
    console.error(error);
    console.error(JSON.stringify(error, null, 2));
    this.resolve(error);

    // Error handling for CoachHub: redirect
    if (getIsExternalSystemIntegrationSupported()) {
      // Canceled calls are ok
      if (error?.name === 'AbortError') {
        return;
      }

      // 1406 is handled by DiscAssessmentPage assessment
      if (error?.responseContent?.error?.errorCode === 1406) {
        return;
      }

      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'error',
          error_type: 'action_error',
          error_message: error?.message,
          // error_code: '',
        },
      });
      return;
    }

    // Regular bq instances
    handleErrorLogout(error?.responseContent?.error?.errorCode);
  },
};
