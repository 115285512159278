import React from 'react';

// INTERMISSIONS
import { Start } from '../components/onboarding/admin/Start';
import { Employees } from '../components/onboarding/admin/Employees';
import { Teams } from '../components/onboarding/admin/Teams';
import { Jobs } from '../components/onboarding/admin/Jobs';
import { Vacancies } from '../components/onboarding/admin/Vacancies';
import { Potential } from '../components/onboarding/admin/Potential';
import { Data } from '../components/onboarding/admin/Data';
import { NextAction } from '../components/onboarding/admin/NextAction';

// OTHER COMPONENTS
import { StoreNext } from 'ui/basic';

export const adminOnboardingConfig = {
  id: 'adminOnboarding',
  title: 'introduction_header_title',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 0,
  pages: [
    {
      isIntermission: true,
      passControls: true,
      noPrev: true,
      Component: Start,
    },
    {
      isIntermission: true,
      passControls: true,
      customLayout: true,
      wideControls: true,
      Component: Employees,
    },
    {
      isIntermission: true,
      passControls: true,
      customLayout: true,
      wideControls: true,
      Component: Teams,
    },
    {
      isIntermission: true,
      passControls: true,
      customLayout: true,
      wideControls: true,
      Component: Jobs,
    },
    {
      isIntermission: true,
      passControls: true,
      customLayout: true,
      wideControls: true,
      Component: Vacancies,
    },
    {
      isIntermission: true,
      passControls: true,
      Component: Potential,
    },
    {
      isIntermission: true,
      passControls: true,
      Component: Data,
    },
    {
      isIntermission: true,
      passControls: true,
      noNext: true,
      Component: NextAction,
    },
  ],
};
