// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './FlowCard.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { Card, Modal, SkeletonFlexible } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { timestampToFullDate } from 'utils/dateTools';
import { getFullName } from 'utils/users';
import { apiNext } from 'apiNext';

// STORE
import { useDispatch } from 'react-redux';
import { listUserFlows, getUserFlowHistory, showToast } from 'store/actions';


// COMPONENT: FlowCard
const FlowCard = (props) => {
  const {
    user,
    userFlow,
    showActions = false,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();

  const [ historyModalOpen, setHistoryModalOpen ] = useState(false);
  const [ triggeringAction, setTriggeringAction ] = useState(false);

  // Activate a manual trigger for the user flow
  const triggerFlowAction = async (triggerId) => {
    let toastTitle = translate('flow_card_trigger_toast_title');
    let toastDescription = translate('flow_card_trigger_toast_description');
    try {
      setTriggeringAction(true);
      await apiNext.post(`/core/flows/user/${user.id}/flow/${userFlow.id}/trigger`, { triggerId });
      dispatch(listUserFlows({ id: user.id })); // refetch data again
    } catch (error) {
      console.error(error);
      toastTitle = null;
      toastDescription = translate('api_error_generic');
    } finally {
      dispatch(showToast(toastTitle, toastDescription));
      setTriggeringAction(false);
    }
  };

  const onShowHistory = () => {
    setHistoryModalOpen(true);
    dispatch(getUserFlowHistory({ userId: user.id, userFlowId: userFlow.id }));
  };


  // RENDER: CellValue
  const renderCellValue = (value) => {
    if (!value) {
      return '-';
    }
    if (typeof value === 'number') {
      return timestampToFullDate(value);
    }
    return <strong>{ value }</strong>;
  };

  // RENDER: FlowCard
  if (!userFlow) {
    return null;
  }

  return (
    <div className={classNames(styles.flowCard)}>
      <Card>
        <div className='bluTypeLabelL'>
          { translate('flow_card_header') }
        </div>
        <h6>{ userFlow.name }</h6>
        <div
          className={styles.showLink}
          role='button'
          tabIndex={0}
          onClick={onShowHistory}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.stopPropagation();
              onShowHistory();
            }
          }}
        >
          <span>
            { translate('wellbeing_ind_result_show_progress_btn') }
          </span>
          <IconsSvg.Chart />
        </div>

        <div className={styles.actualStatus}>
          <div className={styles.actualStatusRow}>
            <div>
              { translate('flow_card_current_status') }
            </div>
            <div className={styles.actualStatusPill}>
              { userFlow.currentStage.name }
            </div>
          </div>
          <div className={styles.actualStatusRow}>
            <div>
              { translate('flow_card_status_since') }
            </div>
            <div>
              { timestampToFullDate(userFlow.currentStage.entryDate) }
            </div>
          </div>
        </div>

        <div className={styles.flowCardActions}>
          { showActions && userFlow.currentStage.triggers
          .filter((el) => el.type === 'manual' && el.userAvailable)
          .map((el) => (
            <div
              key={el.id}
              className={classNames(
                styles.showLink,
                { [styles.disabled]: triggeringAction },
              )}
              role='button'
              tabIndex={0}
              onClick={() => triggerFlowAction(el.id)}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  event.stopPropagation();
                  triggerFlowAction(el.id);
                }
              }}
            >
              <IconsSvg.Play />
              <span>
                { el.name }
              </span>
            </div>
          )) }
        </div>
      </Card>

      { historyModalOpen && (
        <Modal
          header={translate('flow_card_history_header')}
          onClose={() => setHistoryModalOpen(false)}
          secondaryButtonTitle={translate('close_lbl')}
        >
          <div className={styles.flowHistoryModal}>
            <div className={styles.intro}>
              { translate('flow_card_history_intro', [
                '{{username}}', getFullName(user),
                '{{process}}', userFlow.name,
              ]) }
            </div>

            { !userFlow.history
              ? <SkeletonFlexible noHeader />
              : (
                <div className={styles.columns}>
                  { userFlow.history.map((column) => (
                    <div className={styles.row} key={column.entryDate}>
                      { [ 'name', 'entryDate', 'exitDate' ].map((key) => (
                        <div className={styles.rowItem} key={key}>
                          <div className={styles.hint}>
                            { translate(`flow_card_history_key_${key}`) }
                          </div>
                          <div className={styles.copy}>
                            { renderCellValue(column[key]) }
                          </div>
                        </div>
                      )) }
                    </div>
                  )) }
                </div>
              ) }
          </div>
        </Modal>
      ) }
    </div>
  );
};

export default FlowCard;
