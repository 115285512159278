// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useRef, useEffect } from 'react';
import styles from './InputNext.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';
import { ReactComponent as ArrowLeftLong } from 'assets/icons/icn_arrow_left_long.svg';
import { ReactComponent as VisibilityOn } from 'assets/icons/icn_vis_on.svg';


// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { capitalise } from 'utils/textTools';


// COMPONENT: InputNext
const InputNext = (props) => {
  // PROPS
  const {
    // dom props
    id,
    className,
    size = '', // M (default), L, Responsive
    name,
    type = 'text',
    inputMode = 'text',
    disabled,
    autofocus,
    autoComplete,
    placeholder, label, value,
    // component props
    hint = '',
    hintRight = '',
    domain,
    errorMessage,
    icon,
    maxLength,
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
    onClick = () => {},
    onConfirm,
    clearValueOnConfirm = true,
    validate,
    onError = () => {},
    onArrowUp = () => {},
    onArrowDown = () => {},
    bigButton = false,
    bigButtonDisabled = true,
  } = props;

  // COMPONENT/UI STATE and REFS
  const inputRef = useRef(null);
  const [ focus, setFocus ] = useState(false);
  const [ valueInternal, setValueInternal ] = useState(value || '');
  const [ typeInternal, setTypeInternal ] = useState(type);
  const [ smallLabel, setSmallLabel ] = useState(!!value);
  const [ error, setError ] = useState(null);

  const Icon = icon && IconsSvg[icon];
  const { ArrowRight } = IconsSvg;

  // HELPERS
  const getError = (valueParam, validateParam = {}) => {
    const errorInternal = {};

    // required
    if (validateParam.required && (!valueParam || !valueParam.length)) {
      errorInternal.required = true;
    }
    // min
    if (typeof validateParam.min === 'number' && valueParam.length < validateParam.min) {
      errorInternal.min = true;
    }
    // max
    if (typeof validateParam.max === 'number' && valueParam.length > validateParam.max) {
      errorInternal.max = true;
    }
    // pattern
    if (validateParam.pattern && valueParam && !valueParam.match(validateParam.pattern)) {
      errorInternal.pattern = true;
    }

    if (Object.keys(errorInternal).length === 0) {
      return null;
    }

    return errorInternal;
  };

  // METHODS
  const validateValue = (valueParam, validateParam) => {
    const errorInternal = getError(valueParam, validateParam);
    setError(errorInternal);
    onError(errorInternal);
    return errorInternal;
  };

  const confirm = () => {
    // no onConfirm => do nothing
    if (!onConfirm) {
      return;
    }
    // no validate => call onConfirm and return
    if (!validate) {
      onConfirm(valueInternal);

      if (clearValueOnConfirm) {
        setValueInternal('');
      }
      return;
    }

    // check for errors
    const errorInternal = validateValue(valueInternal, validate);
    if (!errorInternal) {
      onConfirm(valueInternal);

      if (clearValueOnConfirm) {
        setValueInternal('');
      }
      // prevent empty confirms with onChange and required
      if (validate.onChange && validate.required) {
        validateValue(valueInternal, validate);
      }
    }
  };

  useEffect(() => {
    setValueInternal(value || '');
    setSmallLabel(!!value);
  }, [ value ]);

  useEffect(() => {
    setTypeInternal(type);
  }, [ type ]);

  useEffect(() => {
    if (autofocus) {
      inputRef.current.focus();
    }
  }, [ autofocus ]);

  // RENDER: InputNext
  const domProps = {
    id,
    type: typeInternal,
    value: valueInternal,
    inputMode,
    disabled,
    name,
    autoComplete,
    placeholder,
    maxLength,
  };
  return (
    // CONTAINER & onConfirm
    <div
      role='presentation'
      className={classNames(styles.inputContainer, {
        [styles[`size${capitalise(size)}`]]: size,
      })}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          confirm();
        }
      }}
    >
      { /* LABEL */ }
      { label && !placeholder && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          className={`${styles.label} ${classNames({ [styles.small]: smallLabel, [styles.disabled]: disabled })}`}
        >
          { label }
        </label>
      ) }

      <input
        className={classNames(styles[className], styles.input, {
          [styles.placeholder]: placeholder,
          [styles.hasIcon]: icon,
          [styles.hasDomain]: domain,
          [styles.hasHintRight]: Boolean(hintRight),
          [styles.hasConfirmButton]: (onConfirm && clearValueOnConfirm) && !domain,
          [styles.error]: error || errorMessage,
        })}
        {...domProps}
        ref={inputRef}
        noValidate
        onChange={(event) => {
          if (error) { // reset error onChange
            setError(null);
            onError(null);
          }

          // handle new value
          const targetValue = event.target.value;
          setValueInternal(targetValue);
          // fire on change?
          onChange(targetValue, getError(targetValue, validate));
          if (validate?.onChange) {
            validateValue(targetValue, validate);
          }
        }}
        onFocus={() => {
          setSmallLabel(true);
          setFocus(true);
          // prevent empty confirms with onChange and required
          if (validate?.onChange) {
            validateValue(valueInternal, validate);
          }
          onFocus();
        }}
        onBlur={() => {
          setSmallLabel(!!valueInternal);
          setFocus(false);
          onBlur(valueInternal);
          // allow empty when not required
          if (validate && !validate.required) {
            setError(null);
            onError(null);
          } else if (!validate?.onChange) {
            validateValue(valueInternal, validate);
          }
        }}
        onClick={onClick}
        onKeyUp={(event) => {
          if (event.key !== 'Enter') event.stopPropagation();
          // preventive measure so form elements in Dialogs / Assessments etc.
          // don't trigger page navigations
          if (event.key === 'ArrowUp') {
            onArrowUp(event);
          } else if (event.key === 'ArrowDown') {
            onArrowDown(event);
          }
        }}
        onKeyDown={(event) => {
          // Prevent inclusion of letter 'e' in number fields
          if (typeInternal !== 'number') {
            return;
          }
          const numberInputInvalidChars = [ 'e', 'E' ];
          if (numberInputInvalidChars.includes(event.key)) {
            event.preventDefault();
          }
        }}
      />

      { errorMessage && <div className={styles.errorMessage}>{ errorMessage }</div> }

      { /* HINT */ }
      { (hint && !errorMessage) && <div className={styles.hint}>{ hint }</div> }

      { hintRight && <div className={styles.hintRight}>{ hintRight }</div> }

      { domain && <div className={styles.domain}>{ domain }</div> }

      { /* ICON */ }
      { icon && <Icon className={styles.icon} /> }

      { /* confirmButton */ }
      { ((onConfirm && clearValueOnConfirm) && !domain && !bigButton) && (
        <div
          role='presentation'
          className={classNames(styles.confirmButton, { [styles.hide]: !(onConfirm && focus), [styles.error]: error })}
          onClick={() => {
            inputRef.current.focus(); // set focus again (lost due to click outside of input)
            confirm();
          }}
        >
          <ArrowRight />
        </div>
      ) }

      { /* bigButton */ }
      { bigButton && (
        <div
          role='presentation'
          className={classNames(styles.bigButton, {
            [styles.hide]: !focus,
            [styles.disabled]: bigButtonDisabled,
          })}
          onClick={() => {
            if (!bigButtonDisabled) {
              confirm();
            }
          }}
        >
          <ArrowLeftLong />
        </div>
      ) }

      { /* PASSWORD VISIBILITY ICON */ }
      { (type === 'password' && valueInternal.length > 0) && (
        <div
          role='presentation'
          className={classNames(styles.password, { [styles.visible]: typeInternal !== 'password' })}
          onClick={() => {
            if (typeInternal === 'password') {
              setTypeInternal('text');
            } else {
              setTypeInternal('password');
            }
          }}
        >
          <VisibilityOn />
        </div>
      ) }

    </div>
  );
};

export default InputNext;
