// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './AttachmentsCard.module.scss';
import { CollapsibleNext, Button, SkeletonFlexible } from 'ui/basic';

// ASSETS
import { IconsSvg } from 'assets/icons';

// UTILS
import { apiNext } from 'apiNext';
import { downloadFile } from 'utils/file';
import { useTranslate } from 'utils/translator';


// COMPONENT: CandidateAttachmentsCard
const AttachmentsCard = (props) => {
  // PROPS
  const {
    attachments,
    isLoading = false,
    readOnly = false,
    getDownloadParams,
    onAddEditClick,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const onAttachmentClick = async (e, attachment) => {
    if (attachment.type === 'file') {
      e.preventDefault();
      try {
        const params = getDownloadParams(attachment);
        const blob = await apiNext.get(...params);
        downloadFile(blob, attachment.name);
      } catch (error) {
        console.error(error);
      }
    }
  };


  // RENDER: ListItem
  const renderListItem = (attachment) => {
    const { type } = attachment;
    const Icon = type === 'link' ? IconsSvg.ExternalLink : IconsSvg.Document;

    return (
      <li className={styles[type]} key={attachment.id}>
        <a
          href={attachment.url || `/download/${attachment.id}`}
          target='_blank'
          rel='noreferrer'
          title={attachment.name || attachment.url}
          onClick={(e) => onAttachmentClick(e, attachment)}
        >
          <Icon />
          <span>{ attachment.name || attachment.url }</span>
        </a>
      </li>
    );
  };

  // RENDER: AttachmentsCard
  return (
    <div className={styles.attachmentsCard}>
      <CollapsibleNext
        header={translate('candidate_attachments_card_header')}
        headerClassName={styles.header}
        withBorders
        isOpenOverride
      >
        { isLoading
          ? <SkeletonFlexible noHeader />
          : (
            <>
              { !attachments?.length
                ? translate('candidate_attachments_nothing_added')
                : (
                  <ul className={styles.list}>
                    { attachments.filter((el) => el.type === 'link').map(renderListItem) }
                    { attachments.filter((el) => el.type === 'file').map(renderListItem) }
                  </ul>
                ) }

              { !readOnly && (
                <div className={styles.addButton}>
                  <Button
                    looks='secondary-transparent'
                    size='s'
                    onClick={onAddEditClick}
                  >
                    { translate('candidate_attachments_add_edit') }
                  </Button>
                </div>
              ) }
            </>
          ) }
      </CollapsibleNext>
    </div>
  );
};

export default AttachmentsCard;
