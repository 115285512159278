// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useReducer, useEffect, useCallback, useRef,
} from 'react';
import styles from './AssessmentNext.module.scss';

// TRANSLATIONS

// COMPONENT REDUCER & LOGIC

// ASSETS
import { ReactComponent as ArrowUp } from 'assets/icons/icn_arrow_up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/icn_arrow_down.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  ProgressBar, Modal, BluCSSTransition, Toast, Button,
} from 'ui/basic';
import { AssessmentHeader } from './components/AssessmentHeader';
import { StandaloneHeader } from 'features/framework/components/MainLayout/StandaloneWrapper/StandaloneHeader';

// UTILS
import { eventBus } from 'architecture/eventBus';
import { useTranslate } from 'utils/translator';
import { msToNumber } from 'utils/styleTools';
import { markdown } from 'utils/textTools';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';
import {
  validateQuestions,
  getQuestionRenderInfo,
  copyAssessmentConfig,
  createStateWithPages,
  storageController,
  hasStoredAnswers,
} from './AssessmentNext.logic';
import {
  init,
  reducer,
  initialState as reducerInitialState,
} from './AssessmentNext.reducer';
import { getTranslationIds } from './AssessmentNext.translations';

// STORE

// CONFIG & DATA
import { GLOBAL_CONFIG } from './AssessmentNext.config';

const CONFIG = {
  progressDelayFactor: 0.6,
  welcomeBackDelay: 450,
  inactivityTimeoutWarningFactor: 2 / 3,
};


// COMPONENT: AssessmentNext
const AssessmentNext = (props) => {
  // PROPS
  const {
    // mandatory props
    type, // assessment type
    userId, // for persistence identification
    configOverride, // override of assessmentConfig, mandatory but can be empty object
    questions, // when unset shows loading page
    newLanguageData,
    requiredQuestions,
    openQuestions,
    assessmentCompleted,
    showCopyright,
    isCoachHub = false,
    waitApiRequest = false,
    externalAssessmentToken = null,
    freeTextAiAssistanceEnabled = false,

    onAnswer = () => {},
    onCancel = () => {},
    onAllAnswers = () => {},
    onFinish = () => {},
    onError, // () => {},
  } = props;

  const translate = useTranslate();

  // PROPS UPDATES, INITIALIZATION
  // LOADING
  const [ loading, setLoading ] = useState(false);
  const [ loadingPage, setLoadingPage ] = useState('');
  const transitionRef = useRef();

  // REDUCER STATE
  const [ state, dispatchLocal ] = useReducer(reducer, reducerInitialState, init);

  const localBlock = useRef(false);
  const setLocalBlock = useCallback(() => {
    localBlock.current = true;
    setTimeout(() => {
      localBlock.current = false;
    }, GLOBAL_CONFIG.clickBlockDuration * 1.5);
  }, []);

  // alert when navigating away
  // show warning to user before navigating away
  const handleBeforeUnload = useCallback((event) => {
    event.stopImmediatePropagation?.();
    event.preventDefault();
    // Chrome requires returnValue to be set
    // eslint-disable-next-line no-param-reassign
    event.returnValue = '';
  }, []);

  // error message & handling via eventBus
  const [ errorMessage, setErrorMessage ] = useState('');
  const setErrorFromBus = useCallback(({ detail }) => {
    console.error(detail);
    let message = typeof detail.message === 'string'
      ? detail.message
      : JSON.stringify(detail, null, 2) || 'General Bus Error';
    message = translate(message);

    if (detail.returnToUrl && onError) {
      setErrorMessage(
        <>
          { message }
          <br />
          <br />
          <Button
            looks='danger'
            size='S'
            onClick={() => {
              window.removeEventListener('beforeunload', handleBeforeUnload);
              onError(message);
            }}
          >
            { translate('back_to_coachhub') }
          </Button>
        </>,
      );
    } else if (message) {
      setErrorMessage(
        <>
          { message }
          <br />
          <br />
          <Button
            looks='danger'
            size='S'
            onClick={() => {
              window.removeEventListener('beforeunload', handleBeforeUnload);
              window.location.reload();
            }}
          >
            { translate('reload_lbl') }
          </Button>
        </>,
      );
    } else {
      // Error as string
      setErrorMessage(message);
    }
  }, [ translate, handleBeforeUnload, onError ]);

  // Keep track of the answers currently being sent to backend
  const [ pendingAnswers, setPendingAnswers ] = useState(false);
  const answersBeingSent = useRef(new Set());
  const setAnswersBeingSent = ({ detail }) => {
    const { answerId, sending } = detail;
    if (sending) {
      answersBeingSent.current.add(answerId);
    } else {
      answersBeingSent.current.delete(answerId);
    }
    setPendingAnswers(answersBeingSent.current.size);
  };

  const lastQuestion = state.questionIndex === state.pages.length - 1;
  const waitApi = waitApiRequest || (Boolean(pendingAnswers) && lastQuestion);

  // Rehearse openQuestions at the end
  useEffect(() => {
    if (!pendingAnswers && assessmentCompleted === false && lastQuestion && openQuestions?.length) {
      dispatchLocal({
        type: 'shiftOpenQuestionsAtEnd',
        payload: {
          openQuestions,
        },
      });
    }
  }, [
    lastQuestion,
    openQuestions,
    assessmentCompleted,
    pendingAnswers,
  ]);

  // Handle local events by adding/removing listeners on mount/unmount
  useEffect(() => {
    const customEvents = {
      'assessmentNext.sendingAnswer': setAnswersBeingSent,
      'assessmentNext.error': setErrorFromBus,
    };

    Object.entries(customEvents).forEach(([ event, cb ]) => eventBus.addListener(event, cb));
    return () => Object.entries(customEvents).forEach(([ event, cb ]) => eventBus.removeListener(event, cb));
  }, [ setErrorFromBus ]);

  // modal welcome back
  const [ modalWelcomeBackShow, setModalWelcomeBackShow ] = useState(false);
  const [ modalWelcomeBackShown, setModalWelcomeBackShown ] = useState(false);

  useEffect(() => {
    if (newLanguageData) {
      dispatchLocal({
        type: 'retranslatePages',
        payload: newLanguageData,
      });
    }
  }, [ newLanguageData, translate ]);

  useEffect(() => {
    // show loading bar and possible loading page until everything is available
    if (!type || !userId || !questions || !configOverride) {
      setLoading(true);
      // show loading page if available and if it hasn't been set yet
      if (type && !loadingPage) {
        const assessmentConfig = copyAssessmentConfig(type);
        if (assessmentConfig) {
          const { loadingPage: configLoadingPage } = assessmentConfig;
          if (configLoadingPage && typeof configLoadingPage.render === 'function') {
            // call render function of loading page and pass in config
            setLoadingPage(configLoadingPage.render(assessmentConfig));
          }
        }
      }

      return undefined;
    }
    setLoading(false);
    setLoadingPage(false);

    // wait with initialisation until loadingPage and loading is over
    if (loadingPage || loading) {
      return undefined;
    }

    // validate questions: can all questions be rendered?
    const [ questionsValid, error ] = validateQuestions(questions);
    if (!questionsValid) {
      if (onError) {
        onError(error);
      }
      setErrorMessage((
        <>
          Empty Questions Array or Array containing faulty question(s):
          <br />
          { JSON.stringify(error, null, 2) }
        </>
      ));
      return undefined;
    }

    // set initialState based on storageState or config
    let initialState;
    let assessmentConfig = copyAssessmentConfig(type);
    // validate assessmentConfig
    if (assessmentConfig.error) {
      if (onError) {
        onError(assessmentConfig.error);
      }
      setErrorMessage((
        <>
          Error with assessmentConfig:
          <br />
          { assessmentConfig.error }
        </>
      ));
      return undefined;
    }

    const assessmentId = configOverride.customAssessmentId ?? type;

    const inGracePeriod = hasStoredAnswers(assessmentId, userId, externalAssessmentToken);

    // configOverride
    assessmentConfig = Object.assign(assessmentConfig, configOverride);

    // continue from storage
    storageController.init(assessmentId, userId, externalAssessmentToken);

    // load state from short cache if available or the assessment is multi session
    if (assessmentConfig.canContinueLater || inGracePeriod) {
      initialState = storageController.loadValidState(
        assessmentConfig,
        questions,
        assessmentConfig.removeFirstIntermissions,
      );
    }

    // show welcome back modal, if assessment was already started previously
    // and if the assessment supports multi session
    let modalWelcomeBackTimer;
    if (initialState && !modalWelcomeBackShown) {
      modalWelcomeBackTimer = setTimeout(() => {
        setModalWelcomeBackShow(true);
      }, CONFIG.welcomeBackDelay);
    }

    // initialise from props
    if (!initialState) {
      const stateWithPages = createStateWithPages(assessmentConfig, questions, assessmentConfig.intermissions);
      initialState = stateWithPages;
    }

    // userId
    initialState = Object.assign(initialState, { userId });

    dispatchLocal({ type: 'reset' });
    dispatchLocal({ type: 'override', payload: initialState });
    dispatchLocal({
      type: 'start',
      payload: Date.now(),
    });
    if (assessmentConfig.removeFirstIntermissions) {
      dispatchLocal({ type: 'removeNextIntermissions' });
    }
    // console.log('props updated', type, assessmentConfig, questions);

    // continue from backend
    const prevAnswers = configOverride?.prevAnswers || initialState?.prevAnswers;
    if (prevAnswers?.length && assessmentConfig.canContinueLater) {
      // show welcome back modal
      if (!modalWelcomeBackShown) {
        modalWelcomeBackTimer = setTimeout(() => {
          setModalWelcomeBackShow(true);
        }, CONFIG.welcomeBackDelay);
      }

      dispatchLocal({
        type: 'overrideAnswers',
        payload: { prevAnswers },
      });
    }

    // cleanup when there's a new call
    // so reducer persistence won't save new states with old keys
    return () => {
      if (storageController.isInitialised()) {
        storageController.reset();
      }
      clearTimeout(modalWelcomeBackTimer);
    };
  }, [
    type,
    userId,
    questions,
    configOverride,
    loadingPage,
    loading,
    modalWelcomeBackShown,
    onError,
    externalAssessmentToken,
  ]);

  // REDUCER STATE PERSISTENCE
  useEffect(() => {
    storageController.saveState(state);
  }, [ state ]);


  // STATE: ON ALL ANSWERS
  const [ finishable, setFinishable ] = useState(undefined);
  const [ loadingDuring, setLoadingDuring ] = useState(false);
  const [ errorEnd, setErrorEnd ] = useState();

  const allowFinish = useCallback((finished) => {
    // allow finish and unset loading
    setFinishable(!waitApi && finished);
    setLoadingDuring(false);
    setErrorEnd();
    window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [ waitApi, setFinishable, setLoadingDuring, setErrorEnd, handleBeforeUnload ]);

  const handleEnd = useCallback(() => {
    setLoadingDuring(true);
    onAllAnswers(state.answers, (finished) => {
      allowFinish(finished);
    });
  }, [ onAllAnswers, state.answers, allowFinish ]);

  useEffect(() => {
    if (!state.clickBlock) {
      // are we on last page?
      if (state.questionIndex === state.pages.length - 1) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        // are all questions answered?
        if (state.isEnd) {
          if (!state.manualEnd) {
            handleEnd();
          }
        } else {
          setErrorEnd(state.error);
        }
      } else {
        // reset UI when navigating away from last page
        setFinishable(undefined);
        setLoading(false);
        setErrorEnd();
      }
    }
  }, [ state, onAllAnswers, handleEnd, handleBeforeUnload ]);


  // COMPONENT/UI STATE and REFS
  // page buildup/teardown
  const [ hide, setHide ] = useState(false);

  // modals
  // modal hurry
  const [ modalHurryShow, setModalHurryShow ] = useState(false);
  const [ modalHurryWasShown, setModalHurryWasShown ] = useState(false);
  const noModalHurry = type.startsWith('survey'); // no hurry up modal for surveys

  const [ questionValueChangedAt, setQuestionValueChangedAt ] = useState(0);
  const handleChange = () => {
    const page = state.pages[state.questionIndex];

    if (page.type !== 'free-text') {
      return;
    }

    setQuestionValueChangedAt(new Date().valueOf());
  };

  const [ updateNavigation, setUpdateNavigation ] = useState(false);
  const handleNavigationUpdate = () => {
    setUpdateNavigation(!updateNavigation);

    handleChange();
  };

  useEffect(() => {
    const page = state.pages[state.questionIndex];
    if (!page || page.isIntermission
      || errorMessage
      || !questionValueChangedAt
      || modalWelcomeBackShow
      || modalHurryWasShown
      || noModalHurry
    ) {
      return undefined;
    }

    const delay = page.modalHurryDelay || state.modalHurryDelay || GLOBAL_CONFIG.modalHurryDelay;

    const intervalId = setInterval(() => {
      const diff = new Date().valueOf() - questionValueChangedAt;
      if (diff >= delay) {
        setModalHurryShow(true);
      }
    }, delay);

    return () => {
      setQuestionValueChangedAt(0);
      clearInterval(intervalId);
    };
  }, [
    state.pages,
    state.questionIndex,
    state.modalHurryDelay,
    modalWelcomeBackShow,
    modalHurryWasShown,
    questionValueChangedAt,
    errorMessage,
    noModalHurry,
  ]);

  // modal hurry
  useEffect(() => {
    const page = state.pages[state.questionIndex];
    if ( // show only when: assessment has started, page is a question
      state.started === true // assessment has started
      && page && !page.isIntermission // page is not an intermission
      && !modalHurryWasShown // modal reminder hasn't been shown already
      && !modalWelcomeBackShow // welcome back modal is not shown
      && !errorMessage // no error to display
      && !questionValueChangedAt
      && !noModalHurry
    ) {
      const delay = page.modalHurryDelay || state.modalHurryDelay || GLOBAL_CONFIG.modalHurryDelay;
      const timerId = setTimeout(() => {
        setModalHurryShow(true);
      }, delay);
      return () => {
        clearTimeout(timerId);
      };
    }
    return undefined;
  }, [
    questionValueChangedAt,
    state.started,
    state.questionIndex,
    state.pages,
    state.modalHurryDelay,
    modalHurryWasShown,
    modalWelcomeBackShow,
    errorMessage,
    noModalHurry,
  ]);

  // modal cancel
  const [ modalCancelShow, setModalCancelShow ] = useState(false);

  // modal help
  const [ modalHelpShow, setModalHelpShow ] = useState();

  // modal inactivity
  const [ modalInactivityShow, setModalInactivityShow ] = useState(false);
  const [ modalInactivityWarningShow, setModalInactivityWarningShow ] = useState(false);
  const [ modalInactivityHash, setModalInactivityHash ] = useState('');

  const [ copyrightModalVisible, setCopyrightModalVisible ] = useState();

  // modal inactivity:
  // the modal is activated only when the first question is answered
  // (so users have enough time for registration, guidance & intro pages)
  // the timeout restarts with every question answered
  // that means users can't linger indefinitely on intermissions
  // once the assessment has started
  useEffect(() => {
    if (!state.inactivityTimeout) {
      return;
    }
    let hash = '';
    Object.keys(state.answers).forEach((key) => {
      const value = state.answers[key];
      if (value !== undefined) {
        hash += `${key}${value}`;
      }
    });
    if (hash.length && hash !== modalInactivityHash) {
      setModalInactivityHash(hash);
      // console.log('answers changed', hash);
    }
  }, [ state.inactivityTimeout, state.answers, modalInactivityHash ]);
  // modal inactivity: set timeouts for inactivity modals
  useEffect(() => {
    let timerIdWarning;
    let timerIdTimeout;

    if (modalInactivityHash.length) {
      // console.log('modalInactivity set timeouts');
      timerIdWarning = setTimeout(() => {
        setModalInactivityWarningShow(true);
      }, state.inactivityTimeout * CONFIG.inactivityTimeoutWarningFactor);
      timerIdTimeout = setTimeout(() => {
        setModalInactivityShow(true);
      }, state.inactivityTimeout);
    }

    return () => {
      clearTimeout(timerIdWarning);
      clearTimeout(timerIdTimeout);
    };
  }, [ state.inactivityTimeout, modalInactivityHash ]);

  // toast message
  const [ toastMessageWasShown, setToastMessageWasShown ] = useState(false);
  const [ toastMessageShow, setToastMessageShow ] = useState(false);
  useEffect(() => {
    if (!toastMessageWasShown
      && state.allowBackNavigationOnlyOncePerQuestion && state.hasNavigatedBack
    ) {
      setToastMessageShow(true);
      setToastMessageWasShown(true);
    }
  }, [
    toastMessageWasShown,
    state.allowBackNavigationOnlyOncePerQuestion, state.hasNavigatedBack,
  ]);

  // before unload message
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [ handleBeforeUnload ]);

  // has no effect, left in here for better scroll management
  const [ animating, setAnimating ] = useState(false);

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    };
  }, []);

  // page & animation handling
  const [ hideBackButton, setHideBackButton ] = useState(true);
  const [ hideForwardButton, setHideForwardButton ] = useState(true);
  const [ pageAnimationsDirection, setPageAnimationsDirection ] = useState('forwards'); // 'forwards' || 'backwards'
  const [ page1, setPage1 ] = useState();
  const [ page1Animation, setPage1Animation ] = useState('begin');
  const [ page1PreAnimation, setPage1PreAnimation ] = useState(false);
  const [ page2, setPage2 ] = useState();
  const [ page2Animation, setPage2Animation ] = useState('begin');
  const [ page2PreAnimation, setPage2PreAnimation ] = useState(false);


  // TRANSLATIONS
  const translationIds = getTranslationIds(type);


  // PROGRESS
  const [ progress, setProgress ] = useState(0);
  const [ hideProgress, setHideProgress ] = useState(false);

  // CLICKBLOCK
  useEffect(() => {
    // set clickBlock and don't reset when any modal is active
    if (
      modalHurryShow || modalCancelShow
      || modalInactivityShow || modalInactivityWarningShow
      || modalHelpShow || modalWelcomeBackShow
      || copyrightModalVisible || waitApi
    ) {
      if (localBlock.current) {
        return undefined;
      }
      setLocalBlock();
      dispatchLocal({ type: 'setClickBlock' });
      return undefined;
    }

    if (!state.clickBlock) return undefined;

    const timerId = setTimeout(() => {
      dispatchLocal({ type: 'unsetClickBlock' });
    }, GLOBAL_CONFIG.clickBlockDuration);

    return () => {
      clearTimeout(timerId);
    };
  }, [ state.clickBlock, setLocalBlock, modalHurryShow, modalCancelShow, modalInactivityShow,
    modalInactivityWarningShow, modalHelpShow, modalWelcomeBackShow, copyrightModalVisible, waitApi ]);


  // ANIMATIONS
  const [ animationTimes ] = useState({ // useState() so new calc between renders
    // forwards
    pageForwardsInTotalDuration: msToNumber(styles.pageForwardsInDuration) + msToNumber(styles.pageForwardsInDelay),
    pageForwardsInDuration: msToNumber(styles.pageForwardsInDuration),
    pageForwardsInDelay: msToNumber(styles.pageForwardsInDelay),
    pageForwardsOutDuration: msToNumber(styles.pageForwardsOutDuration),
    // backwards
    pageBackwardsInTotalDuration: msToNumber(styles.pageBackwardsInDuration) + msToNumber(styles.pageBackwardsInDelay),
    pageBackwardsInDuration: msToNumber(styles.pageBackwardsInDuration),
    pageBackwardsInDelay: msToNumber(styles.pageBackwardsInDelay),
    pageBackwardsOutDuration: msToNumber(styles.pageBackwardsOutDuration),
  });

  useEffect(() => {
    if (waitApi) {
      return undefined;
    }

    const page1Out = state.animationCount % 2;

    const currentPage = state.pages[state.questionIndex] || {};

    if (currentPage.conditional) {
      const requiredQuestion = requiredQuestions?.find((qId) => qId === currentPage.id);
      currentPage.invisible = !requiredQuestion;
    }

    // for conditional questions, where some of the questions are hidden depending on the prev answers
    if (currentPage.invisible) {
      dispatchLocal({
        type: 'skipCurrentPage',
        payload: {
          forward: state.lastQuestionIndex < state.questionIndex,
        },
      });
      return undefined;
    }

    // determine animation type & content
    setAnimating(true);
    if (page1Out) {
      setPage1Animation('out');
      setPage2Animation('in');
      setPage2(currentPage);
    } else {
      setPage1Animation('in');
      setPage1(currentPage);
      setPage2Animation('out');
    }

    // determine animation direction & animationOutDuration for reset
    let animationsDirection;
    let animationOutDuration;
    let progressDelay;
    if (state.questionIndex >= state.lastQuestionIndex) { // >= so it's forwards on start
      animationsDirection = 'forwards';
      animationOutDuration = animationTimes.pageForwardsOutDuration;
      progressDelay = animationTimes.pageForwardsInTotalDuration;
    } else {
      animationsDirection = 'backwards';
      animationOutDuration = animationTimes.pageBackwardsOutDuration;
      progressDelay = animationTimes.pageBackwardsInTotalDuration;
    }
    setPageAnimationsDirection(animationsDirection);

    /* Check: show buttons? */

    // 1) Backward button logic
    const showBackButton = () => {
      // Hide while loading or if there's an error
      if (!state.id || Boolean(errorMessage)) {
        return false;
      }

      // Force show if the page requires it
      if (currentPage.showBackArrow) {
        return true;
      }

      if (currentPage.hideNavigation) {
        return false;
      }

      // Hide if back navigation is forbidden
      if (!state.allowBackNavigation) {
        return false;
      }

      // Hide if some questions were skipped (RMP)
      if (state.skippedQuestionsMode && state.questionIndex <= state.skippedQuestionsModeStartIndex) {
        return false;
      }

      // Hide for:
      // - intermissions that are not explanatory pages
      // - first question after intro if prev page is not explanatory
      // - first explanatory page
      const firstQuestionIndex = state.pages.findIndex(({ isIntermission }) => !isIntermission);
      const firstExplanatoryIndex = state.pages.findIndex((page) => Boolean(page.explanatory));
      const prevQuestionIsExplanatory = state.pages[firstQuestionIndex - 1]?.explanatory;
      if ((currentPage.isIntermission && !currentPage.explanatory)
        || (state.questionIndex === firstQuestionIndex && !prevQuestionIsExplanatory)
        || (state.questionIndex === firstExplanatoryIndex)
      ) {
        return false;
      }

      // Hide when back navigation is available and the user has already reached the limit
      if (state.allowBackNavigationOnlyOncePerQuestion) {
        const backNavigationLimit = state.highestQuestionIndex - Number(state.allowBackNavigation);
        if (state.questionIndex <= backNavigationLimit) {
          return false;
        }
      }

      // Otherwise, show back button
      return true;
    };
    setTimeout(() => setHideBackButton(!showBackButton()), 300);

    // 2) Forward button logic
    const showForwardButton = () => {
      // Hide while loading or if there's an error
      if (!state.id || Boolean(errorMessage)) {
        return false;
      }

      if (currentPage.hideNavigation) {
        return false;
      }

      // Case when the user is not on the latest page
      if (state.allowForwardNavigation) {
        return !currentPage.isIntermission && !state.skippedQuestionsMode;
      }

      return state.answers[currentPage.id] !== undefined && state.allowBackNavigation;
    };
    setHideForwardButton(!showForwardButton());

    // reset content & animating after animation is done so going back
    // rerenders component and doesn't show end state of last animation
    const resetTimerId = setTimeout(() => {
      if (page1Out) {
        setPage1();
      } else {
        setPage2();
      }
    }, animationOutDuration);
    setTimeout(() => {
      setAnimating(false);
    }, 500);


    // progress
    // apply progress update after pageIn animation is done or later when hiding
    const showProgress = !currentPage.isIntermission
      || currentPage.showProgressBar
      || (currentPage.isIntermission && currentPage.countAsProgress);
    progressDelay = showProgress
      ? progressDelay * CONFIG.progressDelayFactor
      : progressDelay * 2;
    const progressTimerId = setTimeout(() => {
      setProgress(state.progress);
    }, progressDelay);
    // hide / show progress with a separate delay based on direction
    const progressHideDelay = !showProgress
      ? 0
      : progressDelay;
    const progressHideTimerId = setTimeout(() => {
      setHideProgress(!showProgress);
    }, progressHideDelay);

    // clearTimeout on
    return () => {
      clearTimeout(resetTimerId);
      clearTimeout(progressTimerId);
      clearTimeout(progressHideTimerId);
    };
  }, [
    state.id,
    state.questionIndex,
    state.lastQuestionIndex,
    state.highestQuestionIndex,
    state.animationCount,
    state.progress,
    state.pages,
    state.allowBackNavigation,
    state.allowBackNavigationOnlyOncePerQuestion,
    state.hasNavigatedBack,
    state.allowForwardNavigation,
    state.skippedQuestionsMode,
    state.skippedQuestionsModeStartIndex,
    state.answers,
    waitApi,
    errorMessage,
    animationTimes,
    requiredQuestions,
    updateNavigation,
  ]);

  const handlePreAnimation = () => {
    // determining page before state change happens
    const page1Out = (state.animationCount + 1) % 2;

    if (page1Out) {
      setPage1PreAnimation(true);
    } else {
      setPage2PreAnimation(true);
    }

    const timeoutDelay = Number(styles.animationDurationMs)
    + animationTimes.pageForwardsOutDuration
    + 200; // buffer for ios
    setTimeout(() => {
      if (page1Out) {
        setPage1PreAnimation(false);
      } else {
        setPage2PreAnimation(false);
      }
    }, timeoutDelay); // from QuestionBubbles
  };


  // STORE HOOKS


  // METHODS
  const close = (callback = () => {}) => {
    setHide(true);

    const duration = Number(styles.animationDurationLongMs) * 2;
    setTimeout(() => {
      callback();
    }, duration);
  };
  const cleanup = () => {
    storageController.removeState();
  };

  const addPages = ({ pages, insertAtIndex, replace }) => {
    const [ questionsValid, error ] = validateQuestions(pages);
    if (questionsValid) {
      dispatchLocal({
        type: 'addPages',
        payload: {
          pages, insertAtIndex, replace,
        },
      });
    } else {
      if (onError) {
        onError(error);
      }
      setErrorMessage(
        <>
          addPages: Empty Array or containing faulty question(s):
          <br />
          { JSON.stringify(error, null, 2) }
        </>,
      );
    }
  };


  // EVENT HANDLES
  const handleCancel = () => {
    setModalCancelShow(false);
    close(onCancel);
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
  const handleFinish = (afterClose = () => {}) => {
    cleanup();
    close(() => {
      onFinish();
      afterClose();
    });
  };
  const handleHeaderAction = () => {
    if (finishable) {
      handleFinish();
    } else {
      setModalCancelShow(true);
    }
  };
  const handleAnswer = useCallback((answerCb, questionAnimationDelay = 0) => {
    const question = state.pages[state.questionIndex];
    const { answers } = state;

    const {
      isIntermission = false,
      id: questionId,
      stageNumber,
    } = question;

    if (state.clickBlock && !isIntermission) {
      // NineLevelsStage might execute faster as clickblock
      // therefore we're ignoring clickblock here so NineLevelsStage can
      // call addPages local reducer action.
      return;
    }

    // get previous answer if it's available
    let answer = answerCb;
    if (answer === undefined) {
      answer = state.answers[questionId];
    }

    const { questionIndex } = state;
    const time = Date.now();
    const animationTime = pageAnimationsDirection === 'forwards'
    || pageAnimationsDirection === 'begin'
      ? animationTimes.pageForwardsInTotalDuration + questionAnimationDelay
      : animationTimes.pageBackwardsInTotalDuration;

    if (configOverride?.containsConditionalQuestions) {
      setTimeout(() => {
        dispatchLocal({
          type: 'next',
          payload: {
            answer,
            time,
            animationTime,
          },
        });
      });
    } else {
      dispatchLocal({
        type: 'next',
        payload: {
          answer,
          time,
          animationTime,
        },
      });
    }

    if (!isIntermission) {
      question.answerIsChanged = answers[questionId] && answers[questionId] !== answerCb;

      onAnswer({
        questionId,
        answer,
        questionIndex,
        stageNumber,
        time: {
          totalTime: time - state.lastTime,
          animationTime,
        },
      });
    }
  }, [
    state,
    onAnswer,
    pageAnimationsDirection,
    animationTimes,
    configOverride?.containsConditionalQuestions,
  ]);

  const goBack = useCallback(() => {
    if (localBlock.current) {
      return;
    }

    const currentPage = state.pages[state.questionIndex - 1];
    if (currentPage) {
      currentPage.hideNavigation = false;
    }

    setLocalBlock();
    dispatchLocal({ type: 'prev', payload: Date.now() });
  }, [ state, setLocalBlock ]);
  const goForward = useCallback(() => {
    if (localBlock.current) {
      return;
    }
    setLocalBlock();
    const currentPage = state.pages[state.questionIndex];
    const currentAnswer = state.answers[currentPage.id];
    handleAnswer(currentAnswer);
  }, [ state, handleAnswer, setLocalBlock ]);


  // KEYBOARD CONTROLS
  const handleKey = useCallback((event) => {
    const { key } = event;

    const thisPage = state?.pages?.[state?.questionIndex];

    if (state.clickBlock || errorMessage || localBlock.current) {
      return;
    }

    // note: focusing Next Button and hitting enter will call onClick on button
    // and handleKeyUp at the same time, causing setNewAnswer to be reset
    // that's why we clickBlock here
    switch (key) {
      case 'Escape': {
        setModalCancelShow(true);
        break;
      }
      case 'ArrowUp':
      case 'd':
      case 'k': {
        if (hideBackButton || thisPage?.type === 'free-text') {
          break;
        }
        goBack();
        break;
      }
      case 'ArrowDown':
      case 'u':
      case 'j':
      case 'Enter': {
        if (thisPage?.type === 'free-text' || (thisPage?.type === 'bipolar-scale' && key === 'Enter')) {
          break;
        }

        const currentPage = state.pages[state.questionIndex];
        if (currentPage && !currentPage.preventKeyboardNext) {
          goForward();
        }
        break;
      }
      default:
    }
  }, [
    goForward,
    goBack,
    state,
    hideBackButton,
    errorMessage,
  ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKey);
    return () => {
      window.removeEventListener('keyup', handleKey);
    };
  }, [ handleKey ]);

  // HELPERS

  // RENDERS
  const renderPage = (page) => {
    if (!page) return undefined;

    // explanatory
    const isExplanatory = page && page.explanatory;

    // intermission
    const isIntermission = page && page.isIntermission && page.render;

    // question
    const isQuestion = page && page.id && !page.isIntermission;
    let QuestionComponent;
    let questionRange;
    if (isQuestion || isExplanatory) {
      // can safely getQuestionRenderInfo here because questions were validated on init
      [ QuestionComponent, questionRange ] = getQuestionRenderInfo(page);
    }

    // console.log('page', page);

    const selectedAnswer = page && page.id && state.answers[page.id] !== undefined
      ? state.answers[page.id]
      : undefined;

    // console.log('answer', selectedAnswer);

    return (
      <>
        { /* ERROR PAGE */ }
        { errorMessage && (
          <>
            <h4>{ translate('technical_error_title') }</h4>
            <br />
            <br />
            { errorMessage }
            { !isCoachHub && typeof errorMessage === 'string' && (
              <>
                <br />
                <br />
                <br />
                <Button
                  looks='secondary'
                  size='M'
                  onClick={handleCancel}
                >
                  { translate('assessment_abort_confirm') }
                </Button>
              </>
            ) }
          </>
        ) }

        { /* INTERMISSION */ }
        { !errorMessage && isIntermission && (
          page.render(handleAnswer, goBack, state, {
            finishable,
            handleFinish,
            allowFinish,
            errorEnd,
            addPages,
            handleEnd: state.manualEnd && handleEnd,
            loadingDuring,
            setLoadingDuring,
            managerControlled: state.managerControlled,
          })
        ) }

        { /* QUESTION */ }
        { !errorMessage && (isQuestion || isExplanatory) && (
          <QuestionComponent
            question={page}
            referenceQuestion={state.pages.find((p) => p.id === page.ai_assistance_reference_question)}
            singleTarget={page?.representation === 'select-most'}
            dispatchLocal={dispatchLocal}
            range={questionRange}
            selectedValue={selectedAnswer}
            allowAnswerSkip={configOverride.allowAnswerSkip}
            clickBlock={state.clickBlock}
            freeTextAiAssistanceEnabled={freeTextAiAssistanceEnabled}
            onAnswer={handleAnswer}
            onChange={handleChange}
            isAnswered={Boolean(openQuestions && !openQuestions.includes(page.id))}
            onNavigationUpdate={handleNavigationUpdate}
            onAnimation={handlePreAnimation}
            onBack={goBack}
            onForward={goForward}
            onHelp={() => setModalHelpShow(true)}
            localBlock={localBlock}
            setLocalBlock={setLocalBlock}
          />
        ) }
      </>
    );
  };

  // RENDER: AssessmentNext
  return (
    <div className={classNames(styles.assessmentNext, {
      [styles.hide]: hide,
    })}
    >

      { /* OVERLAY */ }
      <div className={classNames(styles.overlay, {
        [styles.hide]: hide,
      })}
      >

        { /* HEADER */ }
        <div className={classNames(styles.header, {
          [styles.forExternalAssessment]: Boolean(externalAssessmentToken),
          [styles.hide]: hide,
        })}
        >
          { externalAssessmentToken
            ? <StandaloneHeader />
            : (
              <AssessmentHeader
                title={translate(translationIds.headerTitle) || state.title}
                actionLabel={finishable
                  ? translate('assessment_complete')
                  : translate('assessment_abort')}
                onAction={handleHeaderAction}
              />
            ) }
        </div>

        { /* PROGRESSBAR */ }
        <div className={classNames(styles.progressBarContainer, {
          // hide on hide & on intermissions that don't countAsProgress
          [styles.hide]: hide,
          [styles.hideInBetween]: hideProgress && !loading,
        })}
        >
          <div className={styles.progressBar}>
            <ProgressBar
              progress={progress}
              loading={loading || loadingDuring || waitApi}
            />
          </div>
        </div>

        { !waitApi && (
          // ASSESSMENT CONTENT
          <div
            data-test='AssessmentContent'
            className={classNames(styles.assessmentContent, {
              [styles.hide]: hide,
              [styles.canScroll]: !loadingPage,
              [styles.animating]: animating,
            })}
          >
            { /* PAGE 1 */ }
            <div className={classNames(
              styles.page,
              styles[pageAnimationsDirection],
              styles[page1Animation],
            )}
            >

              { /* BACK ARROW CONTAINER */ }
              <div
                className={classNames(styles.backArrowContainer, {
                  [styles.hideButton]: hideBackButton,
                  [styles.hideImmediately]: Boolean(errorMessage) || (hideForwardButton && page1?.isIntermission),
                  [styles.preAnimation]: !hideBackButton && page1PreAnimation,
                })}
                data-test='AssessmentNextBackArrow'
              >
                <div
                  className={styles.arrow}
                  onClick={goBack}
                  role='presentation'
                >
                  <ArrowUp />
                </div>
              </div>

              { /* PAGE CONTENT */ }
              <div className={styles.pageContent}>

                { /* LOADING PAGE */ }
                <BluCSSTransition
                  nodeRef={transitionRef}
                  in={!!loadingPage}
                  classNames={{ ...styles }}
                >
                  <div ref={transitionRef}>
                    { loadingPage }
                  </div>
                </BluCSSTransition>

                { /* RENDER PAGE1 */ }
                { !loadingPage && (
                  <>
                    { renderPage(page1) }

                    { /* FORWARD ARROW CONTAINER */ }
                    <div className={classNames(styles.forwardArrowContainer, {
                      [styles.hideButton]: hideForwardButton,
                      [styles.hideImmediately]: Boolean(errorMessage) || (hideForwardButton && page2?.isIntermission),
                      [styles.preAnimation]: !hideForwardButton && page1PreAnimation,
                    })}
                    >
                      <div
                        className={styles.arrow}
                        onClick={goForward}
                        role='presentation'
                      >
                        <ArrowDown />
                      </div>
                    </div>

                    { /* COPYRIGHT */ }
                    { (!loading && page1 && (page1.showCopyright || (showCopyright && !page1.isIntermission))) && (
                      <div className={styles.copyright}>
                        <span
                          onClick={() => setCopyrightModalVisible(true)}
                          role='presentation'
                        >
                          { translate(`${type}_ass_copyrightlink`, [ '{{year}}', new Date().getFullYear() ]) }
                        </span>
                      </div>
                    ) }
                  </>
                ) }


              </div>
            </div>

            { /* PAGE 2 */ }
            <div className={classNames(
              styles.page,
              styles[pageAnimationsDirection],
              styles[page2Animation],
            )}
            >

              { /* BACK ARROW CONTAINER */ }
              <div
                className={classNames(styles.backArrowContainer, {
                  [styles.hideButton]: hideBackButton,
                  [styles.hideImmediately]: hideBackButton && page2 && page2.isIntermission,
                  [styles.preAnimation]: !hideBackButton && page2PreAnimation,
                })}
                data-test='AssessmentNextBackArrow'
              >
                <div
                  className={styles.arrow}
                  onClick={goBack}
                  role='presentation'
                >
                  <ArrowUp />
                </div>
              </div>

              { /* PAGE CONTENT */ }
              <div className={styles.pageContent}>

                { /* RENDER PAGE2 */ }
                { renderPage(page2) }

                { /* FORWARD ARROW CONTAINER */ }
                <div className={classNames(styles.forwardArrowContainer, {
                  [styles.hideButton]: hideForwardButton,
                  [styles.hideImmediately]: (hideForwardButton && page2 && page2.isIntermission),
                  [styles.preAnimation]: !hideForwardButton && page2PreAnimation,
                })}
                >
                  <div
                    className={styles.arrow}
                    onClick={goForward}
                    role='presentation'
                  >
                    <ArrowDown />
                  </div>
                </div>

                { /* COPYRIGHT */ }
                { (!loading && page2 && (page2.showCopyright || (showCopyright && !page2.isIntermission))) && (
                  <div className={styles.copyright}>
                    <span
                      onClick={() => setCopyrightModalVisible(true)}
                      role='presentation'
                    >
                      { translate(`${type}_ass_copyrightlink`, [ '{{year}}', new Date().getFullYear() ]) }
                    </span>
                  </div>
                ) }

              </div>
            </div>
          </div>
        ) }

      </div>


      { /* MODALS */ }

      { /* HURRY MODAL */ }
      { (modalHurryShow && !modalInactivityWarningShow && !modalInactivityShow) && (
        <Modal
          header={translate('big5_ass_hintmodal_inactivity_title')}
          secondaryButtonTitle={translate('okay_lbl')}
          onConfirm={() => {
            setModalHurryShow(false);
            setModalHurryWasShown(true);
          }}
          onClose={() => {
            setModalHurryShow(false);
            setModalHurryWasShown(true);
          }}
        >
          { translate('big5_ass_hintmodal_inactivity_description') }
        </Modal>
      ) }

      { /* CANCEL MODAL */ }
      { modalCancelShow && (
        <Modal
          header={translate('assessment_abort_title')}
          redButtonTitle={translate(state.abortModalConfirmKey || 'assessment_abort_confirm')}
          secondaryButtonTitle={translate(state.abortModalCancelKey || 'assessment_abort_cancel')}
          onConfirm={handleCancel}
          onClose={() => setModalCancelShow(false)}
        >
          { state.canContinueLater === undefined && (
            translate(translationIds.abortDescription)
          ) }

          { state.canContinueLater === true && (
            translate(state.abortModalDescriptionKey || 'assessment_abort_description_progress_saved')
          ) }

          { state.canContinueLater === false && (
            translate('assessment_abort_description_progress_lost')
          ) }
        </Modal>
      ) }

      { /* HELP MODAL */ }
      { (modalHelpShow) && (
        <Modal
          header={translate('assessment_help_info_title')}
          secondaryButtonTitle={translate('close_lbl')}
          onClose={() => setModalHelpShow(false)}
        >
          { translate(state.modalHelpContentTranslationKey) || markdown(state.modalHelpContent) }
        </Modal>
      ) }

      { /* WELCOME BACK MODAL */ }
      { (modalWelcomeBackShow) && (
        <Modal
          header={translate('assessment_welcome_back_title')}
          secondaryButtonTitle={translate('continue_lbl')}
          onClose={() => {
            setModalWelcomeBackShow(false);
            setModalWelcomeBackShown(true);
          }}
        >
          { translate(state.welcomeBackKey || 'assessment_welcome_back_content') }
        </Modal>
      ) }

      { /* MODAL INACTIVITY */ }
      { (modalInactivityShow) && (
        <Modal
          header={translate('assessment_inactivity_title')}
          secondaryButtonTitle={translate('assessment_inactivity_restart_button')}
          onClose={() => {
            setModalInactivityShow(false);
            setModalInactivityWarningShow(false);
            setModalHurryShow(false);
            setModalHurryWasShown(true);
            handleCancel();
          }}
        >
          { translate('assessment_inactivity_content') }
        </Modal>
      ) }

      { /* MODAL INACTIVITY WARNING */ }
      { (modalInactivityWarningShow && !modalInactivityShow) && (
        <Modal
          header={translate('assessment_inactivity_warning_title')}
          secondaryButtonTitle={translate('continue_lbl')}
          onClose={() => {
            setModalInactivityWarningShow(false);
          }}
        >
          { translate('assessment_inactivity_warning_content') }
        </Modal>
      ) }

      { /* COPYRIGHT MODAL */ }
      { copyrightModalVisible && (
        <Modal
          header={translate(`${type}_ass_info_title_copyright`)}
          secondaryButtonTitle='OK'
          onClose={() => setCopyrightModalVisible(false)}
        >
          { translate(`${type}_ass_info_description_copyright`) }
        </Modal>
      ) }

      { toastMessageShow && (
        <Toast
          headline={translate('assessment_toast_message_title')}
          message={translate('assessment_toast_message_descr')}
          onClose={() => setToastMessageShow(false)}
        />
      ) }

    </div>
  );
};

export default AssessmentNext;
