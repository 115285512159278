// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import styles from './Ist.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentHistory, IstResult, RecommendationCarousel } from 'ui/molecules';
import {
  InfoCard,
  SkeletonFlexible,
} from 'ui/basic';
import { BookModal } from 'ui/molecules/IstResult/BookModal';
import { ExtraCard } from './ExtraCard';
import { RecurringAssessmentControl } from '../BalancedYou/components/RecurringAssessmentControl';

// UTILS
import { useTranslate } from 'utils/translator';
import { useHeightAnimationStyle } from 'utils/hooks';
import {
  ASSESSMENT_RECURRENCE_TYPES as RECURRENCE_TYPES,
  ASSESSMENT_TYPES,
} from 'utils/configuration/const/assessment-types';

// STORE NEXT
import { useSelector, useDispatch } from 'react-redux';
import { selectAssessments } from 'store/selectors/assessment';
import { getCurrentUser } from 'store/selectors/currentUser';
import { getAssessmentHistory, getAssessmentResult, getExtraCards } from 'store/actions';


// CONFIG & DATA
const Config = {
  assessmentHistoryLimit: 20,
};

// COMPONENT: Ist
const Ist = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  const assessmentsFromStore = useSelector(selectAssessments);
  const me = useSelector(getCurrentUser);

  const [ selectedCard, setSelectedCard ] = useState();
  const [ showBookCoaching, setShowBookCoaching ] = useState(false);

  const istAssessment = assessmentsFromStore.find(({ id }) => id === ASSESSMENT_TYPES.IST);
  const hasResults = Boolean(istAssessment?.results?.length);

  const [ controlWrapperRef, controlWrapperStyle ] = useHeightAnimationStyle();

  const recurring = [ RECURRENCE_TYPES.RUN, RECURRENCE_TYPES.FREQUENCY, RECURRENCE_TYPES.MANUAL ]
  .includes(istAssessment?.recurrenceType);

  const hasPrevResults = Object.values(istAssessment?.history?.results ?? []).some(Boolean);
  const hideRecurring = istAssessment?.recurrenceType === RECURRENCE_TYPES.RUN
    && !hasPrevResults
    && istAssessment?.locked;

  const [ historyIndex, setHistoryIndex ] = useState(0);
  const assessmentHistoryTotal = istAssessment?.history?.assessmentStatistics?.totalResults;
  const assessmentHistoryResults = useMemo(
    () => [ ...istAssessment?.history?.results || [] ].reverse(),
    [ istAssessment?.history ],
  );

  const [ monthlyMode, setMonthlyMode ] = useState();
  useEffect(() => {
    if (monthlyMode !== undefined || !assessmentHistoryResults.length) {
      return;
    }

    const diffs = [];
    for (let i = 0; i < 5; i += 1) {
      const first = assessmentHistoryResults[i];
      const second = assessmentHistoryResults[i + 1];
      if (first && second) {
        diffs.push(second.date - first.date);
      }
    }

    const average = diffs.reduce((acc, current) => acc + current, 0) / diffs.length;
    const averageInDays = average / 60 / 60 / 24; // convert timespan to days

    setMonthlyMode(averageInDays <= 90); // 90 days = 3 months
  }, [ assessmentHistoryResults, monthlyMode ]);

  // Fetch assessment history
  const [ historyOffset, setHistoryOffset ] = useState(0);
  const historyOffsets = useRef([]);
  useEffect(() => {
    if (recurring) {
      historyOffsets.current.push(historyOffset);
      dispatch(getAssessmentHistory(ASSESSMENT_TYPES.IST, { offset: historyOffset }));
    }
  }, [
    dispatch,
    recurring,
    historyOffset,
  ]);

  const onPageUpdate = useCallback(({
    newPagePosition, totalPagesCount, cellsCountPerPage, pageToLeft,
  }) => {
    if (pageToLeft && totalPagesCount) {
      const nextPagePosition = totalPagesCount - newPagePosition;
      const nextPageDataCount = nextPagePosition * cellsCountPerPage;
      const offset = historyOffset + Config.assessmentHistoryLimit;

      if (nextPageDataCount > assessmentHistoryResults?.length && offset < assessmentHistoryTotal) {
        setHistoryOffset(offset);
      }
    }
  }, [
    historyOffset,
    assessmentHistoryResults?.length,
    assessmentHistoryTotal,
  ]);

  useEffect(() => {
    if (!hasResults) {
      dispatch(getAssessmentResult(ASSESSMENT_TYPES.IST));
    }
  }, [ dispatch, hasResults ]);

  const cards = istAssessment?.extraCards;
  useEffect(() => {
    if (!cards || cards.length === 0) {
      dispatch(getExtraCards(ASSESSMENT_TYPES.IST));
    }
  }, [ dispatch, cards ]);

  // RENDER: Ist
  if (!hasResults) {
    return (
      <div className={classNames(styles.ist)}>
        <SkeletonFlexible noHeader repeat={9} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.ist)}>
      <div className={styles.gridContainer}>
        <div className={styles.left}>
          <IstResult
            ist={{
              ...istAssessment,
              registrationData: recurring
                ? istAssessment.history?.results?.[historyIndex]?.registrationData
                : undefined,
              results: recurring
                ? istAssessment.history?.results?.[historyIndex]?.results
                : istAssessment.results,
              prevResults: istAssessment.history?.results?.[historyIndex + 1]?.results,
            }}
            user={me}
          />

          <div className={styles.recommendations}>
            <RecommendationCarousel assessmentId={ASSESSMENT_TYPES.IST} />
          </div>

          { assessmentHistoryResults.length > 1 && (
            <AssessmentHistory
              results={assessmentHistoryResults}
              range={[ istAssessment?.renderReferenceMin, istAssessment?.renderReferenceMax ]}
              historyIndex={Math.abs(historyIndex)}
              monthlyMode={monthlyMode}
              pageCountTotal={assessmentHistoryTotal}
              onPageUpdate={onPageUpdate}
            />
          ) }

        </div>

        <div className={styles.right}>
          { recurring && !hideRecurring && (
            <div
              ref={controlWrapperRef}
              style={controlWrapperStyle}
            >
              <RecurringAssessmentControl
                assessment={istAssessment}
                historyIndex={historyIndex}
                onHistoryChange={setHistoryIndex}
              />
            </div>
          ) }

          <InfoCard
            title={translate('ist_info_title')}
            content={translate('ist_assessment_description')}
          />

          { cards?.map((card) => (
            <ExtraCard
              key={card.type}
              data={card}
              dateOfBirthProvided={Boolean(istAssessment?.registrationData?.date_of_birth)}
              onBook={() => {
                setSelectedCard(card);
                setShowBookCoaching(true);
              }}
            />
          )) }
        </div>
      </div>

      { showBookCoaching && (
        <BookModal
          card={selectedCard}
          onClose={() => setShowBookCoaching(false)}
        />
      ) }
    </div>
  );
};

export default Ist;
