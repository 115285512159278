import { get } from 'api';
import * as action from 'store/actions';
import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';

import {
  MAPPED_PLATFORM_ROLES,
  PLATFORM_ROLES,
  VACANCY_ROLES,
} from 'utils/configuration/const/roles';
import { COMPANY_ID, IS_ADMIN_FRONTEND } from 'utils/configuration';
import { COMPANY_FEATURES } from 'utils/configuration/const/settings';
import { selectIsCoachHub } from 'store/selectors/configuration';

function* getCurrentUser() {
  try {
    const params = new URLSearchParams();
    params.append('expand', 'roles');
    params.append('expand', 'organizationalRoles');
    params.append('include', 'settings');

    const { status, ok, data } = yield call(get, 'core/user/info', params);

    const currentUserData = data;

    let canAccessWellBeing = true;
    const canAccessMindfulnessJourney = false;

    if (ok && status === 200) {
      let role = PLATFORM_ROLES.COMPANY_USER;

      if (currentUserData.roles?.find((r) => r.role === PLATFORM_ROLES.CANDIDATE && r.company)) {
        role = PLATFORM_ROLES.CANDIDATE;
      }

      if (currentUserData.roles?.find((r) => r.role === PLATFORM_ROLES.TEAM_LEADER && r.team)) {
        role = PLATFORM_ROLES.TEAM_LEADER;
      }

      if (currentUserData.roles?.find((r) => r.role === PLATFORM_ROLES.COMPANY_LEADER)) {
        role = PLATFORM_ROLES.COMPANY_LEADER;
      }

      if (currentUserData.roles?.find((r) => r.role === PLATFORM_ROLES.ASSESSMENT_MANAGER)) {
        role = PLATFORM_ROLES.ASSESSMENT_MANAGER;
      }

      if (currentUserData.roles?.find((r) => r.role === PLATFORM_ROLES.COMPANY_LEADER_CUSTOM)) {
        role = PLATFORM_ROLES.COMPANY_LEADER_CUSTOM;
      }

      if (currentUserData.roles?.find((r) => (r.role === PLATFORM_ROLES.COMPANY_ADMIN && r.company)
        || r.role === PLATFORM_ROLES.COMPANY_ADMIN_RESTRICTED)) {
        role = PLATFORM_ROLES.COMPANY_ADMIN;
      }

      // roleNext
      const isCoachHub = yield select(selectIsCoachHub);
      let roleNext;
      if (currentUserData.roles?.find((roleParam) => roleParam.role === 'JOB_SHARE_ROLE')) {
        roleNext = 'jobShare';
      }
      if (currentUserData.roles?.find((roleParam) => roleParam.role === 'PROFILE_SHARE_ROLE')) {
        roleNext = 'profileShare';
      }
      if (currentUserData.roles?.find((roleParam) => roleParam.role === PLATFORM_ROLES.CANDIDATE)) {
        roleNext = 'candidate';
      }
      if (currentUserData.roles?.find((roleParam) => roleParam.role === PLATFORM_ROLES.COMPANY_USER)) {
        roleNext = 'employee';
      }
      if (
        currentUserData.roles?.find((roleParam) => roleParam.role === PLATFORM_ROLES.COMPANY_LEADER
          || roleParam.role === PLATFORM_ROLES.COMPANY_LEADER_CUSTOM)
      ) {
        roleNext = 'leader';
      }
      if (
        currentUserData.roles?.find((roleParam) => roleParam.role === PLATFORM_ROLES.ASSESSMENT_MANAGER
          || roleParam.role === PLATFORM_ROLES.ASSESSMENT_MANAGER)
      ) {
        roleNext = 'assessmentManager';
      }
      if (currentUserData.roles?.find((roleParam) => roleParam.role === PLATFORM_ROLES.COMPANY_ADMIN
          || roleParam.role === PLATFORM_ROLES.COMPANY_ADMIN_RESTRICTED)) {
        roleNext = 'admin';
      }
      if (currentUserData.roles?.find((roleParam) => roleParam.role === 'GLOBAL_ADMIN')) {
        roleNext = 'bluquistAdmin';
      }
      if (isCoachHub) {
        roleNext = 'coachHub';
      }

      // subRoleNext
      const subRoleNext = [];
      let teamsForTeamLeader = [];
      if (currentUserData.roles?.find((roleParam) => roleParam.role === PLATFORM_ROLES.TEAM_MEMBER)) {
        subRoleNext.push('teamMember');
      }

      if (currentUserData.roles?.find((roleParam) => roleParam.role === PLATFORM_ROLES.TEAM_LEADER)) {
        subRoleNext.push('teamLeader');
        teamsForTeamLeader = currentUserData.roles
        .filter((roleParam) => roleParam.role === PLATFORM_ROLES.TEAM_LEADER)
        .map((roleParam) => roleParam.team);
      }

      if (currentUserData.roles?.find((roleParam) => roleParam.role === VACANCY_ROLES.VACANCY_MANAGER)) {
        subRoleNext.push('vacancyManager');
      }

      if (currentUserData.roles?.find((roleParam) => roleParam.role === VACANCY_ROLES.VACANCY_COLLABORATOR)) {
        subRoleNext.push('vacancyCollaborator');
      }

      if (currentUserData.roles?.find((roleParam) => roleParam.role === VACANCY_ROLES.VACANCY_SPECTATOR)) {
        subRoleNext.push('vacancySpectator');
      }

      let companyFeaturesResponse = {};
      if (!IS_ADMIN_FRONTEND) {
        companyFeaturesResponse = yield call(get, 'core/company/features');
      }

      let accessibleAssessments = [];
      if (role === PLATFORM_ROLES.ASSESSMENT_MANAGER) {
        const assessmentsAccessResponse = yield call(get, 'core/access/assessment', {
          user: currentUserData.id,
          company: COMPANY_ID,
        });

        if (assessmentsAccessResponse.ok) {
          accessibleAssessments = assessmentsAccessResponse.data?.users?.[0]?.assessments || [];
        }
      }

      // no well-being for admin module
      if (IS_ADMIN_FRONTEND) {
        canAccessWellBeing = false;
      }

      if (role === PLATFORM_ROLES.CANDIDATE) {
        canAccessWellBeing = false;
      }

      // generate permissions based on organization settings
      if (companyFeaturesResponse.ok && companyFeaturesResponse.status === 200) {
        const companyFeatures = companyFeaturesResponse.data.features;

        const wellBeingModule = companyFeatures.find((feature) => (
          feature.category === 'module' && feature.id === COMPANY_FEATURES.WELL_BEING
        ));

        if (!wellBeingModule || (wellBeingModule && !wellBeingModule.active)) {
          canAccessWellBeing = false;
        }
      }

      yield put(action.getCurrentUserFulfilled({
        ...currentUserData,
        role: MAPPED_PLATFORM_ROLES[role],
        roleNext,
        subRoleNext,
        accessibleAssessments,
        teamsForTeamLeader,
        canAccessWellBeing,
        canAccessMindfulnessJourney,
      }));

      yield put(action.getUserProfilePicture(data));
      if (!IS_ADMIN_FRONTEND) {
        yield put(action.listAssessments());
      }

      // In some rare cases language could not be set: update settings
      if (currentUserData.settings?.language === null) {
        yield put(action.updateUserSettings(
          { language: localStorage.getItem('selectedLanguage') },
          false,
        ));
      }
    } else if (data.error && data.error.errorCode === 1103) {
      // hide error message for error code 1103
      yield put(action.getCurrentUserRejected({
        error: {
          errorCode: 1103,
          errorMessage: '',
        },
      }));
    } else {
      yield put(action.getCurrentUserRejected(data));
    }
  } catch (error) {
    yield put(action.getCurrentUserRejected(error));
  } finally {
    yield put(action.loadingFulFilled());
  }
}

export default function* watchGetUserRequest() {
  yield takeEvery(action.GET_CURRENT_USER, getCurrentUser);
}
