// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './SinglePageAssessment.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import { useHistory, useParams } from 'react-router';
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  Button, Modal, OverlayModal, SkeletonFlexible,
} from 'ui/basic';
import { QuestionBubbles } from './components/QuestionBubbles';
import { QuestionFreeText } from './components/QuestionFreeText';
import { QuestionSegments } from './components/QuestionSegments';
import { QuestionExplanatory } from './components/QuestionExplanatory';

// UTILS
import * as api from 'api';
import { EXTERNAL_WEBSITE_FALLBACK } from 'utils/configuration';
import { useTranslate } from 'utils/translator/translator';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import * as fromSettingsSelectors from 'store/selectors/settings';
import { showToast } from 'store/actions';

// CONFIG & DATA
// const Config = {};
import { questionBubblesRanges } from 'ui/molecules/AssessmentNext/AssessmentNext.config';

const QuestionTypes = {
  scaleBoxes: 'scale-boxes',
  scaleBoxesHorizontal: 'scale-boxes-horizontal',
  steppedScale: 'scale-stepped-scale',
  freeText: 'free-text',
};

const QuestionComponents = {
  [QuestionTypes.scaleBoxes]: {
    component: QuestionBubbles,
    validRangeTypes: questionBubblesRanges,
  },
  [QuestionTypes.scaleBoxesHorizontal]: {
    component: QuestionBubbles,
    validRangeTypes: questionBubblesRanges,
  },
  [QuestionTypes.steppedScale]: {
    component: QuestionSegments,
    validRangeTypes: questionBubblesRanges,
  },
  [QuestionTypes.freeText]: {
    component: QuestionFreeText,
  },
};

// COMPONENT: SinglePageAssessment
const SinglePageAssessment = (props) => {
  // PROPS
  const {
    assessment,
    questions = [],
    peerFor = '',
  } = props;

  // SPECIAL HOOKS
  const history = useHistory();
  const params = useParams();
  const translate = useTranslate();
  const dispatch = useDispatch();

  const companySettings = useSelector(fromSettingsSelectors.getCompanySettings);

  const filteredQuestions = questions
  .filter((question) => !(question.isIntermission && !question.explanatory))
  .filter((question) => !(question.explanatory && (question.conditional !== false && question.answerRequired !== true)))
  .filter((question) => !(question.answerRequired === false));
  const [ visibleQuestions, setVisibleQuestions ] = useState(filteredQuestions);

  let description;
  let guidanceContent;
  if (assessment) {
    description = peerFor
      ? translate(
        assessment.customContent?.peer_360_asmnt_peer_intro_copy ?? 'peer_360_asmnt_peer_intro_copy',
        [ '{{username}}', peerFor ],
      )
      : translate(assessment.description);

    let guidanceKey = peerFor
      ? assessment.customContent?.peer_360_ass_guidance_copy ?? 'peer_360_ass_guidance_copy'
      : 'peer_360_ass_guidance_self_copy';
    // Custom guidance only for self assessment
    if (assessment.guidanceContent && !peerFor) {
      guidanceKey = assessment.guidanceContent;
    }
    guidanceContent = translate(guidanceKey, [ '{{username}}', peerFor ]);
  }

  const [ answers, setAnswers ] = useState({});
  useEffect(() => {
    if (assessment?.prevAnswers) {
      const answersInternal = {};
      assessment.prevAnswers.forEach((a) => {
        answersInternal[a.questionId] = {
          answer: a.answer,
        };
      });

      setAnswers(answersInternal);
    }
  }, [ assessment ]);

  const validateAnswers = () => !Object.values(answers).some(({ valueIsInvalid, error }) => (
    valueIsInvalid || Boolean(error)));

  const [ errorMessage, setErrorMessage ] = useState('');
  useEffect(() => {
    const errorEl = document.getElementById('general-error');
    const modalScrollableEl = document.getElementById('overlay-modal-scrollable-content');
    if (errorEl && modalScrollableEl) {
      modalScrollableEl.scrollTo({
        top: errorEl.offsetTop - 120, // 60px - header height, 60px - extra spacing
        behavior: 'smooth',
      });
    }
  }, [ errorMessage ]);

  const [ assessmentCompleted, setAssessmentCompleted ] = useState(false);
  const isExternalAssessment = window.location.pathname.includes('/share/');

  const [ submitProcessing, setSubmitProcessing ] = useState(false);
  const [ showSubmitModal, setShowSubmitModal ] = useState(false);
  const [ submitModalTitle, setSubmitModalTitle ] = useState('');
  const [ submitModalDescription, setSubmitModalDescription ] = useState('');

  const getSubmitButtonDisabled = useCallback(() => {
    const nonIntermissionQuestions = visibleQuestions.filter((q) => !q.isIntermission);
    if (assessment.allowAnswerSkip) {
      // at least 1 question should be answered
      return !nonIntermissionQuestions.some((question) => Boolean(answers[question.id]));
    }

    return !nonIntermissionQuestions.every((question) => Boolean(answers[question.id]));
  }, [ assessment, answers, visibleQuestions ]);

  const handleSubmit = () => {
    const notAllRequiredQuestionsAnswered = getSubmitButtonDisabled();
    const questionsContainInvalidAnswers = !validateAnswers();

    if (notAllRequiredQuestionsAnswered || questionsContainInvalidAnswers) {
      setSubmitModalTitle(questionsContainInvalidAnswers
        ? translate('obs_assessment_invalid_answers')
        : translate('obs_assessment_not_all_questions_answered'));
      setSubmitModalDescription(questionsContainInvalidAnswers
        ? translate('obs_assessment_submit_invalid_answers_copy')
        : translate('obs_assessment_not_all_questions_answered_copy'));
      setShowSubmitModal(true);
      return;
    }

    setSubmitProcessing(true);
    setErrorMessage('');
    api.post(`/core/assessments/${params.customAssessmentId}/complete`)
    .then(({ ok, data = {} }) => {
      if (ok) {
        if (isExternalAssessment) {
          setAssessmentCompleted(true);
        } else {
          history.goBack();
          dispatch(showToast(
            translate('obs_assessment_submitted_toast'),
            translate('obs_assessment_submitted_toast_copy'),
          ));
        }
      } else {
        const { errorCode, errorMessage: errorMessageInternal } = data.error;

        console.error(errorMessageInternal);
        setErrorMessage(errorCode === 1420
          ? translate('peer_assmnt_missing_required_answer_msg')
          : errorMessageInternal);
      }
    })
    .catch((error) => {
      setErrorMessage(error.message);
      console.error(error.message);
    })
    .finally(() => {
      setSubmitProcessing(false);
    });
  };

  const [ showInvalidAnswersForSaveModal, setShowInvalidAnswersForSaveModal ] = useState(false);
  const [ showAnswersSavedPage, setShowAnswersSavedPage ] = useState(false);
  const handleSaveAndExit = () => {
    const valid = validateAnswers();
    if (!valid) {
      setShowInvalidAnswersForSaveModal(true);
      return;
    }

    if (!isExternalAssessment) {
      if (Object.keys(answers).length > 0) {
        dispatch(showToast(
          translate('obs_assessment_answers_saved_toast'),
          translate('obs_assessment_answers_saved_toast_copy'),
        ));
      }
      history.goBack();
      return;
    }

    setShowAnswersSavedPage(true);
  };

  const handleAnswer = useCallback((answer, valueIsInvalid) => {
    const updateAnswersState = (error) => setAnswers((state) => {
      const newAnswers = JSON.parse(JSON.stringify(state));
      newAnswers[answer.question] = {
        answer: answer.content,
        valueIsInvalid,
        error,
      };
      return newAnswers;
    });

    updateAnswersState();

    if (!valueIsInvalid) {
      api.post(`/core/assessments/${params.customAssessmentId}/1/answers`, {
        answers: [ answer ],
      })
      .then(({ ok, data = {} }) => {
        if (ok) {
          const { requiredQuestions = [] } = data;
          setVisibleQuestions(questions.filter((question) => requiredQuestions.includes(question.id)));
        } else {
          updateAnswersState(data.error?.errorMessage);
        }
      })
      .catch((error = {}) => {
        updateAnswersState(error.message);
      });
    }
  }, [ params.customAssessmentId, questions ]);

  const handleButtonClick = () => {
    const link = companySettings.linkoutButtonUrl || EXTERNAL_WEBSITE_FALLBACK;
    window.open(link, '_self');
  };

  // RENDER: SinglePageAssessment
  return (
    <OverlayModal
      header={assessment?.title}
      onClose={() => history.goBack()}
    >
      <div className={styles.singlePageAssessment}>
        <div className={styles.gridContent}>
          { /* ASSESSMENT NOT COMPLETED YET */ }
          { !assessmentCompleted && (
            <>
              { (!assessment || !visibleQuestions.length) && (
                <SkeletonFlexible repeat={10} />
              ) }

              { showAnswersSavedPage && (
                <div className={styles.answersSaved}>
                  <div className='bluTypeS'>
                    { translate('assmnt_answers_saved_title') }
                  </div>
                  <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                    { translate('assmnt_answers_saved_copy') }
                  </div>
                  <Button
                    size='M'
                    looks='secondary'
                    onClick={() => window.close()}
                  >
                    { translate('close_lbl') }
                  </Button>
                </div>
              ) }

              { (assessment && visibleQuestions.length > 0 && !showAnswersSavedPage) && (
                <>
                  <span className='bluTypeM'>{ assessment.title }</span>
                  <div className={classNames('bluTypeCopy', 'marginTopS')}>
                    { description }
                  </div>
                  <div className={classNames('bluTypeCopy', 'marginTopS')}>
                    { guidanceContent }
                  </div>

                  { errorMessage && (
                    <div
                      id='general-error'
                      className={classNames('error', 'marginTopXs')}
                    >
                      { errorMessage }
                    </div>
                  ) }


                  <div className={styles.questions}>
                    { visibleQuestions.map((question) => {
                      if (question.explanatory) {
                        return (
                          <div
                            key={question.id}
                            className={classNames(styles.question, styles.explanatory)}
                          >
                            <QuestionExplanatory
                              key={question.id}
                              question={question}
                            />
                          </div>
                        );
                      }

                      const type = [ question.type, question.representation ].filter(Boolean).join('-');
                      const { component: Component, validRangeTypes } = QuestionComponents[type];

                      let range;
                      if ([
                        QuestionTypes.scaleBoxes, QuestionTypes.scaleBoxesHorizontal, QuestionTypes.steppedScale,
                      ].includes(type)) {
                        const answerFrom = question.answerFrom ?? 1;
                        const questionRangeType = `from${answerFrom}Step${question.answerStep}To${question.answerTo}`;
                        range = validRangeTypes[questionRangeType];
                      }

                      return (
                        <div key={question.id} className={styles.question}>
                          <Component
                            question={question}
                            range={range}
                            value={answers[question.id]?.answer}
                            error={answers[question.id]?.error}
                            onAnswer={handleAnswer}
                          />
                        </div>
                      );
                    }) }
                  </div>

                  <div className={styles.buttons}>
                    <Button
                      looks='secondary'
                      size='M'
                      onClick={handleSaveAndExit}
                    >
                      { translate('save_and_exit') }
                    </Button>

                    <Button
                      size='M'
                      disabled={getSubmitButtonDisabled() || submitProcessing}
                      onClick={handleSubmit}
                    >
                      { translate('submit_lbl') }
                    </Button>
                  </div>
                </>
              ) }
            </>
          ) }

          { /* ASSESSMENT COMPLETED */ }
          { assessmentCompleted && (
            <div className={styles.finalPage}>
              <span className={styles.graphic} role='img' aria-label='applause'>
                &#128588;
              </span>

              <div className='bluTypeS'>
                { translate(assessment.customContent?.peer_completed_success_title
                  ?? 'self_completed_success_title') }
              </div>

              <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                { translate(
                  assessment.customContent?.peer_360_ass_success_title ?? 'peer_360_ass_success_title',
                  [ '{{username}}', peerFor ],
                ) }
              </div>

              <Button
                size='M'
                looks='secondary'
                onClick={handleButtonClick}
              >
                { companySettings.linkoutButtonLabel
                  ? translate(companySettings.linkoutButtonLabel)
                  : translate('bq_learn_more') }
              </Button>

            </div>
          ) }
        </div>

        { showInvalidAnswersForSaveModal && (
          <Modal
            header={translate('obs_assessment_invalid_answers')}
            secondaryButtonTitle={translate('cancel_lbl')}
            redButtonTitle={translate('obs_assessment_invalid_answers_exit')}
            onClose={() => setShowInvalidAnswersForSaveModal(false)}
            onConfirm={() => history.goBack()}
          >
            { translate('obs_assessment_invalid_answers_copy') }
          </Modal>
        ) }

        { showSubmitModal && (
          <Modal
            header={submitModalTitle}
            secondaryButtonTitle={translate('ok')}
            onClose={() => setShowSubmitModal(false)}
          >
            { submitModalDescription }
          </Modal>
        ) }
      </div>
    </OverlayModal>
  );
};

export default SinglePageAssessment;
