/* eslint-disable no-loop-func */
import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';
import { useDispatch } from 'react-redux';
import { getAssessmentReportPdf } from 'store/actions';
import { getTopParentAssessmentId, serialiseAssessmentReportPdfParams } from 'utils/assessment';
import {
  ASSESSMENT_TYPES,
  DISPLAY_ONLY_RESULT_ASSESSMENTS,
  HEALTH_ASSESSMENT_IDS,
} from 'utils/configuration/const/assessment-types';
import { MAPPED_PLATFORM_ROLES } from 'utils/configuration/const/roles';


// Returns the (redux) action to download the Pdf report of an assessment, and the downloading state.
// The 'downloading' status is saved to store so it's available after navigating elsewhere.
export const selectAssessmentPdfDownloadHandles = (store, props) => {
  const dispatch = useDispatch(); // eslint-disable-line react-hooks/rules-of-hooks
  const {
    showReportButton = true,
    exportView = false,
    assessmentData,
    historyIndex,
    userId,
  } = props;

  // Coachhub can only download the DISC report (for now)
  const isExternal = getIsExternalSystemIntegrationSupported();

  const canGetDiscReportPdf = isExternal
    && assessmentData?.id === 'disc';
  const canGetCustomReportPdf = !isExternal
    && showReportButton
    && assessmentData?.customReportDownloadable
    && !exportView;

  if (!(canGetDiscReportPdf || canGetCustomReportPdf)) {
    return null;
  }

  const queryParams = {
    userId,
    id: assessmentData?.id,
    resultId: historyIndex ? assessmentData?.history?.results[historyIndex].id : undefined,
  };
  const downloadingKey = serialiseAssessmentReportPdfParams(queryParams);

  return {
    downloading: Boolean(store.assessments.downloading?.[downloadingKey]),
    handleDownload: () => dispatch(getAssessmentReportPdf(queryParams)),
  };
};

export const selectAssessmentsLoading = (store) => store.assessments.loading;

export const selectAssessmentsTable = (store) => store.assessments.table;

export const selectAssessments = (store, filters = []) => {
  const assessmentsFromStore = Object.values(selectAssessmentsTable(store)).filter(Boolean);
  let orderedAssessments = [];

  /* Ordering */
  // 1) set standard parent assessments first, following assessment order
  Object.values(ASSESSMENT_TYPES).forEach((aId) => {
    const assessment = assessmentsFromStore.find(({ id }) => id === aId);
    if (assessment && getTopParentAssessmentId(assessment.id, assessmentsFromStore) === assessment.id) {
      orderedAssessments.push(assessment);
    }
  });

  // 2) then add all other custom parent assessments
  assessmentsFromStore.forEach((a) => {
    if (!Object.values(ASSESSMENT_TYPES).includes(a.id)
      && getTopParentAssessmentId(a.id, assessmentsFromStore) === a.id) {
      orderedAssessments.push(a);
    }
  });

  // 3) finally, sort follow-up (children) assessments by parentId, serialized by dfs
  let assessmentsLeft = true;
  while (assessmentsLeft) {
    assessmentsLeft = false;
    assessmentsFromStore
    .filter((a) => a.parentId && !orderedAssessments.some(({ id }) => id === a.id))
    .forEach((a) => {
      const parentAssessmentIndex = orderedAssessments.findIndex(({ id }) => id === a.parentId);
      if (parentAssessmentIndex !== -1) {
        orderedAssessments.splice(parentAssessmentIndex + 1, 0, a);
        // assessments appearing before this one could be its own children, so we check them again
        assessmentsLeft = true;
      }
    });
  }

  /* Filtering */
  filters.forEach?.((filter) => {
    orderedAssessments = orderedAssessments.filter((a) => filter(a, store));
  });

  return orderedAssessments;
};

// List of filters callbacks that can be applied to selectAssessments
export const ASSESSMENT_FILTERS = {
  noClifton: (a) => a.render_results && !DISPLAY_ONLY_RESULT_ASSESSMENTS.includes(a.render_results),
  noUnavailable: (a) => a.userAvailable !== false, // true and nullish are both ok
  noHealth: (a) => !HEALTH_ASSESSMENT_IDS.includes(a.id),
  noAsPeer: (a) => !a.asPeer,
  forUserGroup: (a, s) => {
    const userGroup = s.currentUser?.roleNext;
    const accessibleAssessments = s.currentUser?.accessibleAssessments;
    if (userGroup !== MAPPED_PLATFORM_ROLES.ASSESSMENT_MANAGER) {
      return true;
    }
    return accessibleAssessments.includes(a.id);
  },
};

// Derived selectors
export const selectAssessmentsWithNoClifton = (store) => selectAssessments(store, [
  ASSESSMENT_FILTERS.noClifton,
]);
export const selectAssessmentsNonHealth = (store) => selectAssessments(store, [
  ASSESSMENT_FILTERS.noClifton,
  ASSESSMENT_FILTERS.noUnavailable,
  ASSESSMENT_FILTERS.noHealth,
]);
export const selectAssessmentsFilteredForUserGroup = (store) => selectAssessments(store, [
  ASSESSMENT_FILTERS.noClifton,
  ASSESSMENT_FILTERS.forUserGroup,
]);
export const selectAssessmentsBluquist = (store) => selectAssessments(store, [
  ASSESSMENT_FILTERS.noClifton,
  ASSESSMENT_FILTERS.noHealth,
  ASSESSMENT_FILTERS.noAsPeer,
  ASSESSMENT_FILTERS.forUserGroup,
]);

export const selectShowStrengths = (store) => Boolean(selectAssessmentsNonHealth(store).length);
