export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const GET_ASSESSMENT_FULFILLED = 'GET_ASSESSMENT_FULFILLED';
export const GET_ASSESSMENT_REJECTED = 'GET_ASSESSMENT_REJECTED';

export const getAssessment = (id, user) => ({
  type: GET_ASSESSMENT,
  payload: { id, user },
});

export const getAssessmentFulfilled = (payload) => ({
  type: GET_ASSESSMENT_FULFILLED,
  payload,
});

export const getAssessmentRejected = (payload) => ({
  type: GET_ASSESSMENT_REJECTED,
  payload,
});

