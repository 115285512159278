// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useRef } from 'react';
import styles from './HotChipsPeople.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import {
  Button,
  InputNext,
  Chip,
  BluCSSTransition,
  ImgCircle,
  SkeletonFlexible,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { MIN_SEARCH_LENGTH, DEBOUNCE_TIME } from 'api';
import { useDebounce } from 'utils/hooks';
import { highlight } from 'utils/textTools';
import REGEXES from 'utils/configuration/const/regexes';


// STORE

// CONFIG & DATA
const CONFIG = {
  pagination: 10,
  blurDelay: 100,
};


// LOCAL CHILD COMPONENT: AnimatedChip
const AnimatedChip = (props) => {
  const {
    item,
    showImage,
    onClick,
    in: transitionIn, // injected by TransitionGroup
  } = props;

  const transitionRef = useRef();

  return (
    <BluCSSTransition
      nodeRef={transitionRef}
      key={item.value}
      classNames={{ ...styles }}
      in={transitionIn}
    >
      <Chip
        ref={transitionRef}
        key={item.value}
        img={item.img}
        showImage={showImage}
        label={!item.isEmail ? item.label : ''}
        onClick={onClick}
      >
        { item.isEmail ? item.label : '' }
      </Chip>
    </BluCSSTransition>
  );
};


// COMPONENT: HotChipsPeople
const HotChipsPeople = (props) => {
  // PROPS
  const {
    values,
    crossValues = [], // includes values from other HotChipsPeople that are connected to this one
    label,
    placeholder,
    hint,
    addNewUsers = true,
    showImage = true,
    noResultsHint,
    disabled,
    showSearchIcon,
    validate,
    onUpdate,
    canMail = true,
    topFlyout = false,
    staticResults = false,
    onSearch,
    searchResults,
    searchTotal,
    loadingSearchResults,
    onPaginationNext,
  } = props;

  const translate = useTranslate();

  // COMPONENT/UI STATE and REFS
  const [ list, setList ] = useState(values || []);
  const [ value, setValue ] = useState('');
  const [ hideFlyout, setHideFlyout ] = useState(true);
  const [ hideFlyoutOverride, setHideFlyoutOverride ] = useState(true);
  const [ blurTimeout, setBlurTimeout ] = useState();
  const [ activeResultIndex, setActiveResultIndex ] = useState(0);
  const [ bigButtonDisabled, setBigButtonDisabled ] = useState(true);

  const isEmail = canMail && REGEXES.EMAIL.test(value);

  // SPECIAL HOOKS
  const debouncedValue = useDebounce(value, DEBOUNCE_TIME);

  // EFFECT HOOKS
  useEffect(() => {
    if (debouncedValue?.length >= MIN_SEARCH_LENGTH || debouncedValue === '') {
      onSearch?.(debouncedValue);
    }
  }, [ debouncedValue, onSearch ]);

  useEffect(() => {
    document.querySelector('[data-active=\'true\']')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ activeResultIndex ]);

  useEffect(() => {
    if (searchResults || loadingSearchResults) {
      setHideFlyout(false);
    }
  }, [ searchResults, loadingSearchResults ]);

  useEffect(() => {
    setList(values);
  }, [ values ]);

  // STORE HOOKS

  // METHODS

  // HANDLES
  const addItem = (item) => {
    // prevent duplicates
    if ([ ...list, ...crossValues ].find((el) => el.value === item.value)) {
      return;
    }

    const newList = [ ...list ];
    newList.unshift(item); // add at the beginning for more dynamic feel on mobile
    setList(newList);
    onUpdate?.(newList, item);
  };

  const removeItem = (item) => {
    const newList = list.filter((listItem) => listItem !== item);
    setList(newList);
    onUpdate?.(newList);
  };

  const handleSearchResultClick = (result) => {
    addItem(result);
    setValue('');
    setActiveResultIndex(0);
    setHideFlyout(true);
  };

  const handleBlur = () => {
    // handle blur with a delay so focus can be prevent blur
    // when for instance the "show more" button in the
    // result flyout is clicked
    setBlurTimeout(setTimeout(() => {
      setHideFlyoutOverride(true);
    }, CONFIG.blurDelay));
  };

  const handleFocus = () => {
    setHideFlyoutOverride(false);
    if (staticResults && (searchResults || loadingSearchResults)) {
      setHideFlyout(false);
    }
    if (blurTimeout) {
      clearTimeout(blurTimeout);
      setBlurTimeout();
    }
  };


  // RENDER: flyout content
  const renderFlyoutContent = () => {
    // LOADING
    if (loadingSearchResults && !searchResults?.length) {
      return (
        <div className={styles.skeleton}>
          <SkeletonFlexible noHeader repeat={1} />
        </div>
      );
    }

    // RESULTS
    if (searchResults?.length) {
      return (
        <div>
          { searchResults.map((result, index) => (
            <div
              key={result.value}
              tabIndex='0'
              role='button'
              data-active={activeResultIndex === index}
              className={classNames(styles.searchResult, {
                [styles.active]: activeResultIndex === index,
                [styles.disabled]: result.disabled,
              })}
              onKeyPress={() => { handleSearchResultClick(result); }}
              onClick={() => { handleSearchResultClick(result); }}
            >
              { result.img && (
                <div className={styles.imgCircle}>
                  <ImgCircle
                    className='darker'
                    size='S'
                    label1={result.label?.split(' ')[0]}
                    label2={result.label?.split(' ')[1]}
                    src={result.img}
                  />
                </div>
              ) }
              <div className={styles.labels}>
                <div className={styles.label}>
                  { highlight(result.label, value) }
                </div>

                { result.subLabel && (
                  <div className={styles.subLabel}>
                    { highlight(result.subLabel, value) }
                  </div>
                ) }
              </div>
            </div>
          )) }

          { /* SHOW MORE */ }
          { searchResults.length < searchTotal && (
            <div className={styles.showMoreResults}>
              { loadingSearchResults
                ? (
                  <div className={styles.loading}>
                    <SkeletonFlexible noHeader repeat={1} />
                  </div>
                ) : (
                  <Button
                    looks='tertiary'
                    size='S'
                    onClick={() => {
                      onPaginationNext();
                      handleFocus(); // prevent blur
                    }}
                  >
                    { translate('create_role_add_emp_show_more') }
                  </Button>
                ) }
            </div>
          ) }
        </div>
      );
    }

    // EMAIL
    if (isEmail) {
      return (
        <div className={styles.message}>
          { addNewUsers
            ? translate('create_role_add_emp_email_hint')
            : translate('hotchips_adding_users_not_possible_hint') }
        </div>
      );
    }

    // NO RESULTS
    return (
      <div className={styles.message}>
        { noResultsHint }
      </div>
    );
  };

  // RENDER: HotChips
  return (
    <div
      className={classNames(styles.hotChipsPeople)}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      { label && (
        <div className={styles.label}>
          { label }
        </div>
      ) }

      { /* INPUT */ }
      <InputNext
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete='chrome-off'
        validate={validate}
        icon={showSearchIcon && 'SearchBig'}
        clearValueOnConfirm={!loadingSearchResults}
        onConfirm={(confirmedValue) => {
          if (loadingSearchResults) {
            return;
          }
          setBigButtonDisabled(true);
          if (!hideFlyout && searchResults?.[activeResultIndex]) {
            handleSearchResultClick(searchResults[activeResultIndex]);
          } else if (isEmail && addNewUsers) {
            handleSearchResultClick({
              isEmail: true,
              value: confirmedValue,
              label: confirmedValue,
            });
          }
        }}
        onArrowUp={(event) => {
          if (!hideFlyout && searchResults) {
            event.preventDefault();
            const newResultIndex = activeResultIndex - 1;
            if (newResultIndex >= 0) {
              setActiveResultIndex(newResultIndex);
            }
          }
        }}
        onArrowDown={(event) => {
          if (!hideFlyout && searchResults) {
            event.preventDefault();
            const newResultIndex = activeResultIndex + 1;
            if (newResultIndex <= searchResults.length - 1) {
              setActiveResultIndex(newResultIndex);
            }
          }
        }}
        onChange={(newValue, newError) => {
          setValue(newValue);
          if (staticResults) {
            setHideFlyout(false);
          } else if (newValue === '') {
            setHideFlyout(true);
          }
          setBigButtonDisabled(Boolean(newError));
        }}
        bigButton
        bigButtonDisabled={bigButtonDisabled
          || loadingSearchResults
          || (!isEmail && !searchResults?.length)}
      />

      { /* FLYOUT */ }
      { !hideFlyout && !hideFlyoutOverride && (
        <div
          className={styles.flyoutContainer}
          onFocus={handleFocus}
        >
          <div className={classNames(styles.flyout, { [styles.topFlyout]: topFlyout })}>
            { renderFlyoutContent() }
          </div>
        </div>
      ) }

      { /* HINT */ }
      { hint && (
        <div className={classNames(styles.hint)}>
          { hint }
        </div>
      ) }

      { /* CHIPS */ }
      <div className={styles.chips}>
        <TransitionGroup>
          { list.map((item) => (
            <AnimatedChip
              key={item.value}
              item={item}
              showImage={showImage}
              label={!item.isEmail ? item.label : ''}
              onClick={() => removeItem(item)}
            />
          )) }
        </TransitionGroup>
      </div>

    </div>
  );
};

export default HotChipsPeople;
