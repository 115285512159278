import React, { memo, useEffect, useState } from 'react';
import styles from './AssessmentRadioButton.module.scss';
import classnames from 'classnames';


const AssessmentRadioButton = memo((props) => {
  const {
    isError,
    disabled,
    checked,
    number,
    value,
    title = '',
    size = 'L',
    showAnimation = false,
    onChange,
    onDeselect,
    onHover,
  } = props;
  const [ startAnimation, setStartAnimation ] = useState(false);

  useEffect(() => {
    if (checked) {
      setTimeout(() => {
        setStartAnimation(showAnimation);
      }, 500);
    } else {
      setStartAnimation(showAnimation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ showAnimation ]);

  const classNames = classnames(
    styles.assessmentRadioButton,
    styles[`size${size}`],
    { [styles.error]: isError },
    { [styles.disabled]: disabled },
    { [styles.checked]: checked },
    { [styles.animation]: startAnimation },
  );

  const handleItem = () => {
    if (checked && onDeselect) {
      onDeselect();
      if (onChange) {
        onChange();
      }
    }
    if (!checked && onChange) {
      onChange(value);
    }
  };

  const handleKey = (event) => {
    const { key } = event;

    switch (key) {
      case ' ': {
        event.stopPropagation();
        handleItem();
        break;
      }
      default:
    }
  };

  return (
    <label
      tabIndex={0}
      role='presentation'
      className={classNames}
      onClick={handleItem}
      onKeyUp={handleKey}
    >
      <div className={styles.name}>
        { number && <span className={styles.number}>{ number }</span> }
        { title }
      </div>
      <span
        role='presentation'
        className={styles.checkmark}
        onMouseEnter={() => onHover?.(value)}
        onMouseLeave={onHover}
      />
    </label>
  );
});

export default AssessmentRadioButton;
