import * as actions from 'store/actions';

const initialState = {
  list: null,
  user: {},
  loading: false,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.LIST_FLOWS:
      return {
        ...state,
        loading: true,
      };
    case actions.LIST_FLOWS_FULFILLED: {
      const { flows: list } = action.payload;
      return {
        ...state,
        loading: false,
        list,
      };
    }
    case actions.LIST_FLOWS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case actions.LIST_USER_FLOWS_FULFILLED: {
      const { flows, id } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          [id]: flows,
        },
      };
    }
    case actions.LIST_USER_FLOWS_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case actions.GET_USER_FLOW_HISTORY_FULFILLED: {
      const { userId, userFlowId, history } = action.payload;

      const newFlows = [ ...state.user[userId] ];
      const index = newFlows.findIndex((el) => el.id === userFlowId);
      if (index !== -1) {
        newFlows[index].history = history;
      }

      return {
        ...state,
        user: {
          ...state.user,
          [userId]: newFlows,
        },
      };
    }
    case actions.GET_USER_FLOW_HISTORY_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }

    default:
      return state;
  }
};
