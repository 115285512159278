// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './MyCoaching.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import { CallHelp } from 'ui/molecules/IstResult/CallHelp';
import { BookModal } from 'ui/molecules/IstResult/BookModal';
import { IstResult } from 'ui/molecules';
import { ExtraCard } from '../MyProfileWellBeing/Ist/ExtraCard';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import * as api from 'api';
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA

// COMPONENT: MyCoaching
const MyCoaching = (props) => {
  // PROPS
  const {
    ist, user, cards, showHelpCard = false,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ selectedCard, setSelectedCard ] = useState();
  const [ showBookCoaching, setShowBookCoaching ] = useState(false);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: MyCoaching
  return (
    <div className={classNames(styles.myCoaching)}>
      <div className={classNames(styles.orgDevelopmentItems)}>
        <div className={styles.title}>
          { translate('my_coaching_title') }
        </div>
        <div className={styles.cards}>
          <div className={styles.left}>
            <IstResult
              ist={ist}
              user={user}
              coachingView
            />

            { showHelpCard && (
              <CallHelp />
            ) }
          </div>

          { cards?.length > 0 && (
            <div className={styles.right}>
              { cards.map((card) => (
                <ExtraCard
                  key={card.type}
                  data={card}
                  dateOfBirthProvided={Boolean(ist?.registrationData?.date_of_birth)}
                  onBook={() => {
                    setSelectedCard(card);
                    setShowBookCoaching(true);
                  }}
                />
              )) }
            </div>
          ) }

        </div>
      </div>

      { /* FLOATERS */ }
      { showBookCoaching && (
        <BookModal
          card={selectedCard}
          onClose={() => setShowBookCoaching(false)}
        />
      ) }
    </div>
  );
};

export default MyCoaching;
