import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { useDispatch, useSelector } from 'react-redux';

import { getAssessment, initSubmitContentFeedback, submitContentFeedback } from 'store/actions';

/* UTILS */
import { useTranslate } from 'utils/translator';
import { joinNumbers } from 'utils/textTools';
import { numberWithForcedDecimals } from 'utils/numberFormatting';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { useBreakpoint } from 'utils/hooks';
import { isValid } from 'utils/numbers';
import { setDecimalSeparator } from 'utils/userpreference';

// 3RD PARTY
import classNames from 'classnames';

import {
  Button, CollapsibleNext,
  InfoTitle,
  SpiderDiagramNext,
  Card,
  Modal, LineDiagram, Callout,
  ShowResultsButton,
} from 'ui/basic';

import Big5ResultSkeleton from './Big5ResultSkeleton';
import styles from './Big5Result.module.scss';

import Big5Report from './Big5Report';
import { DownloadResultButton } from '../DownloadResultButton';
import { filterHiddenResults, filterResultSchema } from 'utils/assessment';


const Big5Result = (props) => {
  const {
    results,
    showReportButton = true,
    openReport,
    requestFeedback = true,
    footerText,
    noDataText,
    showInfoTitle = true,
    showExportIcon,
    exportView,
    onExport,
    userId,
    managerControlled,
    sharedWithUser,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();
  const bp = useBreakpoint();

  // Spider Hovers
  const [ dimensionsHovers, setDimensionsHovers ] = useState([
    // true|false value for each dimensions
    // 0 => true|false depending if hover is set for dimension 0
  ]);

  // STATE
  const [ assessmentResults, setAssessmentResults ] = useState();
  const [ assessmentRanges, setAssessmentRanges ] = useState();
  const [ totalResults, setTotalResults ] = useState([]);
  const [ totalRanges, setTotalRanges ] = useState([]);
  const [ anchor, setAnchor ] = useState();
  const [ showReport, setShowReport ] = useState(false);
  const [ infoPageIsVisible, setInfoPageIsVisible ] = useState(false);
  const [ modalData, setModalData ] = useState();

  const [ currentDimensionIndex, setCurrentDimensionIndex ] = useState();

  const [ modalHeader, setModalHeader ] = useState();
  const [ modalSubHeader, setModalSubHeader ] = useState();
  const [ modalContent, setModalContent ] = useState();
  const [ modalPrimaryButtonTitle, setModalPrimaryButtonTitle ] = useState();
  const [ modalScrollable, setModalScrollable ] = useState(false);
  const [ showModalFeedback, setShowModalFeedBack ] = useState();

  // SELECTORS
  const resultSchema = useSelector((state) => state.assessments.table[ASSESSMENT_TYPES.BIG5]?.resultSchema);

  const resultsFiltered = useMemo(() => filterHiddenResults(results), [ results ]);
  const resultSchemaFiltered = useMemo(() => filterResultSchema(resultSchema), [ resultSchema ]);

  const feedbackSubmittedSuccessfully = useSelector((state) => state.survey?.success);

  const showRange = resultsFiltered && resultsFiltered[0] && Array.isArray(resultsFiltered[0].range);

  const setHover = (dimensionIndex, isHover) => {
    const newDimensionsHovers = new Array(resultSchemaFiltered.length).fill(false);
    newDimensionsHovers[dimensionIndex] = isHover;

    setDimensionsHovers(newDimensionsHovers);
  };

  const getCalloutText = (value, dimensionId) => {
    if (value >= 1 && value <= 2) {
      return translate(`big5_report_dim_${dimensionId}_callout_very_low`);
    }
    if (value > 2 && value <= 4) {
      return translate(`big5_report_dim_${dimensionId}_callout_low`);
    }
    if (value > 4 && value <= 6) {
      return translate(`big5_report_dim_${dimensionId}_callout_medium`);
    }
    if (value > 6 && value <= 8) {
      return translate(`big5_report_dim_${dimensionId}_callout_high`);
    }
    return translate(`big5_report_dim_${dimensionId}_callout_very_high`);
  };

  const getSubHeader = (dimension, dimensionValue) => {
    let dimensionValueNumber = dimensionValue;
    // if dimensionValue is not a number
    if (!isValid(dimensionValue)) {
      dimensionValueNumber = Number(dimensionValue.replace(',', '.'));
    }

    if (dimensionValueNumber >= 1 && dimensionValueNumber <= 2) {
      return translate(
        'big5_dimension_very_low__short_descr',
        [ '{{dimension}}', dimension ],
      );
    }

    if (dimensionValueNumber > 2 && dimensionValueNumber <= 4) {
      return translate(
        'big5_dimension_low__short_descr',
        [ '{{dimension}}', dimension ],
      );
    }

    if (dimensionValueNumber > 4 && dimensionValueNumber <= 6) {
      return translate(
        'big5_dimension_medium__short_descr',
        [ '{{dimension}}', dimension ],
      );
    }

    if (dimensionValueNumber > 6 && dimensionValueNumber <= 8) {
      return translate(
        'big5_dimension_high__short_descr',
        [ '{{dimension}}', dimension ],
      );
    }

    return translate(
      'big5_dimension_very_high__short_descr',
      [ '{{dimension}}', dimension ],
    );
  };

  useEffect(() => {
    if (openReport && totalResults && assessmentResults) {
      setShowReport(true);
    }
  }, [ openReport, totalResults, assessmentResults ]);

  useEffect(() => {
    if (resultsFiltered && resultSchemaFiltered) {
      const resultsMatrix = [];
      const rangesMatrix = [];
      let start = 0;

      const dimensionsLength = resultSchemaFiltered?.reduce((total, r) => total + r.subDimensions.length, 0);

      resultSchemaFiltered?.forEach((dimension) => {
        const subDimensionsLength = dimension.subDimensions?.length ?? 1;

        if (resultsFiltered?.[0]?.result) {
          resultsMatrix.push(resultsFiltered.slice(
            start,
            (start + subDimensionsLength),
          ).map((a) => a.result));
        }

        if (resultsFiltered?.[0]?.range) {
          rangesMatrix.push(resultsFiltered.slice(
            start,
            (start + subDimensionsLength),
          ).map((a) => a.range));
        }

        // results includes only subDimensions
        if (resultsFiltered?.length <= dimensionsLength) {
          start += subDimensionsLength;
        } else {
          // results includes subDimensions + dimensions
          // +1 because after sub dimensions values there is one more value for total result;
          start += subDimensionsLength + 1;
        }
      });

      setAssessmentResults(resultsMatrix);
      setAssessmentRanges(rangesMatrix);
    }
  }, [ resultsFiltered, resultSchemaFiltered ]);

  useEffect(() => {
    if (assessmentResults?.length > 0) {
      const total = assessmentResults.map((res) => {
        let fixedNumber = (res.reduce((acc, result) => acc + result) / res.length).toFixed(1);
        if (fixedNumber !== null && fixedNumber !== undefined) {
          fixedNumber = Number(fixedNumber);
        }

        return fixedNumber;
      });

      setTotalResults(total);
    }
  }, [ assessmentResults ]);

  useEffect(() => {
    if (assessmentRanges?.length > 0) {
      const total = assessmentRanges.map((assessmentRange) => {
        const firstLevelResult1 = assessmentRange
        .map((result) => result[0])
        .reduce((acc, result) => acc + result, 0);
        const firstLevelResult2 = assessmentRange
        .map((result) => result[1])
        .reduce((acc, result) => acc + result, 0);

        const secondLevelResult1 = (firstLevelResult1 / assessmentRange.length).toFixed(1);
        const secondLevelResult2 = (firstLevelResult2 / assessmentRange.length).toFixed(1);

        return [ Number(secondLevelResult1), Number(secondLevelResult2) ];
      });

      setTotalRanges(total);
    } else {
      setTotalRanges([]);
    }
  }, [ assessmentRanges ]);

  useEffect(() => {
    if (!resultSchema) {
      dispatch(getAssessment(ASSESSMENT_TYPES.BIG5));
    }
  }, [ dispatch, resultSchema ]);

  const getSubItem = (subDimensionName, dimensionIndex, subDimensionIndex) => {
    let result;
    let range = [];

    if (assessmentResults && assessmentResults.length > 0) {
      result = assessmentResults && assessmentResults[dimensionIndex - 1][subDimensionIndex - 1];
    }

    if (assessmentRanges && assessmentRanges.length > 0) {
      range = (assessmentRanges && assessmentRanges[dimensionIndex - 1][subDimensionIndex - 1]) || [];
    }

    // const resultIsValid = isValid(result);

    const formattedResult = setDecimalSeparator(result);
    const formattedRange = range.length
      ? `${numberWithForcedDecimals(range[0])}-${numberWithForcedDecimals(range[1])}`
      : '';

    const headerHighlight = formattedRange || formattedResult;

    const headerRight = formattedRange
      ? formattedResult
      : '';


    return (
      <CollapsibleNext
        key={`${dimensionIndex}-${subDimensionIndex}`}
        isExpandable={false}
        headerHighlight={headerHighlight}
        header={subDimensionName}
        headerRight={headerRight}
      />
    );
  };

  const renderExpandedModalContent = () => {
    const {
      result: dimensionResult,
      index: dimensionIndex,
      id: dimensionId,
    } = modalData;

    return (
      <div className={styles.infoModalResult}>
        <div className={styles.reportDiagram} data-test='Big5ResultScore'>
          <div className={styles.header}>
            <span>{ translate('big5_report_facet_result') }</span>
            <span>{ setDecimalSeparator(dimensionResult) }</span>
          </div>

          <div className='marginTopXs'>
            <LineDiagram
              score={dimensionResult}
            />
          </div>

          <div className={styles.footer}>
            <span>{ translate('big5_report_resut_hint1') }</span>
            <span>{ translate('big5_report_resut_hint2') }</span>
          </div>
        </div>

        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          <Callout>
            { getCalloutText(dimensionResult, dimensionId) }
          </Callout>
        </div>

        <div className={styles.facets}>
          <div className={styles.facetsTitle}>
            { translate(`big5_report_dim_${dimensionId}_facets`) }
          </div>

          { resultSchemaFiltered
          .find((r) => r.id === dimensionId)
          ?.subDimensions?.map((subDimension, subDimensionIndex) => {
            const subDimensionValue = assessmentResults[dimensionIndex][subDimensionIndex];

            return (
              <div
                className={styles.facet}
                key={`subDimension-${subDimensionIndex + 1}`}
                data-test='Big5ResultScore'
              >
                <div className={styles.facetHeader}>
                  <span>
                    { subDimension.name }
                  </span>
                  <span>
                    { numberWithForcedDecimals(subDimensionValue) }
                  </span>
                </div>

                <div className='marginTopXs'>
                  <LineDiagram
                    score={subDimensionValue}
                  />
                </div>

                <div className={styles.facetFooter}>
                  <span>
                    { translate('big5_report_resut_hint1') }
                  </span>
                  <span>
                    { translate('big5_report_resut_hint2') }
                  </span>
                </div>
              </div>
            );
          }) }
        </div>
      </div>
    );
  };

  // Rerender Big5 facet content on resize
  useEffect(() => {
    if (!modalData?.result) {
      return;
    }

    setModalContent(renderExpandedModalContent());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bp.isS, bp.isXs, modalData?.result ]);


  if (!resultsFiltered || !resultSchemaFiltered) {
    return (
      <div className={styles.big5Result}>
        <Big5ResultSkeleton />
      </div>
    );
  }

  if (resultsFiltered.length === 0 && resultSchemaFiltered.length === 0) {
    return (
      <div className={styles.big5Result} data-test='Big5Result'>
        <Card hasBreakout='S'>
          <div className={styles.title}>
            { (exportView || !showInfoTitle)
              ? (
                <span className='bluTypeLabelL'>
                  { translate('big5_results_title') }
                </span>
              ) : (
                <InfoTitle
                  title={translate('big5_results_title')}
                  onClick={() => {
                    setModalHeader(translate('big5_results_title'));
                    setModalSubHeader(null);
                    setModalContent(translate('big5_results_info_description'));
                    setModalPrimaryButtonTitle(null);
                    setModalScrollable(false);
                    setShowModalFeedBack(false);
                    setInfoPageIsVisible(true);
                  }}
                />
              ) }
          </div>
          { noDataText && (
            <div className={styles.noDataText}>
              { noDataText }
            </div>
          ) }
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.big5Result} data-test='Big5Result'>

      { /* CARD */ }
      <Card hasBreakout='S'>
        { /* TITLE */ }
        <div className={styles.title}>
          { (exportView || !showInfoTitle)
            ? (
              <span className='bluTypeLabelL'>
                { translate('big5_results_title') }
              </span>
            ) : (
              <InfoTitle
                title={translate('big5_results_title')}
                onClick={() => {
                  setModalHeader(translate('big5_results_title'));
                  setModalSubHeader(null);
                  setModalContent(translate('big5_results_info_description'));
                  setModalPrimaryButtonTitle(null);
                  setModalScrollable(false);
                  setShowModalFeedBack(false);
                  setInfoPageIsVisible(true);
                }}
              />
            ) }

          { (!exportView && showExportIcon) && (
            <DownloadResultButton onExport={onExport} />
          ) }
        </div>

        { noDataText && <div className={styles.noDataText}>{ noDataText }</div> }

        { !noDataText && (
          <>
            { managerControlled && (
              <ShowResultsButton
                userId={userId}
                assessmentId={ASSESSMENT_TYPES.BIG5}
                sharedWithUser={sharedWithUser}
              />
            ) }

            { /* SPIDERDIAGRAMNEXT */ }
            <SpiderDiagramNext
              lineValues={totalResults}
              rangeValues={totalRanges}
              dimensions={(resultSchemaFiltered || [])
              .map((dimension) => ({ label: dimension.name, value: dimension.id }))}
              dimensionsHovers={dimensionsHovers}
              onHover={setHover}
              onDimensionClick={(dimension) => {
                if (!showReportButton) {
                  return;
                }

                const dimensionId = dimension.value;
                const dimensionName = dimension.label;
                const dimensionIndex = resultSchemaFiltered.findIndex((rs) => rs.id === dimensionId);
                const dimensionResult = totalResults[dimensionIndex];

                setCurrentDimensionIndex(dimensionIndex);

                setModalHeader(dimensionName);
                setModalSubHeader(getSubHeader(dimensionName, dimensionResult));

                setModalPrimaryButtonTitle(translate('go_to_report_lbl'));
                setShowModalFeedBack(true);
                setModalScrollable(true);

                setModalData({
                  id: dimensionId,
                  result: dimensionResult,
                  index: dimensionIndex,
                });

                setInfoPageIsVisible(true);
              }}
            />

            { /* COLLAPSIBLES / DIMENSIONS */ }
            <div className={styles.collapsibles}>
              { resultSchemaFiltered
              .map((dimension, dimensionIndex) => {
                const lineValue = totalResults[dimensionIndex];
                const range = (totalRanges && totalRanges[dimensionIndex]) || [];

                const formattedResult = setDecimalSeparator(lineValue);
                const formattedRange = range.length
                  ? joinNumbers(range, '-', numberWithForcedDecimals)
                  : '';

                const headerHighlight = range.length
                  ? formattedRange
                  : formattedResult;
                const headerRight = range.length
                  ? formattedResult
                  : '';

                return (
                  <CollapsibleNext
                    key={dimension.id}
                    isExpandable={!exportView}
                    headerSubtle={dimensionIndex + 1}
                    headerHighlight={headerHighlight}
                    header={dimension.name}
                    headerRight={headerRight}
                    isHovered={dimensionsHovers[dimensionIndex]}
                    onMouseEnter={() => { setHover(dimensionIndex, true); }}
                    onMouseLeave={() => { setHover(dimensionIndex, false); }}
                  >
                    { dimension.subDimensions.map((subDimension, subDimensionIndex) => (
                      getSubItem(subDimension.name, dimensionIndex + 1, subDimensionIndex + 1)
                    )) }
                    { /* SHOW REPORT BUTTON */ }
                    { (!showRange && showReportButton) && (
                      <div className={styles.button}>
                        <Button
                          size='S'
                          looks='secondary'
                          onClick={() => {
                            setCurrentDimensionIndex(dimensionIndex);

                            setModalHeader(dimension.name);
                            setModalSubHeader(
                              getSubHeader(
                                dimension.name,
                                formattedResult,
                              ),
                            );
                            setModalPrimaryButtonTitle(translate('go_to_report_lbl'));
                            setShowModalFeedBack(true);
                            setModalScrollable(true);

                            setModalData({
                              id: dimension.id,
                              result: lineValue,
                              index: dimensionIndex,
                            });

                            setInfoPageIsVisible(true);
                          }}
                        >
                          { translate(`big5_results_button_learnmore_dim${dimensionIndex + 1}`) }
                        </Button>
                      </div>
                    ) }
                  </CollapsibleNext>
                );
              }) }
            </div>

            { footerText && (
              <div className={styles.footerText}>
                { footerText }
              </div>
            ) }

            { (!showRange && showReportButton && !exportView) && (
              <div className={styles.reportButton}>
                <Button
                  size='S'
                  looks='secondary'
                  onClick={() => {
                    setAnchor(null);
                    setShowReport(true);
                  }}
                >
                  { translate('big5_results_button_showreport') }
                </Button>
              </div>
            ) }
          </>
        ) }
      </Card>

      { infoPageIsVisible && (
        <Modal
          header={modalHeader}
          subHeader={modalSubHeader}
          primaryButtonTitle={modalPrimaryButtonTitle}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
          feedbackSubmitted={feedbackSubmittedSuccessfully}
          onFeedbackSubmit={(requestFeedback && showModalFeedback)
            ? ((feedback) => {
              dispatch(submitContentFeedback({
                contentId: resultSchemaFiltered[currentDimensionIndex]?.name,
                contentType: 'recommendation',
                helpful: feedback === 'Yes',
              }));
            })
            : null}
          onClose={() => {
            dispatch(initSubmitContentFeedback());
            setInfoPageIsVisible(false);
            setModalData();
          }}
          onConfirm={modalScrollable
            ? (() => {
              setInfoPageIsVisible(false);
              setModalData();
              setAnchor(resultSchemaFiltered[currentDimensionIndex]?.name?.replace(/\s/g, '-').toLowerCase());
              setShowReport(true);
            })
            : (() => setInfoPageIsVisible(false))}
        >
          { modalContent }
        </Modal>
      ) }

      { showReport && createPortal(
        <Big5Report
          dimensionsResults={totalResults}
          facetsResults={assessmentResults}
          resultSchema={resultSchemaFiltered}
          anchor={anchor}
          onClose={() => setShowReport(false)}
        />,
        document.body, // ModalsAndPanels
      ) }

    </div>
  );
};

export default Big5Result;
