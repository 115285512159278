// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useImperativeHandle, forwardRef, useRef,
} from 'react';
import styles from './InputFile.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

// UTILS
import { useTranslate } from 'utils/translator';

// CONFIG & DATA
const Config = {
  MB: 1024 ** 2,
};


// COMPONENT: InputFile
const InputFile = (props, ref) => {
  // PROPS
  const {
    label = '',
    maxSizeMB = 5,
    allowedTypes = [],
    customFileName,
    disabled,
    error = '',
    onChange,
    onValid,
    onInvalid,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  const inputId = useRef(uuid());
  const inputRef = useRef();
  const [ validationErrors, setValidationErrors ] = useState({});
  const [ originalFileName, setOriginalFileName ] = useState(null);
  const fileName = customFileName || originalFileName;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  function validate(file = {}) {
    const errors = {};

    const suffix = file.name
    ?.split('.')
    .pop()
    .toLowerCase();

    if (!file) {
      errors.required = true;
    }

    if (file.size > maxSizeMB * Config.MB) {
      errors.size = true;
    }

    if (allowedTypes.length !== 0 && allowedTypes.includes(suffix) === false) {
      errors.type = true;
    }

    if (Object.keys(errors).length === 0) {
      setValidationErrors({});
      return true;
    }

    setValidationErrors(errors);
    return false;
  }

  function handleOnChange(e) {
    const file = e.target.files[0];
    const isValid = validate(file);

    if (isValid) {
      onChange?.(file);
      onValid?.(file);
      setOriginalFileName(file.name);
    } else {
      onInvalid?.();
      setOriginalFileName(null);
    }
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      inputRef.current.value = '';
      setValidationErrors({});
      setOriginalFileName(null);
      onChange?.();
      onInvalid?.();
    },
  }));


  // RENDER: InputFile
  return (
    <div
      className={classNames(
        styles.inputFile,
        fileName ? styles.selected : styles.unselected,
        disabled === true ? styles.disabled : undefined,
      )}
    >
      <label htmlFor={inputId.current}>
        <input
          id={inputId.current}
          type='file'
          ref={inputRef}
          onChange={handleOnChange}
          disabled={disabled}
        />

        { fileName ? (
          <div className={styles.inputAlt}>
            <IconsSvg.Document />
            <span>{ fileName }</span>
            <IconsSvg.Edit />
          </div>
        ) : (
          <span>
            <IconsSvg.Upload />
            { ' ' }
            { label }
          </span>
        ) }
      </label>

      { error && (
        <div className={styles.error}>
          { error }
        </div>
      ) }

      { /* not sure if this is needed, since you can not not select a file */ }
      { validationErrors.required && (
        <div className={styles.error}>
          { translate('input_file_required') }
        </div>
      ) }

      { validationErrors.size && (
        <div className={styles.error}>
          { translate('input_file_max_size') }
          { ' ' }
          { maxSizeMB }
          { ' ' }
          MB.
        </div>
      ) }

      { validationErrors.type && (
        <div className={styles.error}>
          { translate('input_file_wrong_type') }
        </div>
      ) }
    </div>
  );
};

export default forwardRef(InputFile);
