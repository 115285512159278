import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'api';
import * as fromActions from 'features/+candidates/store/vacancy/vacancy.actions';
import { getMappedVacancyData } from './vacancy.utils';
import { get } from 'api';
import { getFullName } from 'utils/users';


function* getVacancy(action) {
  const vacancyId = action.payload.id;

  const params = new URLSearchParams();
  params.append('expand', 'role');
  params.append('expand', 'team');
  params.append('expand', 'teamPosition');

  const { status, ok, data } = yield call(api.get, `recruiting/jobs/${vacancyId}`, params);

  if (ok && status === 200) {
    yield put(fromActions.getVacancySuccess({ data: getMappedVacancyData(data) }));
    yield put(fromActions.getVacancyCandidates({ id: vacancyId }));
  } else {
    yield put(fromActions.getVacancyFail({ error: data.error }));
  }
}

export function* watchGetVacancy() {
  yield takeLatest(fromActions.GET_VACANCY, getVacancy);
}


function* getVacancyDescription(action) {
  const vacancyId = action.payload.id;

  const { status, ok, data } = yield call(api.get, `recruiting/jobs/${vacancyId}`);

  if (ok && status === 200) {
    yield put(fromActions.getVacancyDescriptionSuccess({ data: data.description }));
  } else {
    yield put(fromActions.getVacancyDescriptionFail({ error: data.error }));
  }
}

export function* watchGetVacancyDescription() {
  yield takeLatest(fromActions.GET_VACANCY_DESCRIPTION, getVacancyDescription);
}

function* getVacancyCandidates(action) {
  const vacancyId = action.payload.id;

  const { status, ok, data } = yield call(api.get, `recruiting/jobs/${vacancyId}/candidates?expand=user&expand=flow`);

  if (ok && status === 200) {
    yield put(fromActions.getVacancyCandidatesSuccess({ data: data.users }));
  } else {
    yield put(fromActions.getVacancyCandidatesFail({ error: data.error }));
  }
}

export function* watchGetVacancyCandidates() {
  yield takeLatest(fromActions.GET_VACANCY_CANDIDATES, getVacancyCandidates);
}


function* getVacancyMatches(action) {
  const vacancyId = action.payload.id;

  const { status, ok, data } = yield call(api.get, `recruiting/jobs/${vacancyId}/matches`, { expand: 'user' });

  if (ok && status === 200) {
    yield put(fromActions.getVacancyMatchesSuccess({ data: data.matches }));
  } else {
    yield put(fromActions.getVacancyMatchesFail({ error: data?.error }));
  }
}

export function* watchGetVacancyMatches() {
  yield takeLatest(fromActions.GET_VACANCY_MATCHES, getVacancyMatches);
}


function* getVacancyProfile(action) {
  const vacancyId = action.payload.id;

  // get vacancy profile
  const { status, ok, data } = yield call(api.get, `recruiting/jobs/${vacancyId}/profile`, { expand: 'user' });
  const vacancyProfile = data;

  if (ok && status === 200) {
    // get vacancy candidates to fetch users data
    const { data } = yield call(get, `recruiting/jobs/${vacancyId}/candidates?expand=user&expand=flow`);
    const vacancyCandidates = data.users;

    vacancyProfile.candidates.forEach((profile) => {
      profile.results.forEach((r) => {
        r.values.forEach((value) => {
          const thisCandidate = vacancyCandidates.find((vCandidate) => vCandidate.user.id === value.user);
          if (thisCandidate) {
            value.user = {
              id: thisCandidate.user.id,
              name: getFullName(thisCandidate.user),
            };
          } else {
            value.user = {
              id: value.user,
              name: value.user,
            };
          }
        });
      });
    });

    yield put(fromActions.getVacancyProfileSuccess({ data: vacancyProfile }));
  } else {
    yield put(fromActions.getVacancyProfileFail({ error: data.error }));
  }
}

export function* watchGetVacancyProfile() {
  yield takeLatest(fromActions.GET_VACANCY_PROFILE, getVacancyProfile);
}
