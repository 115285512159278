/**
 * Check if an object is empty.
 *
 * @param {Object} [obj] The object to check
 * @returns {Boolean}
 */
export const isEmpty = (obj) => !obj || !Object.keys(obj).length;

/**
 * Take a plain JS object and remove a list of enumerable keys, at all levels of nesting.
 *
 * @param {Object} obj The object to clear
 * @param {Array} keys List of key names to find and delete
 * @returns {Object} The initial object without the selected keys
 */
export const stripKeys = (obj, keys) => {
  const strip = (el) => {
    if (el?.constructor === Array) {
      el.forEach(strip);
    }
    if (el?.constructor === Object) {
      keys.forEach((key) => {
        delete el[key]; // eslint-disable-line no-param-reassign
      });
      Object.values(el).forEach(strip);
    }
    return el;
  };

  return strip(JSON.parse(JSON.stringify(obj)));
};
