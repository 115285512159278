import sanitize from 'insane';
import { LANGUAGES } from './configuration/const/languages';

const mail = localStorage.getItem('mail');
const currentLanguage = localStorage.getItem('selectedLanguage');

// DYNAMIC ENV VARS
// via Post Build Configuration and .env file
export const getPostBuildConfig = () => JSON.parse(window.__PRELOADED_STATE__ || '{}', true, 2);

// getDynamicEnvVar
// tries to retrieve the env var dynamically from window.__PRELOADED_STATE__
// otherwise falls back to retrieving it from process.env
export const getDynamicEnvVar = (identifier, fallbackValue) => {
  const config = getPostBuildConfig();
  let value = process.env[identifier];
  if (config && config[identifier]) {
    value = config[identifier];
  }
  const sanitizedValue = sanitize(value);
  return sanitizedValue || fallbackValue || null;
};

export const REACT_APP_API_BASE_URL = getDynamicEnvVar('REACT_APP_API_BASE_URL');

// ADMIN FRONTEND
export const IS_ADMIN_FRONTEND = getDynamicEnvVar('REACT_APP_IS_ADMIN_FRONTEND');
export const ADMIN_FRONTEND_OTP_SECRET = process.env.NODE_ENV === 'development'
&& getDynamicEnvVar('REACT_APP_GLOBAL_ADMIN_OTP_SECRET');

export const CONFIGURATION_OPTIONS = {
  BLUQUIST: 'BLUQUIST',
  BALANCED_YOU: 'BALANCED_YOU',
  COACH_HUB: 'COACH_HUB',
};

export const CONFIGURATION = getDynamicEnvVar('REACT_APP_CONFIGURATION', CONFIGURATION_OPTIONS.BLUQUIST);

export const SUBDOMAIN_FOR_DEFAULT_COMPANY_ID = getDynamicEnvVar('REACT_APP_SUBDOMAIN_FOR_DEFAULT_COMPANY_ID');
export const COMPANY_ID_FALLBACK = getDynamicEnvVar('REACT_APP_COMPANY_ID_FALLBACK');

// tracking
export const TRACKING_ENABLE = getDynamicEnvVar('REACT_APP_TRACKING_ENABLE');
export const TRACKING_SITE_ID = getDynamicEnvVar('REACT_APP_TRACKING_SITE_ID');

// translation disable
export const ALLOW_TRANSLATION_DISABLE = getDynamicEnvVar('REACT_APP_ALLOW_TRANSLATION_DISABLE');

// CoachHub fallback
export const EXTERNAL_WEBSITE_FALLBACK = getDynamicEnvVar('REACT_APP_EXTERNAL_WEBSITE_FALLBACK');

// languages to be tested (overrides lang list from backend)
export const ENABLED_LANGUAGES = getDynamicEnvVar('REACT_APP_ENABLED_LANGUAGES')?.split(',');

// maintenance
export const IS_MAINTENANCE_MODE = getDynamicEnvVar('REACT_APP_IS_MAINTENANCE_MODE') === 'ENABLE';

// DOMAIN VARS

/**
 * Extract instance and domain from hostname.
 *
 * @param {String} hostnameParam Hostname from browser Location object.
 * @returns {[String, String]} Tuple containing instance name and domain.
 */
const splitInstanceAndDomain = (hostnameParam) => {
  let hostname = hostnameParam;
  if (hostname.includes('host.docker.internal')) {
    hostname = hostname.replace('host.docker.internal', 'localhost');
  }

  const [ instance, domain ] = hostname
  .replace(/^www\./, '') // remove www if present
  .split(/\.(.*)/); // split on first dot

  // Cases where instance and domain could not be separated correctly, usually found on localhost.
  const exceptions = [ 'admin', 'localhost' ];
  if (!instance || !domain || exceptions.includes(instance) || !Number.isNaN(Number(instance))) {
    return [ undefined, hostname ];
  }

  return [ instance, domain ];
};

/**
 * Return the default data available in current window location, divided by type.
 * Some keys may be empty depending on url type (e.g. localhost may not have an instance).
 *
 * @returns {Object} Object containing current protocol, instance, domain, port.
 */
const getDefaultLocation = () => {
  const { hostname, port } = window.location;
  let { protocol } = window.location;

  const [ instance, domain ] = splitInstanceAndDomain(hostname);
  protocol = protocol.slice(0, -1); // remove trailing ':'

  return {
    protocol, instance, domain, port,
  };
};

/**
 * Return COMPANY ID, retrieved from domain. It's the leftmost subdomain that is not www.
 * If undefined, return default subdomain (for multiple instance login).
 *
 * @param {String} hostname Hostname from browser Location object.
 * @param {String} [defaultCompanyId] Name of instance used in central login (from ENV)
 * @param {String} [companyIdFallback] Company id used as a fallback, if the current company id is equal to defaultCompanyId
 * @returns {String} Id of current company
 */
export const getCompanyId = (hostname, defaultCompanyId, companyIdFallback) => {
  const [ companyId ] = splitInstanceAndDomain(hostname);

  if (companyIdFallback && companyId && companyId === defaultCompanyId) {
    return companyIdFallback;
  }

  return companyId || defaultCompanyId;
};

export const COMPANY_ID = getCompanyId(window.location.hostname, SUBDOMAIN_FOR_DEFAULT_COMPANY_ID, COMPANY_ID_FALLBACK);
export const CURRENT_LOCATION = getDefaultLocation();

// EXTERNAL LINKS
// eslint-disable-next-line max-len
export const SUPPORT_LINK = `https://get.support.bluquist.com/servicedesk/customer/portal/1/group/1/create/1?email=${mail}`;
// eslint-disable-next-line max-len
export const FEEDBACK_LINK = `https://get.support.bluquist.com/servicedesk/customer/portal/1/group/1/create/8?email=${mail}`;
export const HELP_LINK = currentLanguage === LANGUAGES.DE
  ? 'https://help.bluquist.com/de/'
  : 'https://help.bluquist.com/';

// currently turned off: https://blueexcellence.atlassian.net/browse/BDE-678
export const IS_INTRODUCTION_ENABLED = false;
