import React, { useState, useEffect, useCallback } from 'react';

// 3RD PARTY
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { AssessmentNext } from 'ui/molecules/AssessmentNext';

// UTILS
import { ASSESSMENT_TYPES, ASSESSMENT_DELAYED_RESULTS } from 'utils/configuration/const/assessment-types';
import {
  initialiseAssessment,
  handleAssessmentAnswer,
  getTopParentAssessmentId,
} from 'utils/assessment';
import { returnToUrl } from 'features/+coachHub/utils/returnUrl';
import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';

// STORE
import { getCurrentUserId } from 'store/selectors/currentUser';
import { selectAssessments } from 'store/selectors/assessment';


const Big5AssessmentPage = () => {
  // SPECIAL HOOKS
  const history = useHistory();

  // STORE
  const userId = useSelector(getCurrentUserId);
  const assessmentsFromStore = useSelector(selectAssessments);

  const externalSystemSupported = getIsExternalSystemIntegrationSupported();

  // GET ASSESSMENT DATA
  const assessmentId = ASSESSMENT_TYPES.BIG5;
  const topParent = getTopParentAssessmentId(assessmentId, assessmentsFromStore);

  const [ loadingAssessment, setLoadingAssessment ] = useState(false);
  const [ assessmentQuestions, setAssessmentQuestions ] = useState();
  const [ configOverride, setConfigOverride ] = useState();

  // initialise assessment
  useEffect(() => {
    if (!userId || !assessmentId || loadingAssessment) {
      return;
    }

    setLoadingAssessment(true);

    initialiseAssessment({
      userId,
      assessmentId,
      handleExit: () => {
        if (externalSystemSupported) {
          returnToUrl({
            urlFromStorage: true,
            getParams: {
              status: 'error',
              error_type: 'assessment_cant_start_b5',
              assessment: assessmentId,
            },
          });
        } else {
          history.goBack();
        }
      },
    })
    .then(({ questions, assessment, prevAnswers }) => {
      setConfigOverride({
        removeFirstIntermissions: externalSystemSupported,

        title: assessment.title,
        description: assessment.description,

        allowBackNavigation: assessment.free_navigation,
        canContinueLater: assessment.multi_session,
        // modalHurryDelay: 5 * 1000,
        // modalHurryDelay: assessment.time_to_reminder * 1000,

        // // modalHelpContent: '[blu-markdown] **modal help content**',
        // // guidanceContent: '[blu-markdown] **guidance content**',
        // modalHelpContent: assessment.info_texts.question_help,
        // guidanceContent: assessment.info_texts.guidance_text,

        progress: assessment.progress,
        prevAnswers,
      });

      setAssessmentQuestions(questions);
    })
    .catch(() => {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'error',
          error_type: 'assessment_error',
          assessment: 'big5',
          error_message: 'could not initialise assessment',
        },
      });
    });
  }, [
    loadingAssessment,
    history,
    userId,
    externalSystemSupported,
    assessmentId,
    assessmentQuestions,
  ]);

  // METHODS

  // HANDLES
  const handleCancel = () => {
    if (externalSystemSupported) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'assessment_canceled',
          assessment: assessmentId,
        },
      });
    } else {
      history.goBack();
    }
  };

  const handleFinish = () => {
    if (externalSystemSupported) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'assessment_completed',
          assessment: assessmentId,
          results_available: !ASSESSMENT_DELAYED_RESULTS[assessmentId],
        },
      });
    } else {
      history.push(`/my-profile/strength-profile?route=${topParent}&openReport=big5`);
    }
  };

  const handleError = useCallback((errorMessage) => {
    if (externalSystemSupported) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'error',
          error_type: 'assessment_error',
          assessment: 'big5',
          error_message: errorMessage,
        },
      });
    }
  }, [ externalSystemSupported ]);

  const handleAnswer = (answer) => {
    handleAssessmentAnswer(answer, assessmentId, externalSystemSupported);
  };

  const handleAllAnswers = (answers, setFinishable) => {
    setFinishable(true);
  };

  // RENDER: Big5AssessmentPage
  return (
    <AssessmentNext
      type='big5'
      questions={assessmentQuestions}
      userId={userId}
      configOverride={configOverride}
      onCancel={handleCancel}
      onFinish={handleFinish}
      onAnswer={handleAnswer} // session prolongation
      onAllAnswers={handleAllAnswers}
      onError={handleError}
      isCoachHub={externalSystemSupported}
    />
  );
};

export default Big5AssessmentPage;
