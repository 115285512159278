export const USER_GROUP_PROFILE_SETTINGS_ITEMS = [
  {
    id: 'username',
    name: 'profile_settings__username',
    alwaysChecked: true,
    disabled: true,
  },
  {
    id: 'location',
    name: 'profile_settings__location',
  },
  {
    id: 'nationality',
    name: 'profile_settings__nationality',
  },
  {
    id: 'gender',
    name: 'gender',
  },
  {
    id: 'age',
    name: 'rmp_ass_form_age_label',
  },
  {
    id: 'schoolDegree',
    name: 'highest_school_degree',
  },
  {
    id: 'inCompanySince',
    name: 'in_company_since',
    excludeFor: [ 'candidate' ],
  },
  {
    id: 'careerLevel',
    name: 'cp_label_dropdown_careerlevel',
  },
  {
    id: 'position',
    name: 'current_position',
  },
  {
    id: 'jobFamily',
    name: 'job_family',
  },
  {
    id: 'experience',
    name: 'industry_experience',
  },
  {
    id: 'jobRequirements',
    name: 'job_requirements',
    excludeFor: [ 'employee', 'leader', 'assessmentManager', 'admin' ],
  },
  {
    id: 'jobMotivations',
    name: 'job_motivation',
    excludeFor: [ 'employee', 'leader', 'assessmentManager', 'admin' ],
  },
  {
    id: 'jobStartDate',
    name: 'start_termin',
    excludeFor: [ 'employee', 'leader', 'assessmentManager', 'admin' ],
  },
];
