// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './StartNewEvaluationLink.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Modal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { apiNext } from 'apiNext';
import { useDispatch } from 'react-redux';

// STORE
import { showToast } from 'store/actions';

// CONFIG & DATA
// const Config = {};


// COMPONENT: StartNewEvaluationLink
const StartNewEvaluationLink = (props) => {
  const { userId } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();

  const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);
  const [ processing, setProcessing ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const handleConfirm = () => {
    setProcessing(true);

    apiNext.post('core/assessments/ist/start', {}, { user: userId })
    .then(() => {
      setShowConfirmationModal(false);
      dispatch(showToast(translate('fill_position__toast_title'), translate('new_iteration_started_toast_content')));
    })
    .catch(({ message }) => {
      console.error(message);
      setErrorMessage(message);
    })
    .finally(() => setProcessing(false));
  };


  // RENDER: StartNewEvaluationLink
  return (
    <div className={styles.startNewEvaluationLink}>
      <div
        className={classNames(styles.content, { [styles.disabled]: processing })}
        role='button'
        tabIndex={0}
        onClick={() => setShowConfirmationModal(true)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setShowConfirmationModal(false);
          }
        }}
      >
        <IconsSvg.Reset />
        <span>{ translate('assessment_retake_start') }</span>
      </div>

      { showConfirmationModal && (
        <Modal
          secondaryButtonTitle={translate('cancel_lbl')}
          primaryButtonTitle={translate('start')}
          primaryButtonDisabled={processing}
          closeOnConfirm={false}
          onClose={() => {
            setErrorMessage('');
            setShowConfirmationModal(false);
          }}
          onConfirm={handleConfirm}
        >
          <>
            { errorMessage && (
              <div className='error'>{ errorMessage }</div>
            ) }

            { translate('ist_assmnt_retake_for_employee') }
          </>
        </Modal>
      ) }
    </div>
  );
};

export default StartNewEvaluationLink;
