// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './StringResults.module.scss';

// UTILS
import { useTranslate } from 'utils/translator';


// COMPONENT: StringResults
const StringResults = (props) => {
  // PROPS
  const {
    assessmentResults,
    assessmentResultsAll,
    configuration = {},
    content = {},
  } = props;

  const {
    showDimensionName = true,
    showDimensionDescription,
    breakdownPeerGroups,
  } = configuration;
  const {
    dimension: dimensionId,
  } = content;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const isBreakdownPeerGroups = (dim) => breakdownPeerGroups && dim.peerResults?.length;

  const results = dimensionId
    // assessmentResultsAll containing all visible and hidden dimensions
    // https://blueexcellence.atlassian.net/browse/BDE-33
    ? assessmentResultsAll
    : assessmentResults;


  // RENDER: resultItem
  const renderResultItem = (result = [], id = '', name = null) => (
    <div key={id} className={styles.peerGroupName}>
      { name && (
        <div className={styles.title}>
          { translate(name) }
        </div>
      ) }
      <ul>
        { Array.isArray(result)
          // eslint-disable-next-line react/no-array-index-key
          ? result.map((el, index) => <li key={index}>{ el }</li>)
          : <li>{ result }</li> }
      </ul>
    </div>
  );

  // RENDER: StringResults
  return (
    <div className={styles.stringResults}>
      { results
      .filter(({ id }) => (dimensionId ? id === dimensionId : true))
      .map((dimension) => (
        <div
          id={dimension.id}
          key={dimension.id}
          className={styles.dimension}
        >
          { showDimensionName && (
            <div className={styles.name}>
              { dimension.name || dimension.id }
            </div>
          ) }

          { showDimensionDescription && (
            <div className={styles.description}>
              { translate(dimension.description) || '-' }
            </div>
          ) }

          <div>
            { !isBreakdownPeerGroups(dimension)
              ? renderResultItem(
                dimension.result,
                '',
                showDimensionDescription && 'peer_360_report_additional_input_comments',
              ) : (
                <>
                  { showDimensionDescription && (
                    <div className={styles.commentLabel}>
                      { translate('peer_360_report_additional_input_comments') }
                    </div>
                  ) }
                  <div className={styles.peerGroups}>
                    { dimension.peerResults.map(({ id, name = 'self_lbl', result }) => (
                      renderResultItem(result, id, name)
                    )) }
                  </div>
                </>
              ) }
          </div>
        </div>
      )) }
    </div>
  );
};

export default StringResults;
